import type { Option } from './option'
import type { Subject } from './subject'

export type MergedStudyQuestionStructure<
  S extends StudyQuestionStructure = StudyQuestionStructure,
> = Omit<S, 'waveId'> & {
  readonly waveIds: string[]
}

export enum DISPLAY_LOGIC_ANSWER_OPERATOR {
  ALL_SELECTED = 'ALL_SELECTED',
  ANY_SELECTED = 'ANY_SELECTED',
  NONE_SELECTED = 'NONE_SELECTED',
}

export type DisplayLogicAnswerOperator = 'ALL_SELECTED' | 'ANY_SELECTED' | 'NONE_SELECTED'

export type DisplayLogic = {
  conditions: {
    choiceIds: string[]
    condition: DisplayLogicAnswerOperator
    studyCardGuid: string
  }[]
  operator: 'AND' | 'OR'
}

export type QuestionCardDisplayLogic = {
  operator: DisplayLogic['operator']
  displayLogicRules: QuestionCardDisplayLogicValues[]
}

type QuestionCardDisplayLogicValues = {
  questionNumber: number
  answerTitles: string[]
  answerOperator: DisplayLogicAnswerOperator
}

export type StudyQuestionStructure<
  O extends StudyQuestionStructureOptionSettings = StudyQuestionStructureOptionSettings,
> = {
  readonly waveId: string
  readonly publishedTimestamp: number | undefined
  readonly subjects: readonly Subject[]
  readonly options: readonly Option[]
  readonly optionSettings: O
  readonly next?: string
  readonly displayLogic?: DisplayLogic
  readonly title: string
  readonly mediaUrl?: string
  readonly omitted: boolean
}

export type StudyQuestionStructureOptionSettings<
  T extends StudyQuestionOptionResponseType = StudyQuestionOptionResponseType,
> = {
  readonly type: T
  readonly limit: T extends 'multiple' ? number | undefined : undefined
}

export type StudyQuestionOptionResponseType =
  | 'single'
  | 'multiple'
  | 'order'
  | 'short'
  | 'medium'
  | 'long'
  | 'video'

export function assertIsMergedStudyQuestionStructure(
  structure: unknown,
): asserts structure is MergedStudyQuestionStructure {
  if (isMergedStudyQuestionStructure(structure)) return
  throw new TypeError('Structure is not a question structure')
}

export function isMergedStudyQuestionStructure(
  structure: unknown,
): structure is MergedStudyQuestionStructure {
  if (typeof structure !== 'object' || structure === null) return false
  return (
    'waveIds' in structure &&
    'options' in structure &&
    'optionSettings' in structure &&
    'subjects' in structure
  )
}
