import { useMemoize } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, type Ref, ref, watch } from 'vue'

import {
  fishersExactTest,
  useResultsRoundsStore,
  useStudyInsightsStore,
} from '@attest/results-core'
import { type ContextKey, useSavedSettingsStore } from '@attest/results-saved-settings'
import type { Round } from '@attest/rounds'
import { useStudyStore } from '@attest/study'
import { deepEqual, difference } from '@attest/util'

import {
  type ColumnDefinitionValue,
  type DynamicColumnDefinitionValue,
  isStaticColumnDefinitionValue,
  type StackedColumnDefinitionValue,
} from '../column-definition'
import { useCardCellWithBaseRounds } from '../filters/cell'
import { useCardColumnWithBaseRounds } from '../filters/column'
import { getColumnCacheKey } from '../memoization'
import type { Row, RowChild } from '../row'

import { useCrosstabs2ColumnDefinitionsStore } from './column-definition'

export type SigTestValue = 'higher' | 'lower' | 'none'
export type SigTestComparison = StackedColumnDefinitionValue | 'previous-column' | 'total'

const SIG_TEST_STORE = 'sigTest'

export const useSigTestStore = defineStore(SIG_TEST_STORE, () => {
  const studyStore = useStudyStore()
  const studyInsightsStore = useStudyInsightsStore()

  const enabled: Record<ContextKey, Ref<boolean>> = {
    overview: ref(false),
    crosstabs: ref(true),
    trends: ref(true),
  }

  const comparison: Record<ContextKey, Ref<SigTestComparison>> = {
    overview: ref('total'),
    crosstabs: ref('total'),
    trends: ref('previous-column'),
  }

  const pValue: Record<ContextKey, Ref<number>> = {
    overview: ref(0.05),
    crosstabs: ref(0.05),
    trends: ref(0.05),
  }

  const contextKey = computed(() => useSavedSettingsStore().contextKey)

  function updateComparison(newValue: SigTestComparison | 'total' | 'previous-column'): void {
    comparison[contextKey.value].value = newValue
  }

  const definitionValues = computed(() => {
    const { dynamicDefinitions } = useCrosstabs2ColumnDefinitionsStore()
    return dynamicDefinitions.flatMap(({ type, variable, values }) =>
      values.map(value => ({ type, variable, value })),
    )
  })

  const withCalculate = useMemoize(
    (args: SigTestArgs) =>
      computed(() => {
        if (!enabled[contextKey.value].value) {
          return 'none'
        }
        return calculateCrosstabSignificance({
          pValueRef: pValue[contextKey.value],
          comparisonRef: comparison[contextKey.value],
          definitionsRef: definitionValues,
          ...args,
        })
      }),
    {
      getKey: ({ cardId, row, rowChild, column }) => {
        return `${cardId}${row.id}${rowChild.id}${getColumnCacheKey(column)}`
      },
    },
  )

  function $reset(): void {
    enabled.crosstabs.value = true
    enabled.trends.value = true
    comparison.crosstabs.value = 'total'
    comparison.trends.value = 'previous-column'
    pValue.crosstabs.value = 0.05
    pValue.trends.value = 0.05
    withCalculate.clear()
  }

  watch(useResultsRoundsStore().all.rounds, () => withCalculate.clear())

  // reset state if study id shifts
  const id = computed(() => studyStore.study?.id)
  watch(id, $reset)

  return {
    enabled,
    comparison,
    pValue,
    updateComparison,
    calculate: (args: SigTestArgs) =>
      !enabled[contextKey.value].value ? 'none' : withCalculate(args).value,
    $reset,
  }
})

type SigTestArgs = {
  cardId: string
  column: ColumnDefinitionValue
  rowChild: RowChild
  row: Row
  dynamicColumnDefinitionValues?: DynamicColumnDefinitionValue[]
}

function calculateCrosstabSignificance(
  args: {
    pValueRef: Ref<number>
    comparisonRef: Ref<SigTestComparison>
    definitionsRef: Ref<DynamicColumnDefinitionValue[]>
  } & SigTestArgs,
): SigTestValue {
  if (isStaticColumnDefinitionValue(args.column)) return 'none'
  if (deepEqual(args.comparisonRef.value, args.column)) return 'none'

  const definitions = args.dynamicColumnDefinitionValues ?? args.definitionsRef.value

  const comparisonColumnArgs = (() => {
    if (args.comparisonRef.value === 'total') {
      return { ...args, column: { type: 'static', variable: 'total', value: args.cardId } as const }
    }

    if (args.comparisonRef.value === 'previous-column') {
      const foundIndex = definitions.findIndex(
        definition =>
          definition.variable === args.column.variable && definition.value === args.column.value,
      )

      return foundIndex < 1 ? undefined : { ...args, column: definitions[foundIndex - 1] }
    }

    return { ...args, column: args.comparisonRef.value }
  })()

  if (comparisonColumnArgs === undefined) {
    return 'none'
  }

  const cellRounds = useCardCellWithBaseRounds(args).value
  const comparisonCellRounds = useCardCellWithBaseRounds(comparisonColumnArgs).value
  const columnRounds = useCardColumnWithBaseRounds(args).value
  const comparisonColumnRounds = useCardColumnWithBaseRounds(comparisonColumnArgs).value
  const didNotSelectCellSize = getDidNotSelectCellSize(args.cardId, columnRounds, cellRounds)
  const didNotSelectComparisonCellSize = getDidNotSelectCellSize(
    args.cardId,
    comparisonColumnRounds,
    comparisonCellRounds,
  )

  const { twoTailedPValue } = fishersExactTest(
    cellRounds.size,
    comparisonCellRounds.size,
    didNotSelectCellSize,
    didNotSelectComparisonCellSize,
  )

  if (twoTailedPValue < args.pValueRef.value) {
    return cellRounds.size / (cellRounds.size + didNotSelectCellSize) >
      comparisonCellRounds.size / (comparisonCellRounds.size + didNotSelectComparisonCellSize)
      ? 'higher'
      : 'lower'
  }

  return 'none'
}

export function getSignificantComparisonTitle(comparison: SigTestComparison): string {
  if (comparison === 'total') return 'Total'
  if (comparison === 'previous-column')
    return useSavedSettingsStore().contextKey === 'crosstabs' ? 'Previous Column' : 'Previous Wave'
  return useCrosstabs2ColumnDefinitionsStore().getValueTitle(comparison)
}

export function getSignificanceConfidenceLevel(pValue: number): string {
  return `${(1 - pValue) * 100}%`
}

function getDidNotSelectCellSize(
  cardId: string,
  columnRounds: Set<Round>,
  cellRounds: Set<Round>,
): number {
  const { getMergedQuestionStructureByCardId } = useStudyInsightsStore()
  const card = getMergedQuestionStructureByCardId(cardId)

  const needsDifference =
    card.optionSettings.type === 'multiple' || card.optionSettings.type === 'order'
  if (needsDifference) {
    // difference is slightly slower overall, this is a very hot path
    // we only need to do difference for multiple choice or ranked options
    // otherwise we can take the column size - the cell size
    return difference(cellRounds, columnRounds).size
  }

  return columnRounds.size - cellRounds.size
}
