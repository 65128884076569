<script lang="tsx" setup>
import { getCurrentInstance, onBeforeUnmount, onMounted, shallowRef } from 'vue'

import { assertIsElement } from '@attest/dom'
import { EMPTY_VNODE } from '@attest/vue-tsx'

const props = defineProps({
  attributeFilter: null,
  attributeOldValue: { type: Boolean },
  attributes: { type: Boolean },
  characterData: { type: Boolean },
  characterDataOldValue: { type: Boolean },
  childList: { type: Boolean },
  subtree: { type: Boolean }
})

const emit = defineEmits(["mutation"])

const slots = defineSlots<{ default?: () => any }>()

const observer = shallowRef<MutationObserver | undefined>(undefined)

onMounted(() => {
  const instance = getCurrentInstance()
  observer.value = new MutationObserver(onMutation)
  const el = instance?.subTree.el
  if (!el) {
    return
  }

  assertIsElement(el)
  observer.value.observe(el, {
    attributeFilter: props.attributeFilter,
    attributeOldValue: props.attributeOldValue,
    attributes: props.attributes,
    characterData: props.characterData,
    characterDataOldValue: props.characterDataOldValue,
    childList: props.childList,
    subtree: props.subtree,
  })
})

onBeforeUnmount(() => {
  observer.value?.disconnect()
})

function onMutation(mutations: MutationRecord[]): void {
  for (const mutation of mutations) {
    emit('mutation', mutation)
  }
}

defineRender(() => {
  return slots.default?.()[0] ?? EMPTY_VNODE
})
</script>
