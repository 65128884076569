import { defineStore } from 'pinia'

import { difference, intersection, sum } from '@attest/util'

import { createCardResponseFilter } from '.'

export type CardResponseFiltersState = {
  cardIdToFilters: Record<string, CardResponseFilter>
  isEnabled: boolean
}
export type CardResponseFilter = {
  responseIds: string[]
  answerIds: string[]
  answerIdsToResponseIds: { [key: string]: string[] }
}

export const CARD_RESPONSE_FILTERS_STORE_NAMESPACE = 'cardResponseFilters'

export function createCardResponseFiltersState(
  override: Partial<CardResponseFiltersState> = {},
): CardResponseFiltersState {
  return {
    cardIdToFilters: {},
    isEnabled: false,
    ...override,
  }
}

export const useCardResponseFiltersStore = defineStore(CARD_RESPONSE_FILTERS_STORE_NAMESPACE, {
  state: createCardResponseFiltersState,
  actions: {
    async deleteAllFilters(): Promise<void> {
      this.cardIdToFilters = {}
    },

    async deleteCardFilter(cardId: string) {
      delete this.cardIdToFilters[cardId]
    },

    async setFiltersForCard<K extends keyof CardResponseFilter>(payload: {
      cardId: string
      type: K
      filters: CardResponseFilter[K]
    }): Promise<void> {
      this.cardIdToFilters[payload.cardId] = createCardResponseFilter({
        [payload.type]: payload.filters,
      })

      if (
        this.cardIdToFilters[payload.cardId].answerIds.length === 0 &&
        this.cardIdToFilters[payload.cardId].responseIds.length === 0
      ) {
        delete this.cardIdToFilters[payload.cardId]
      }
    },

    async toggleFilterForCard<
      K extends keyof Omit<CardResponseFilter, 'answerIdsToResponseIds'>,
    >(payload: { cardId: string; type: K; filter: CardResponseFilter[K][number] }): Promise<void> {
      const cardFilter = this.cardIdToFilters[payload.cardId]
      if (!cardFilter) {
        this.cardIdToFilters[payload.cardId] = createCardResponseFilter({
          [payload.type]: [payload.filter],
        })

        return
      }

      const index = cardFilter[payload.type].indexOf(payload.filter)
      if (index === -1) {
        cardFilter[payload.type] = [...cardFilter[payload.type], payload.filter]
        return
      }

      cardFilter[payload.type] = cardFilter[payload.type].toSpliced(index, 1)

      if (
        this.cardIdToFilters[payload.cardId].answerIds.length === 0 &&
        this.cardIdToFilters[payload.cardId].responseIds.length === 0
      ) {
        delete this.cardIdToFilters[payload.cardId]
      }
    },

    async toggleFiltersForCard<
      K extends keyof Omit<CardResponseFilter, 'answerIdsToResponseIds'>,
    >(payload: { cardId: string; type: K; filters: CardResponseFilter[K] }): Promise<void> {
      const cardFilter = this.cardIdToFilters[payload.cardId]
      if (!cardFilter) {
        this.cardIdToFilters[payload.cardId] = createCardResponseFilter({
          [payload.type]: payload.filters,
        })

        return
      }

      cardFilter[payload.type] = cardFilter[payload.type].some(a => payload.filters.includes(a))
        ? cardFilter[payload.type].filter(a => !payload.filters.includes(a))
        : [...cardFilter[payload.type], ...payload.filters]

      if (
        this.cardIdToFilters[payload.cardId].answerIds.length === 0 &&
        this.cardIdToFilters[payload.cardId].responseIds.length === 0
      ) {
        delete this.cardIdToFilters[payload.cardId]
      }
    },

    async toggleFiltersForRankedCard(payload: {
      cardId: string
      filters: CardResponseFilter['answerIdsToResponseIds']
    }): Promise<void> {
      const cardFilter = this.cardIdToFilters[payload.cardId]
      const [answerId, responseIds] = Object.entries(payload.filters)[0]

      if (!cardFilter) {
        this.cardIdToFilters[payload.cardId] = createCardResponseFilter({
          answerIdsToResponseIds: payload.filters,
        })

        return
      }

      const responseIdsInStore = cardFilter.answerIdsToResponseIds[answerId] ?? []
      const overlappingResponseIds = intersection([
        new Set(responseIdsInStore),
        new Set(responseIds),
      ])

      const shouldDeleteFilter =
        overlappingResponseIds.size === responseIds.length &&
        overlappingResponseIds.size === responseIdsInStore.length
      const shouldUpdateFilter = overlappingResponseIds.size === responseIds.length

      if (shouldDeleteFilter) {
        delete this.cardIdToFilters[payload.cardId].answerIdsToResponseIds[answerId]

        if (
          Object.entries(this.cardIdToFilters[payload.cardId].answerIdsToResponseIds).length === 0
        ) {
          delete this.cardIdToFilters[payload.cardId]
        }
        return
      }

      if (shouldUpdateFilter) {
        cardFilter.answerIdsToResponseIds = {
          ...cardFilter.answerIdsToResponseIds,
          [answerId]: [...difference(new Set(responseIdsInStore), new Set(responseIds))],
        }
        return
      }

      cardFilter.answerIdsToResponseIds = {
        ...cardFilter.answerIdsToResponseIds,
        [answerId]: [...new Set([...responseIdsInStore, ...responseIds])],
      }
    },

    async toggleIsEnabled(): Promise<void> {
      this.isEnabled = !this.isEnabled
    },

    async setIsEnabled(isEnabled: boolean): Promise<void> {
      this.isEnabled = isEnabled
    },
  },
  getters: {
    totalFilters(): number {
      return sum(
        Object.values(this.cardIdToFilters).map(cardFilter =>
          sum([
            cardFilter.answerIds.length,
            cardFilter.responseIds.length,
            Object.keys(cardFilter.answerIdsToResponseIds).length,
          ]),
        ),
      )
    },

    shouldFilter(): boolean {
      return this.isEnabled && this.totalFilters > 0
    },
  },
})
