import { computed } from 'vue'

import { intersection } from '@attest/util'

import type { ColumnDefinitionValue } from '../column-definition'
import type { Row, RowChild } from '../row'

import { useCardColumnRounds, useCardColumnWithBaseRounds } from './column'
import { useMemoizeRounds } from './memoization'
import { useCardRowChildRounds, useCardRowChildWithBaseRounds } from './row-child'

export const useCardCellRounds = useMemoizeRounds(
  (payload: { cardId: string; column: ColumnDefinitionValue; row: Row; rowChild: RowChild }) => {
    return intersection([useCardColumnRounds(payload).value, useCardRowChildRounds(payload).value])
  },
)

export const useCardCellWithBaseRounds = (payload: {
  cardId: string
  column: ColumnDefinitionValue
  row: Row
  rowChild: RowChild
}) => {
  return computed(() =>
    intersection([
      useCardColumnWithBaseRounds(payload).value,
      useCardRowChildWithBaseRounds(payload).value,
    ]),
  )
}
