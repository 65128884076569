import { nanoid } from 'nanoid'
import type { MarkOptional } from 'ts-essentials'

import { SETTINGS } from '@attest/editor-settings'

import type { Audience } from '../model'
import { getDefaultSampleSize } from '../targeting/sample-size'

export function createAudience(override: MarkOptional<Audience, 'id'>): Audience {
  return {
    id: nanoid(),
    ...override,
  }
}

export function createDefaultAudience(): Audience {
  return createAudience({
    country: SETTINGS.AUDIENCE.DEFAULT_COUNTRY,
    language: SETTINGS.AUDIENCE.DEFAULT_LANGUAGE,
    name: 'United Kingdom (English)',
    sampleSize: getDefaultSampleSize(),
    segmentations: {},
    quotas: [],
    audienceId: null,
    useDraftLanguage: false,
    status: 'NEW',
  })
}
