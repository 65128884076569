import { isDefaultOption, useDemographicsStore } from '@attest/audience-demographic'
import { useResultsRoundsStore, useStudyInsightsStore } from '@attest/results-core'
import { useSavedFiltersStore } from '@attest/results-saved-filters'
import { isStudyQuestionCard, type Subject, useStudyStore } from '@attest/study'
import { groupBy, intersection } from '@attest/util'

const ageBands = [[18, 24], [25, 34], [35, 44], [45, 54], [55, 64], [65]]

export type CrossTabType = 'standard' | 'stacked'

type StaticColumnDefinitionType = 'static'
export type StaticColumnDefinition = {
  type: StaticColumnDefinitionType
  variable: string
  values: string[]
}
type StaticColumnDefinitionValue = {
  type: StaticColumnDefinitionType
  variable: string
  value: string
  titleOverride?: string
}

export type DynamicColumnDefinitionType =
  | 'segment'
  | 'question'
  | 'age'
  | 'country'
  | 'date'
  | 'demographic'
  | 'audience'
export type DynamicColumnDefinition = {
  type: DynamicColumnDefinitionType
  variable: string
  subjects?: readonly Subject[]
  values: string[]
  titleOverride?: string
}
export type DynamicColumnDefinitionValue = {
  type: DynamicColumnDefinitionType
  variable: string
  value: string
  titleOverride?: string
}

export type StackedColumnDefinitionValue = DynamicColumnDefinitionValue & {
  stackedColumnDefinitionValue?: DynamicColumnDefinitionValue
}

export type ColumnDefinitionType = StaticColumnDefinitionType | DynamicColumnDefinitionType
export type ColumnDefinition = StaticColumnDefinition | DynamicColumnDefinition
export type ColumnDefinitionValue =
  | StaticColumnDefinitionValue
  | DynamicColumnDefinitionValue
  | StackedColumnDefinitionValue

export function isStaticColumnDefinitionValue(
  definition: ColumnDefinitionValue,
): definition is StaticColumnDefinitionValue {
  return definition.type === 'static'
}

export function isStackedColumnDefinitionValue(
  definition: ColumnDefinitionValue,
): definition is StackedColumnDefinitionValue {
  return !!(definition as StackedColumnDefinitionValue).stackedColumnDefinitionValue
}

export function createAllStaticColumnDefinitionsForCard(cardId: string): StaticColumnDefinition[] {
  return [createStaticTotalColumnDefinition(cardId)]
}

function createStaticTotalColumnDefinition(cardId: string): StaticColumnDefinition {
  return {
    type: 'static',
    variable: 'total',
    values: [cardId],
  }
}

export function createAllStackedColumnDefinitions(): DynamicColumnDefinition[] {
  const definitions = [...createAllAgeColumnDefinitions(), ...createGenderColumnDefinitions()]

  const dateDefinitions = createAllDateColumnDefinitions()
  const audienceDefinitions = createAllAudienceColumnDefinitions()
  if (dateDefinitions[0].values.length > 1) {
    definitions.push(...dateDefinitions)
  }
  if (audienceDefinitions[0].values.length > 1) {
    definitions.push(...audienceDefinitions)
  }
  return definitions
}
export function createGenderColumnDefinitions(): DynamicColumnDefinition[] {
  return createAllDemographicColumnDefinitions().filter(def => def.values.includes('male'))
}

export function createAllDateColumnDefinitions(
  includedPublishedTimestamps?: Set<number>,
): DynamicColumnDefinition[] {
  const studyStore = useStudyStore()
  return [
    {
      type: 'date',
      variable: 'date',
      values: Object.keys(
        groupBy(
          Object.values(
            studyStore.sentWaveSurveys.filter(ws =>
              includedPublishedTimestamps
                ? includedPublishedTimestamps.has(ws.publishedTimestamp)
                : true,
            ),
          ).toSorted((a, b) => a.publishedTimestamp - b.publishedTimestamp),
          ({ publishedTimestamp }) => publishedTimestamp,
        ),
      ),
    },
  ]
}

export function getAnswerIdsForGridQuestionSubjects(
  cardId: string,
  subjectIds: string[],
  optionIds: string[],
): string[] {
  const studyInsightsStore = useStudyInsightsStore()
  const structureForCard = studyInsightsStore.getMergedQuestionStructureByCardId(cardId)
  const subjectAnswers = structureForCard.subjects
    .filter(s => subjectIds.includes(s.id))
    .flatMap(s => s.answerIds)
  const optionAnswers = structureForCard.options
    .filter(o => optionIds.includes(o.id))
    .flatMap(o => o.answerIds)
  const intersectedAnswer = intersection([new Set(subjectAnswers), new Set(optionAnswers)])

  return Array.from(intersectedAnswer)
}

export function createAllQuestionColumnDefinitions(): DynamicColumnDefinition[] {
  const studyInsightsStore = useStudyInsightsStore()
  const questionTypes = new Set(['choice', 'grid'])
  return studyInsightsStore.filteredCards
    .filter(isStudyQuestionCard)
    .filter(card => questionTypes.has(card.question.type))
    .map(card => ({
      type: 'question',
      variable: card.id,
      subjects: studyInsightsStore.getMergedQuestionStructureByCardId(card.id).subjects,
      values: card.question.optionGroups
        .map(({ ids }) => ids)
        .filter(ids =>
          ids.every(id =>
            studyInsightsStore
              .getMergedQuestionStructureByCardId(card.id)
              .options.map(option => option.id)
              .includes(id),
          ),
        )
        .map(ids => ids.toString()),
    }))
}

export function createAllCountryColumnDefinitions(
  includedCountries?: Set<string>,
): DynamicColumnDefinition[] {
  const studyStore = useStudyStore()
  return [
    {
      type: 'country',
      variable: 'country',
      values: Object.keys(
        groupBy(
          Object.values(
            studyStore.sentWaveSurveys.filter(ws =>
              includedCountries ? includedCountries.has(ws.country) : true,
            ),
          ),
          ({ country }) => country,
        ),
      ),
    },
  ]
}

export function createAllAudienceColumnDefinitions(
  includedAudiences?: Set<string>,
): DynamicColumnDefinition[] {
  const studyStore = useStudyStore()
  return [
    {
      type: 'audience',
      variable: 'audience',
      values: Object.keys(
        groupBy(
          Object.values(
            studyStore.sentWaveSurveys.filter(ws =>
              includedAudiences ? includedAudiences.has(ws.audienceId) : true,
            ),
          ),
          ({ audienceId }) => audienceId,
        ),
      ),
    },
  ]
}

export function createAllAgeColumnDefinitions(): DynamicColumnDefinition[] {
  const values = ageBands.map(band => band.toString())

  return values.length > 0
    ? [
        {
          type: 'age',
          variable: 'age',
          values,
        },
      ]
    : []
}

export function createAllSegmentColumnDefinitions(): DynamicColumnDefinition[] {
  const savedFiltersStore = useSavedFiltersStore()
  const values = savedFiltersStore.filtersSortedByShared.map(({ id }) => id)
  return values.length > 0
    ? [
        {
          type: 'segment',
          variable: 'segment',
          values: savedFiltersStore.filtersSortedByShared.map(({ id }) => id),
        },
      ]
    : []
}

export function createAllDemographicColumnDefinitions(): DynamicColumnDefinition[] {
  const demographicsStore = useDemographicsStore()

  return Object.entries(useResultsRoundsStore().successfulSubjectValueToRounds)
    .filter(
      ([variable]) =>
        variable !== 'age' && demographicsStore.demographicNameToDemographic[variable],
    )
    .map(
      ([variable, values]) =>
        [demographicsStore.demographicNameToDemographic[variable], values] as const,
    )
    .toSorted(([a], [b]) => a.order - b.order)
    .map(([demog, values]) => ({
      type: 'demographic',
      variable: demog.name,
      values: demog.options
        .filter(isDefaultOption)
        .filter(option => values[option.name])
        .map(option => option.name),
    }))
}
