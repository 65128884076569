import type { SavedApiGroup, SavedApiNode } from '@attest/_api/model/node'
import {
  transformApiQuotasToQuotas,
  transformApiSegmentationsToSegmentations,
} from '@attest/audience'
import type { AnswerQuota } from '@attest/editor-card'
import type { ApiTag } from '@attest/tag'

import type { Survey, SurveyCard, SurveyGroup, SurveyViewership } from '../../model'
import { isFlatQuestionCard } from '../../model'
import type { ApiSurvey, ApiSurveyViewership } from '../model'
import { isApiGroup } from '../util'

import {
  transformApiCardsToCardGuidToAnswerQuota,
  transformCardGuidToAnswerIdToQuotaToQuotaIdToQuota,
} from './answer-quotas'
import { toSurveyCardsModel } from './card'
import { transformApiRecurrenceToRecurrence } from './recurrence'

export function toSurveyModel(survey: ApiSurvey): Survey {
  const cardGuidToAnswerIdToQuota = transformApiCardsToCardGuidToAnswerQuota(survey.cards ?? [])

  return {
    accessCode: survey.access_code || null,
    cards: toSurveyCardsModel(survey.cards || []).map(card => {
      const answerQuotas = cardGuidToAnswerIdToQuota[card.id]
      if (answerQuotas === undefined) {
        return card
      }
      return decorateCardWithAnswerQuota(card, answerQuotas)
    }),
    groups: toSurveyGroupsModel(survey.cards || []),
    countries: survey.countries ?? [],
    coverNote: survey.cover_note || null,
    creationTimestamp: survey.created,
    endTimestamp: survey.expires,
    exclusiveSampleGuids: survey.exclusive_sample_guids || [],
    guid: survey.guid,
    internalTitle: survey.internal_title || null,
    isRandomized: survey.randomized,
    isShared: survey.shared,
    languages: [survey.language],
    lastAnswerTimestamp: survey.last_answer || null,
    maxResponses: survey.max_responses === undefined ? null : survey.max_responses,
    publishTimestamp: survey.published !== undefined ? survey.published : null,
    quotas: transformApiQuotasToQuotas(survey.quotas ?? []),
    responses: survey.responses,
    segmentations: transformApiSegmentationsToSegmentations(survey.segmentations ?? {}),
    startTimestamp: survey.starts,
    status: survey.status,
    tags: (survey.tags || []).map((tag: ApiTag) => ({ id: tag.id, name: tag.name })),
    timeZoneId: survey.time_zone_id || null,
    template: survey.template || null,
    title: survey.title,
    titleImage: survey.title_image || null,
    viewership: toViewership(survey.viewership),
    makerGuid: survey.maker_guid || null,
    recurrence: transformApiRecurrenceToRecurrence(survey.recurrence ?? null),
    answerQuotas: transformCardGuidToAnswerIdToQuotaToQuotaIdToQuota(cardGuidToAnswerIdToQuota),
    waves: [],
  }
}

export function toViewership(viewership: ApiSurveyViewership): SurveyViewership {
  return viewership === 'me' ? 'own' : viewership
}

function toSurveyGroupsModel(apiNodes: SavedApiNode[]): SurveyGroup[] {
  return apiNodes
    .filter((node): node is SavedApiGroup => isApiGroup(node))
    .map(({ guid, next, children }) => ({
      id: guid,
      next: next ?? undefined,
      children: children
        .map(child => ({
          id: apiNodes[child.index]?.guid,
          isRandomized: child.randomized,
        }))
        .filter(
          (child: {
            id: string | undefined
            isRandomized: boolean
          }): child is { id: string; isRandomized: boolean } => child.id !== undefined,
        ),
    }))
}

function decorateCardWithAnswerQuota(
  card: SurveyCard,
  answerIdToAnswerQuota: Record<string, AnswerQuota>,
): SurveyCard {
  if (!isFlatQuestionCard(card)) return card
  return {
    ...card,
    answers: card.answers.map(answer => ({
      ...answer,
      quotaId: answerIdToAnswerQuota[answer.id]?.id,
    })),
  }
}
