import { useStudyInsightsStore } from '@attest/results-core'
import {
  createRoundAnswerIdsFilterExpression,
  createRoundAnswersOrderFilterNode,
  type FilterExpression,
  type FilterNode,
  NONE,
  OR,
} from '@attest/rounds'
import type { MergedStudyQuestionStructure, Option, Subject } from '@attest/study'
import { intersection, never } from '@attest/util'

import type { Row, RowChild } from '../../row'

export function createRowChildFilterNode(payload: {
  cardId: string
  row: Row
  rowChild: RowChild
}): FilterNode {
  const { cardId, row, rowChild } = payload
  const { getMergedQuestionStructureByCardId, getQuestionCardById } = useStudyInsightsStore()
  const card = getQuestionCardById(cardId)
  const structure = getMergedQuestionStructureByCardId(cardId)
  const rowIds = row.id.split(',')
  const rowChildIds = rowChild.id.split(',')

  switch (card.question.type) {
    case 'choice':
      return createChoiceRowChildFilterExpression({ cardId, structure, rowChildIds })
    case 'ranked':
      return createRankedRowChildFilterNode({ cardId, structure, rowIds, rowChildIds })
    case 'nps':
      return createNPSRowChildFilterNode({ cardId, structure, rowChildIds })
    case 'grid':
      return createGridRowChildFilterExpression({ cardId, structure, rowIds, rowChildIds })
    case 'open-text':
    case 'video':
      return NONE()
    default:
      throw never(card.question.type)
  }
}

function createChoiceRowChildFilterExpression(payload: {
  cardId: string
  structure: MergedStudyQuestionStructure
  rowChildIds: string[]
}): FilterExpression {
  const { cardId, structure, rowChildIds } = payload
  return createRoundAnswerIdsFilterExpression(
    cardId,
    createRowAnswerIds(structure.options, rowChildIds),
  )
}

function createGridRowChildFilterExpression(payload: {
  cardId: string
  structure: MergedStudyQuestionStructure
  rowIds: string[]
  rowChildIds: string[]
}): FilterExpression {
  const { cardId, structure, rowIds, rowChildIds } = payload
  return createRoundAnswerIdsFilterExpression(cardId, [
    ...intersection([
      new Set(createRowAnswerIds(structure.subjects, rowIds)),
      new Set(createRowAnswerIds(structure.options, rowChildIds)),
    ]),
  ])
}

const npsValues: Record<string, number[]> = {
  promoters: [9, 10],
  passives: [7, 8],
  detractors: [0, 1, 2, 3, 4, 5, 6],
}

function createRankedRowChildFilterNode(payload: {
  cardId: string
  structure: MergedStudyQuestionStructure
  rowIds: string[]
  rowChildIds: string[]
}): FilterNode {
  const { cardId, structure, rowIds, rowChildIds } = payload
  return createRoundAnswersOrderFilterNode(
    cardId,
    createRowAnswerIds(structure.options, rowIds),
    rowChildIds.map(id => Number.parseInt(id)),
  )
}

function createNPSRowChildFilterNode(payload: {
  cardId: string
  structure: MergedStudyQuestionStructure
  rowChildIds: string[]
}): FilterNode {
  const { cardId, structure, rowChildIds } = payload
  return OR(
    rowChildIds.map(id => {
      if (npsValues[id]) {
        return createRoundAnswerIdsFilterExpression(
          cardId,
          createRowAnswerIds(
            structure.options,
            structure.options
              .filter(option => npsValues[id].includes(Number.parseInt(option.text)))
              .map(option => option.id),
          ),
        )
      }

      return createRoundAnswerIdsFilterExpression(
        cardId,
        createRowAnswerIds(structure.options, [id]),
      )
    }),
  )
}

function createRowAnswerIds(
  items: readonly Option[] | readonly Subject[],
  ids: string[],
): string[] {
  return items.filter(item => ids.includes(item.id)).flatMap(item => item.answerIds)
}
