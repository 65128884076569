import axios, { type AxiosResponse } from 'axios'

import type {
  SavedCardIdToSavedCardSettings,
  SavedCardSettingsApiResponse,
  SavedOrganisationSettingsApiResponse,
  SavedStudySettings,
} from './model'

export const ORGANISATION_SETTINGS_ENDPOINT = `/api/results/ui-settings`

export async function getSavedOrganisationSettings(): Promise<{
  settingsVersion: number
  settings: {
    colours: { default: string[] }
  }
}> {
  try {
    const response = await axios.get<SavedOrganisationSettingsApiResponse>(
      ORGANISATION_SETTINGS_ENDPOINT,
    )

    const settingsVersion = Number(response.headers[HEADER_VERSION_KEY] ?? 0)

    return {
      settingsVersion,
      settings: {
        colours: response.data?.colours?.default
          ? { default: response.data.colours.default }
          : { default: [] },
      },
    }
  } catch (e) {
    throw new Error(e)
  }
}

export async function updateSavedOrganisationSettings(
  organisationSettings: {
    colours: {
      default: string[]
    }
  },
  settingsVersion: number,
): Promise<AxiosResponse<SavedOrganisationSettingsApiResponse>> {
  return axios.put<SavedOrganisationSettingsApiResponse>(
    ORGANISATION_SETTINGS_ENDPOINT,
    organisationSettings,
    {
      headers: {
        [HEADER_VERSION_KEY]: settingsVersion,
      },
    },
  )
}

export const SETTINGS_ENDPOINT = (studyGuid: string): string =>
  `/api/v2/study/${studyGuid}/results/ui-settings`
export const HEADER_VERSION_KEY = 'x-ui-settings-version'

export async function getSavedSettings(studyGuid: string): Promise<{
  cards: SavedCardIdToSavedCardSettings
  study?: SavedStudySettings
  settingsVersion: number
}> {
  try {
    const response = await axios.get<SavedCardSettingsApiResponse>(SETTINGS_ENDPOINT(studyGuid))

    const settingsVersion = Number(response.headers[HEADER_VERSION_KEY])

    return {
      settingsVersion,
      cards: response.data.cards,
      study: response.data.study,
    }
  } catch (e) {
    throw new Error(e)
  }
}

export async function updateSavedSettings(
  studyGuid: string,
  cardSettings: SavedCardIdToSavedCardSettings,
  settingsVersion: number,
  studySettings?: SavedStudySettings,
): Promise<AxiosResponse<SavedCardSettingsApiResponse>> {
  return axios.put<SavedCardSettingsApiResponse>(
    SETTINGS_ENDPOINT(studyGuid),
    {
      cards: cardSettings,
      study: studySettings,
    },
    {
      headers: {
        [HEADER_VERSION_KEY]: settingsVersion,
      },
    },
  )
}
