import type { CountryLanguages } from '../model'

export const getCountryLanguageKey = (
  country: string,
  language: string,
  countryLanguages: CountryLanguages,
): string | null =>
  Object.keys(countryLanguages).find(
    key => countryLanguages[key].country === country && countryLanguages[key].language === language,
  ) ?? null
