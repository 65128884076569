import type { CardDisplayLogicError } from '@attest/_lib/src/editor-error/editor-error'
import { createGetErrorsFor } from '@attest/editor'
import {
  type AnswerQuota,
  type AnswerQuotaId,
  type Card,
  type CardGuid,
  getActiveAnswerQuotas,
  isQualifying,
} from '@attest/editor-card'
import type { DisplayLogicRule } from '@attest/editor-display-logic'
import type { RoutingGraph } from '@attest/editor-routing-graph'

export const getCardDisplayLogicErrors = createGetErrorsFor<
  CardDisplayLogicError['type'],
  {
    card: Card
    randomizedGroupGraph: RoutingGraph
    guidToDisplayLogicRule: Record<CardGuid, DisplayLogicRule>
    pipingFrom: (CardGuid | undefined)[]
    answerQuotas: Record<AnswerQuotaId, AnswerQuota>
    cardsVisibleForAudiences: { [audienceId: string]: Set<string> }
  }
>({
  CARD_DISPLAY_LOGIC_REFERENCE_RANDOMIZED_WITH_TARGET({
    card,
    randomizedGroupGraph,
    guidToDisplayLogicRule,
  }) {
    return (
      guidToDisplayLogicRule[card.guid]?.conditions.some(condition => {
        const sourceGuid = condition.referenceGuid
        if (!sourceGuid) {
          return false
        }

        return randomizedGroupGraph.hasNode(card.guid) && randomizedGroupGraph.hasNode(sourceGuid)
      }) ?? false
    )
  },
  CARD_DISPLAY_LOGIC_IS_REFERENCE_AND_RANDOMIZED_WITH_TARGET({
    card,
    randomizedGroupGraph,
    guidToDisplayLogicRule,
  }) {
    return Object.values(guidToDisplayLogicRule)
      .flatMap(rule => [...rule.conditions.map(condition => ({ ...condition, guid: rule.guid }))])
      .filter(({ referenceGuid }) => referenceGuid === card.guid)
      .some(
        ({ guid }) => randomizedGroupGraph.hasNode(card.guid) && randomizedGroupGraph.hasNode(guid),
      )
  },
  CARD_DISPLAY_LOGIC_DIFFERENT_LOGIC_THAN_PIPING_SOURCE({
    guidToDisplayLogicRule,
    pipingFrom,
    card,
  }) {
    if (pipingFrom.length === 0) {
      return false
    }

    for (const senderGuid of pipingFrom) {
      if (senderGuid === undefined) {
        continue
      }

      const senderHasDifferentDisplayConditionToSource =
        targetCardHasDisplayLogicThatIsDifferentToSourceCard(
          guidToDisplayLogicRule[senderGuid],
          guidToDisplayLogicRule[card.guid],
        )

      if (senderHasDifferentDisplayConditionToSource) {
        return true
      }
    }

    return false
  },
  CARD_DISPLAY_LOGIC_DIFFERENT_LOGIC_THAN_MASKING_SENDER({ card, guidToDisplayLogicRule }) {
    if (card?.question?.options.maskingQuestion?.isToggled === false) {
      return false
    }

    const senderGuid = card?.question?.options.maskingQuestion?.senderGuid
    if (!senderGuid) {
      return false
    }

    return targetCardHasDisplayLogicThatIsDifferentToSourceCard(
      guidToDisplayLogicRule[senderGuid],
      guidToDisplayLogicRule[card.guid],
    )
  },
  CARD_DISPLAY_LOGIC_WITH_QUALIFYING_ANSWER_QUOTAS({ card, guidToDisplayLogicRule, answerQuotas }) {
    return (
      guidToDisplayLogicRule[card.guid] !== undefined &&
      isQualifying(card) &&
      getActiveAnswerQuotas(card, answerQuotas).length > 0
    )
  },
  CARD_DISPLAY_LOGIC_INVISIBLE_TO_ALL_AUDIENCES: ({ card, cardsVisibleForAudiences }) => {
    const audienceIds = Object.keys(cardsVisibleForAudiences)
    if (audienceIds.length === 0) {
      return false
    }
    return audienceIds.every(audienceId => !cardsVisibleForAudiences[audienceId]?.has(card.guid))
  },
})

function targetCardHasDisplayLogicThatIsDifferentToSourceCard(
  sourceCardDisplayLogic: DisplayLogicRule | undefined,
  targetCardDisplayLogic: DisplayLogicRule | undefined,
): boolean {
  if (
    sourceCardDisplayLogic === undefined ||
    (sourceCardDisplayLogic === undefined && targetCardDisplayLogic === undefined)
  ) {
    return false
  }
  return (
    sourceCardDisplayLogic.operator !== targetCardDisplayLogic?.operator ||
    sourceCardDisplayLogic.conditions?.length !== targetCardDisplayLogic?.conditions?.length ||
    !sourceCardDisplayLogic.conditions?.every(conditionSender =>
      targetCardDisplayLogic?.conditions?.some(
        conditionReceiver => conditionSender.referenceGuid === conditionReceiver.referenceGuid,
      ),
    )
  )
}
