import { useResultsRoundsStore } from '@attest/results-core'

import type { Row, RowChild } from '../row'

import { createRowChildFilterNode } from './factory/row-child'
import { useMemoizeRounds } from './memoization'

export const useCardRowChildRounds = useMemoizeRounds(
  (payload: { cardId: string; row: Row; rowChild: RowChild }) => {
    return useResultsRoundsStore()
      .card({ id: payload.cardId })
      .filtered.filter(createRowChildFilterNode(payload)).value
  },
)

export const useCardRowChildWithBaseRounds = useMemoizeRounds(
  (payload: { cardId: string; row: Row; rowChild: RowChild }) => {
    return useResultsRoundsStore()
      .cardWithBase({
        id: payload.cardId,
        subjectIds: payload.row.id.split(','),
        optionIds: payload.rowChild.id.split(','),
      })
      .filtered.filter(createRowChildFilterNode(payload)).value
  },
)
