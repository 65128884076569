import type { RouteLocation, RouteRecordRaw } from 'vue-router'

import { creditCentsToCredit } from '@attest/_lib/src/pricing/pricing'
import { calculateSurveyPrice } from '@attest/_lib/src/pricing/service/calculator'
import { useAudienceEditorStore } from '@attest/audience'
import { SETTINGS } from '@attest/editor-settings'
import { useEditorRoutingGraphStore, useEditorSurveyStore } from '@attest/editor-survey'
import {
  SURVEY_DRAFTER_ROUTE_NAME,
  SurveyDrafterCardEditorViewToRouteName,
  VIEWS,
} from '@attest/router'
import {
  createPermissionEntity,
  createSurveyEntity,
  type PermissionEntity,
  useGetTrackingEntities,
} from '@attest/tracking'
import {
  createSurveyPricingEntity,
  type SurveyPricingEntity,
} from '@attest/tracking/src/entity/survey-pricing'

import {
  CARD_EDITOR_VIEW,
  type CardEditorProps,
  getCardEditorViewSessionValue,
  setCardEditorViewSessionValue,
} from './components/organism/CardEditor'
import type { SurveyDrafterEditorProps } from './components/organism/SurveyDrafterEditor/interface'

export function createSurveyDrafterRoute(): RouteRecordRaw {
  return {
    path: '/editor/:guid',
    meta: {
      authenticationRequired: 'authenticated',
      ...useGetTrackingEntities(route => [
        ...createEditorPermissionEntities(),
        ...(route.params.guid === SETTINGS.DEFAULT_GUID
          ? []
          : [
              createSurveyEntity({ id: route.params.guid as string }),
              createEditorSurveyPricingEntity(),
            ]),
      ]),
    },
    components: {
      default: async () =>
        (await import('./components/organism/SurveyDrafterEditor/SurveyDrafterEditor.vue')).default,
      [VIEWS.NAVBAR]: async () =>
        (await import('./components/organism/SurveyDrafterNavbar/SurveyDrafterNavbar.vue')).default,
    },
    props: {
      default: transformRouteToSurveyDrafterEditorProps,
      [VIEWS.NAVBAR]: transformRouteToSurveyDrafterEditorProps,
    },
    children: [
      {
        path: 'draft',
        alias: ['/editor/:guid'],
        name: SURVEY_DRAFTER_ROUTE_NAME.CARD_EDITOR,
        redirect(to) {
          const { guid } = transformRouteToSurveyDrafterEditorProps(to)
          return {
            name:
              getCardEditorViewSessionValue(guid) ??
              SURVEY_DRAFTER_ROUTE_NAME.CARD_EDITOR_LIST_VIEW,
          }
        },
        meta: {
          authenticationRequired: 'either',
          showNavbarTopbar: true,
          showProgressBar: false,
        },
        props(to): CardEditorProps {
          const { guid } = transformRouteToSurveyDrafterEditorProps(to)
          const view =
            to.name === SURVEY_DRAFTER_ROUTE_NAME.CARD_EDITOR_MAP_VIEW
              ? CARD_EDITOR_VIEW.MAP
              : CARD_EDITOR_VIEW.LIST
          setCardEditorViewSessionValue(guid, (to.name as string) ?? undefined)
          return { view, viewToRouteName: SurveyDrafterCardEditorViewToRouteName }
        },
        component: async () =>
          (await import('./components/organism/CardEditor/CardEditor.vue')).default,
        children: [
          {
            name: SURVEY_DRAFTER_ROUTE_NAME.CARD_EDITOR_LIST_VIEW,
            path: 'list',
            meta: {
              authenticationRequired: 'either',
              showNavbarTopbar: true,
              showProgressBar: false,
            },
            component: async () =>
              (await import('./components/organism/EditorListView/EditorListView.vue')).default,
          },
          {
            name: SURVEY_DRAFTER_ROUTE_NAME.CARD_EDITOR_MAP_VIEW,
            path: 'map',
            meta: {
              authenticationRequired: 'either',
              showNavbarTopbar: true,
              showProgressBar: false,
            },
            component: async () =>
              (await import('./components/organism/EditorMapView/EditorMapView.vue')).default,
          },
        ],
      },
      {
        path: 'audience',
        component: async () =>
          (await import('./components/organism/SurveyDrafterAudience/SurveyDrafterAudience.vue'))
            .default,
        meta: {
          authenticationRequired: 'authenticated',
        },
        children: [
          {
            path: '',
            name: SURVEY_DRAFTER_ROUTE_NAME.AUDIENCE,
            meta: {
              authenticationRequired: 'authenticated',
              showNavbarTopbar: true,
              showProgressBar: false,
            },
            props: {
              managingAudiencesForStudy: false,
            },
            component: async () =>
              (await import('@attest/audience-editor/src/organism/ManageAudiences'))
                .ManageAudiences,
          },
          {
            name: SURVEY_DRAFTER_ROUTE_NAME.AUDIENCE_EXCLUSIONS,
            path: 'exclusions',
            meta: {
              authenticationRequired: 'authenticated',
              showNavbarTopbar: true,
              showProgressBar: false,
            },
            component: async () =>
              (
                await import(
                  '@attest/audience-editor/src/organism/AudienceEditorExclusiveSamplesTab'
                )
              ).AudienceEditorExclusiveSamplesTab,
            children: [
              {
                name: SURVEY_DRAFTER_ROUTE_NAME.EDIT_AUDIENCE_EXCLUSIONS,
                path: 'edit',
                meta: {
                  authenticationRequired: 'authenticated',
                  showNavbarTopbar: true,
                  showProgressBar: false,
                },
                component: async () =>
                  (await import('./components/organism/ModalExcludeRespondents.vue')).default,
              },
            ],
          },
        ],
      },
      {
        name: SURVEY_DRAFTER_ROUTE_NAME.REVIEW,
        path: 'review',
        meta: {
          authenticationRequired: 'authenticated',
          showNavbarTopbar: true,
          showProgressBar: false,
        },
        component: async () =>
          (await import('../review/components/view/SurveyDrafterReview/SurveyDrafterReview.vue'))
            .default,
      },
      {
        name: SURVEY_DRAFTER_ROUTE_NAME.REVIEW_CHECKOUT,
        path: 'checkout',
        alias: 'review/checkout',
        meta: {
          authenticationRequired: 'authenticated',
          showNavbarTopbar: true,
          showProgressBar: false,
        },
        component: async () =>
          (await import('../review/components/view/SurveyDrafterReview/SurveyDrafterReview.vue'))
            .default,
      },
    ],
  }
}

function transformRouteToSurveyDrafterEditorProps(to: RouteLocation): SurveyDrafterEditorProps {
  return {
    guid: to.params.guid === SETTINGS.DEFAULT_GUID ? undefined : (to.params.guid as string),
  }
}

function createEditorSurveyPricingEntity(): SurveyPricingEntity {
  return createSurveyPricingEntity({
    cost: creditCentsToCredit(
      calculateSurveyPrice({
        audience: useAudienceEditorStore().type,
        surveyType: useEditorSurveyStore().isVideoResponseSurvey ? 'qual' : 'quant',
        numResponses: useAudienceEditorStore().respondentCount,
        numQuestions: useEditorRoutingGraphStore().countOfQuestionsInLongestRouteOverall,
        numCountries: useAudienceEditorStore().filledAudiences.length,
        paymentMethod: 'wallet',
      }).cents,
    ),
    surveyLength: useEditorRoutingGraphStore().countOfQuestionsInLongestRouteOverall,
  })
}

function createEditorPermissionEntities(): PermissionEntity[] {
  return [
    createPermissionEntity({
      id: 'SURVEY_CAN_EDIT',
      value: useEditorSurveyStore().isSurveyOwner,
    }),
  ]
}
