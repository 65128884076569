<script lang="tsx">
const baseClass = 'attest-accordion'
</script>
<script lang="tsx" setup>
import { mergeDefaults as __MACROS_mergeDefaults } from "vue";
import { useId } from 'vue'

import IconChevron from '../../../assets/icons/chevron_right.svg?component'
import { AttestIcon } from '../AttestIcon'

defineProps(__MACROS_mergeDefaults({
  open: { type: Boolean },
  size: null,
  alignChevron: null
}, { size: 'default',alignChevron: 'right', }))

const emit = defineEmits(["toggle"])

const slots = defineSlots<{ header: () => any; content: () => any }>()



const labelledbyId = useId()
const controlsId = useId()

defineRender(() => {
  return (
    <div
      class={[
        baseClass,
        `${baseClass}--size-${__props.size}`,
        `${baseClass}--align-chevron-${__props.alignChevron}`,
        { [`${baseClass}--open`]: __props.open },
        'o-outline-focus-visible',
      ]}
    >
      <button
        id={labelledbyId}
        class={[`${baseClass}__header`]}
        onClick={event => {
          emit('toggle', { event, open: !__props.open })
        }}
        data-name="AttestAccordionHeader"
        aria-controls={controlsId}
        aria-expanded={__props.open}
      >
        {slots.header()}
        <AttestIcon class={`${baseClass}__chevron`} aria-hidden="true">
          <IconChevron />
        </AttestIcon>
      </button>
      <div
        id={controlsId}
        class={`${baseClass}__content`}
        inert={!__props.open}
        hidden={!__props.open}
        role="region"
        aria-labelledby={labelledbyId}
      >
        {slots.content()}
      </div>
    </div>
  )
})
</script>

<style src="../../css/components/accordion.css" />
