import { defineStore } from 'pinia'
import { computed, ref, watch, watchEffect } from 'vue'
import {
  type RouteLocationNormalizedLoaded,
  type RouteLocationResolvedGeneric,
  useRouter,
} from 'vue-router'

import { sortWavesByDescendingStartTime, useStudyStore } from '@attest/study'
import { difference, intersection } from '@attest/util'

import { getStoreKeyForRoute, parseQueryValue } from './util'

export const useStudyWaveDateFiltersStore = defineStore('studyWaveDateFilters', () => {
  const studyStore = useStudyStore()
  const router = useRouter()

  const storedPublishedTimestampsForRoute = ref(new Map<string, Set<number>>())

  function getDefaultPublishedTimestamps(
    route: RouteLocationNormalizedLoaded | RouteLocationResolvedGeneric,
  ): Set<number> {
    const { name } = route
    const wavesSortedByPublishedTimestamp = Array.from(
      new Set(
        sortWavesByDescendingStartTime(studyStore.sentWaveSurveys).map(
          wave => wave.publishedTimestamp,
        ),
      ),
    )
    return new Set(
      wavesSortedByPublishedTimestamp.slice(0, name === 'STUDY::INSIGHTS::TRENDS' ? 3 : 1),
    )
  }

  const areAnyDatesValid = computed(() => {
    const anyDatesPresent = !!router.currentRoute.value.query.date
    const publishedDates = getPublishedTimestampsForRoute(router.currentRoute.value)
    const allDates = new Set(studyStore.sentWaveSurveys.map(wave => wave.publishedTimestamp))
    if (!anyDatesPresent || allDates.size === 0 || publishedDates.size === 0) {
      return false
    }
    return intersection([publishedDates, allDates]).size > 0
  })

  function getPublishedTimestampsForRoute(
    route: RouteLocationNormalizedLoaded | RouteLocationResolvedGeneric,
  ): Set<number> {
    const { query } = route
    const timestampsForRoute = storedPublishedTimestampsForRoute.value.get(
      getStoreKeyForRoute(route),
    )
    if (timestampsForRoute && timestampsForRoute.size > 0) return timestampsForRoute
    return query.date
      ? new Set(parseQueryValue(query.date).map(timestamp => Number.parseInt(timestamp)))
      : getDefaultPublishedTimestamps(route)
  }

  // update stored state when route changes
  // when navigating between pages the waves are retained
  watch(
    router.currentRoute,
    route => {
      if (!route.params.studyId) return
      if (!route.query.date) return

      storedPublishedTimestampsForRoute.value.set(
        getStoreKeyForRoute(router.currentRoute.value),
        new Set(parseQueryValue(route.query.date).map(date => Number.parseInt(date))),
      )
    },
    { immediate: true },
  )

  // optimize the published timestamps being set, if the published timestamps are directly
  // calculated in a computed property then any change to the router (query string changes)
  // will trigger a reactive chain. Instead we should watch the router and update a ref if there
  // are any differences
  const publishedTimestampsForCurrentRoute = ref(getPublishedTimestampsForCurrentRoute())
  function getPublishedTimestampsForCurrentRoute(): Set<number> {
    return areAnyDatesValid.value
      ? getPublishedTimestampsForRoute(router.currentRoute.value)
      : getDefaultPublishedTimestamps(router.currentRoute.value)
  }
  watchEffect(() => {
    const newPublishedTimestamps = getPublishedTimestampsForCurrentRoute()
    if (difference(newPublishedTimestamps, publishedTimestampsForCurrentRoute.value).size > 0) {
      publishedTimestampsForCurrentRoute.value = newPublishedTimestamps
    }
  })

  return {
    publishedTimestamps: publishedTimestampsForCurrentRoute,
    getPublishedTimestampsForRoute,
  }
})
