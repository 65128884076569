import { computed, type ComputedRef, onMounted, onUnmounted, ref, type Ref } from 'vue'

import { useStorageSystem } from '@attest/composables'

const localStorageCache = ref<{ [key: string]: string | null }>({})

export const useReactiveLocalStorage = (key: string): Ref<null | string> => {
  const storage = useStorageSystem()

  const handler = (event: StorageEvent): void => {
    if (event.key === key) {
      localStorageCache.value[key] = event.newValue
    }
  }

  onMounted(() => {
    window.addEventListener('storage', handler)
    localStorageCache.value[key] = storage?.getItem(key) ?? null
  })

  onUnmounted(() => {
    window.removeEventListener('storage', handler)
  })

  return computed({
    get() {
      return localStorageCache.value[key] ?? null
    },
    set(newValue: string | null) {
      if (newValue === null) {
        storage?.removeItem(key)
      } else {
        storage?.setItem(key, newValue)
      }
      localStorageCache.value[key] = newValue
    },
  })
}

export function useLocalStorageSet(key: string): {
  localStorageSet: ComputedRef<Set<string>>
  add: (newValue: string) => void
} {
  const stringRepresentationOfSet = useReactiveLocalStorage(key)

  const localStorageSet: ComputedRef<Set<string>> = computed(() => {
    if (stringRepresentationOfSet.value === null) {
      return new Set()
    }
    try {
      const parsedObject = JSON.parse(stringRepresentationOfSet.value)
      if (!Array.isArray(parsedObject)) {
        return new Set()
      }
      return new Set(parsedObject.map(String))
    } catch {
      return new Set()
    }
  })

  return {
    localStorageSet,
    add: newValue =>
      (stringRepresentationOfSet.value = JSON.stringify([
        ...(localStorageSet.value ?? []),
        newValue,
      ])),
  }
}
