import { nanoid } from 'nanoid'
import { defineStore } from 'pinia'

import type { InternalError, ServiceError } from '@attest/_api'
import { type SavedFilter, type Segment, segmentToSavedFilter } from '@attest/segments'

import * as api from '../api'

import { useCardResponseFiltersStore } from './card-response-filters'
import { useResultsDemographicFiltersStore } from './demographic-filters'

type Status = 'loading' | 'loaded' | 'error' | 'saving' | null

export type SavedFiltersState = {
  studyGuid: string | null
  filters: SavedFilter[]
  loadedId: string | null
  listStatus: Status
  saveStatus: Status
  updateStatus: Status
  renameStatus: Status
  deleteStatus: Status
  deleteId: string | null
}

export const SAVED_FILTERS_STORE_NAMESPACE = 'savedFilters'

export function createFiltersStoreState(
  override: Partial<SavedFiltersState> = {},
): SavedFiltersState {
  return {
    studyGuid: null,
    filters: [],
    loadedId: null,
    listStatus: null,
    saveStatus: null,
    updateStatus: null,
    renameStatus: null,
    deleteStatus: null,
    deleteId: null,
    ...override,
  }
}

export const useSavedFiltersStore = defineStore(SAVED_FILTERS_STORE_NAMESPACE, {
  state: createFiltersStoreState,

  getters: {
    filterIdToFilter(): Record<string, SavedFilter> {
      return Object.fromEntries(this.filters.map(filter => [filter.id, filter]))
    },
    filtersSortedByShared(): SavedFilter[] {
      return this.filters
        .map(filter => filter)
        .sort((filterA, filterB) => {
          if (filterA.isShared && !filterB.isShared) {
            return 1
          }
          if (!filterA.isShared && filterB.isShared) {
            return -1
          }
          return 0
        })
    },
    firstSharedIndex(): number {
      return this.filtersSortedByShared.findIndex(filter => filter.isShared)
    },
  },

  actions: {
    saveSegment(args: { segment: Segment }): void {
      this.save(args.segment.name, segmentToSavedFilter({ segment: args.segment }))
    },

    updateSegment(args: { segment: Segment }): void {
      this.update(segmentToSavedFilter({ segment: args.segment }))
    },

    async get(studyGuid: string): Promise<InternalError<ServiceError<never>> | void> {
      try {
        this.listStatus = 'loading'
        const savedFilters = await api.getSavedFilters(studyGuid)

        this.studyGuid = studyGuid
        this.filters = savedFilters
        this.listStatus = 'loaded'
      } catch (e) {
        this.listStatus = 'error'
        throw e
      }
    },

    async rename(
      filterId: string,
      newName: string,
    ): Promise<InternalError<ServiceError<never>> | void> {
      if (!this.studyGuid) return
      const filter = this.filterIdToFilter[filterId]
      if (!filter) throw new Error('filter not found')
      if (filter.name === newName) return

      try {
        this.renameStatus = 'saving'
        await api.updateFilter(this.studyGuid, filterId, {
          ...filter,
          name: newName,
        })
        filter.name = newName
        this.renameStatus = null
      } catch (e) {
        this.renameStatus = 'error'
        throw e
      }
    },
    async update(filter: SavedFilter) {
      if (!this.studyGuid) return
      try {
        await api.updateFilter(this.studyGuid, filter.id, { ...filter })
        this.filters = this.filters.map(f => {
          if (f.id === filter.id) {
            return filter
          }
          return f
        })
      } catch (e) {
        this.updateStatus = 'error'
        throw e
      }
    },

    async delete(filterId: string): Promise<InternalError<ServiceError<never>> | void> {
      if (!this.studyGuid) return
      try {
        this.deleteStatus = 'loading'
        this.deleteId = filterId
        await api.deleteFilter(this.studyGuid, filterId)
        const index = this.filters.findIndex(({ id }) => id === filterId)
        this.filters.splice(index, 1)
        this.deleteStatus = null
        this.deleteId = null
      } catch (e) {
        this.deleteStatus = 'error'
        throw e
      }
    },

    async save(title: string, savedFilter?: SavedFilter) {
      if (!this.studyGuid) return
      const resultDemographicFilters = useResultsDemographicFiltersStore()
      const demographicFilters = JSON.parse(
        JSON.stringify(useResultsDemographicFiltersStore().nameToFilters),
      )
      const studyCardFilters = JSON.parse(
        JSON.stringify(useCardResponseFiltersStore().cardIdToFilters),
      )

      this.saveStatus = 'loading'
      const newFilter: SavedFilter = savedFilter
        ? { ...savedFilter, name: title }
        : ({
            guid: '',
            name: title,
            demographicFilters,
            studyCardFilters,
            countries: ['GB'],
          } as any)

      let attempts = 3
      do {
        newFilter.id = newFilter.id ? newFilter.id : nanoid()
        try {
          await api.createFilter(this.studyGuid, newFilter)
          break
        } catch (e) {
          if (attempts === 1) {
            this.saveStatus = 'error'
            throw e
          }
          // todo validate that it's a guid error
        }
      } while (--attempts > 0)

      this.filters.push(newFilter)
      resultDemographicFilters.reset({})
      useCardResponseFiltersStore().cardIdToFilters = {}
      this.loadedId = null
      this.saveStatus = 'loaded'
    },

    async load(filterId: string): Promise<void> {
      if (!this.studyGuid) return
      const resultDemographicFilters = useResultsDemographicFiltersStore()

      const filter = this.filterIdToFilter[filterId]
      if (!filter) throw new Error('filter not found')

      if (filterId === this.loadedId) {
        resultDemographicFilters.reset({})
        useCardResponseFiltersStore().cardIdToFilters = {}
        this.loadedId = null
        return
      }

      resultDemographicFilters.reset(JSON.parse(JSON.stringify(filter.demographicFilters)))
      useCardResponseFiltersStore().cardIdToFilters = JSON.parse(
        JSON.stringify(filter.studyCardFilters),
      )

      this.loadedId = filterId
    },
  },
})
