import {
  createCardEntity,
  createQuestionEntity,
  createValidationEntity,
  type Entity,
  trackAction,
} from '@attest/tracking'

export type EditorError<T extends EditorErrorType = EditorErrorType> = {
  type: T
}

export type EditorErrorType =
  | CardErrorType
  | ChoiceAnswerErrorType
  | SampleSizeError['type']
  | ExternalTitleErrorType
  | InternalTitleErrorType
  | SurveyErrorType
  | ScheduleErrorType
  | TargetingErrorType
  | TargetingsErrorType
  | PurchaseErrorType
  | GroupCardErrorType

export type SurveyError = EditorError<SurveyErrorType>
export type CardError = EditorError<CardErrorType>
export type CardTitleError = EditorError<CardTitleErrorType>
export type CardTextError = EditorError<CardTextErrorType>
export type CardPipingError = EditorError<CardPipingErrorType>
export type CardMaskingError = EditorError<CardMaskingErrorType>
export type CardGroupedPipingError = EditorError<CardGroupedPipingErrorType>
export type CardDisplayLogicError = EditorError<CardDisplayLogicErrorType>
export type CardQualifyingError = EditorError<CardQualifyingErrorType>
export type ChoiceAnswerError = EditorError<ChoiceAnswerErrorType>
export type GroupCardError = EditorError<GroupCardErrorType>
export type SampleSizeError = EditorError<SampleSizeErrorType>
export type ExternalTitleError = EditorError<ExternalTitleErrorType>
export type InternalTitleError = EditorError<InternalTitleErrorType>
export type TranslationError = EditorError<TranslationErrorType>
export type ScheduleError = EditorError<ScheduleErrorType>
export type TargetingError = EditorError<TargetingErrorType>
export type TargetingsError = EditorError<TargetingsErrorType>
export type TargetingRangeError = EditorError<TargetingRangeErrorType>
export type CardRoutingError = EditorError<CardRoutingErrorType>
export type CardRoutingAnswerError = EditorError<CardRoutingAnswerErrorType>
export type PurchaseError = EditorError<PurchaseErrorType>

export type SurveyErrorType =
  | 'ALL_QUESTIONS_ARE_QUALIFYING'
  | 'QUALIFYING_IN_MIDDLE_OF_SURVEY'
  | 'TOO_MANY_QUESTIONS_IN_ROUTE'
  | 'TOO_MANY_TEXT_CARDS_IN_A_ROW'
  | 'ENDING_CARD_IS_A_TEXT_CARD'
  | 'ALL_CARDS_ARE_TEXT_CARDS'
  | 'CARDS_CANNOT_BE_REACHED'
  | 'TOO_MANY_QUALIFYING_QUESTIONS'
  | 'MISSING_PRIVACY_POLICY'
  | 'VIDEO_QUESTIONS_NOT_ENABLED'
  | 'VIDEO_QUESTION_SURVEY_WITH_TOO_MANY_VIDEO_QUESTIONS'
  | 'VIDEO_QUESTION_SURVEY_WITH_TOO_MANY_QUALIFYING_QUESTIONS'
  | 'VIDEO_QUESTION_SURVEY_WITH_TOO_MANY_NON_VIDEO_QUESTIONS'
  | TargetingErrorType
  | TargetingsErrorType
  | SampleSizeErrorType
  | ExternalTitleErrorType
  | InternalTitleErrorType
  | GenericQuestionErrorType
  | ScheduleErrorType
  | CardRandomizationErrorType
  | EmptySurveyError
  | TranslationErrorType

type CardErrorType =
  | CardTextErrorType
  | QuestionCardErrorType
  | CardPipingErrorType
  | CardMaskingErrorType
  | CardGroupedPipingErrorType
  | CardRoutingErrorType
  | CardRoutingAnswerErrorType
  | CardRandomizingErrorType
  | GroupCardErrorType
  | CardQualifyingErrorType
  | CardDisplayLogicErrorType

type CardTextErrorType = (typeof CARD_TEXT_ERRORS)[number]
type CardTitleErrorType = (typeof CARD_TITLE_ERRORS)[number]
type QuestionCardBodyErrorType = (typeof QUESTION_BODY_ERRORS)[number]
type QuestionCardErrorType =
  | CardTitleErrorType
  | QuestionCardBodyErrorType
  | ChoiceQuestionCardAnswersErrorType
type ExternalTitleErrorType = (typeof SURVEY_EXTERNAL_TITLE_ERRORS)[number]
type InternalTitleErrorType = (typeof SURVEY_INTERNAL_TITLE_ERRORS)[number]
type TranslationErrorType = 'MISSING_TRANSLATIONS'
type SampleSizeErrorType =
  | 'SAMPLE_SIZE_TOO_SMALL'
  | 'SAMPLE_SIZE_TOO_BIG'
  | 'AVAILABLE_SIZE_TOO_SMALL'
  | 'QUAL_SAMPLE_SIZE_TOO_HIGH'
type ScheduleErrorType = 'START_BEFORE_NOW' | 'START_BEFORE_PENDING_SCHEDULE'
type CardPipingErrorType = (typeof CARD_PIPING_ERRORS)[number]
type CardMaskingErrorType = (typeof CARD_MASKING_ERRORS)[number]
type CardGroupedPipingErrorType = (typeof CARD_GROUPED_PIPING_ERRORS)[number]
type CardDisplayLogicErrorType = (typeof CARD_DISPLAY_LOGIC_ERRORS)[number]
type CardRoutingErrorType = (typeof CARD_ROUTING_ERRORS)[number]
type CardRoutingAnswerErrorType = (typeof CARD_ROUTING_ANSWER_ERRORS)[number]
type CardRandomizingErrorType = (typeof CARD_RANDOMIZING_ERRORS)[number]
type CardQualifyingErrorType = (typeof CARD_QUALIFYING_ERRORS)[number]
type ChoiceQuestionCardAnswersErrorType = (typeof QUESTION_CARD_ANSWERS_ERRORS)[number]
type ChoiceAnswerErrorType = (typeof QUESTION_CARD_ANSWER_ERRORS)[number]
type GroupCardErrorType = (typeof GROUP_CARD_ERRORS)[number]
type TargetingErrorType =
  | TargetingRangeErrorType
  | TargetingQuotasMisalignedType
  | 'TARGETING_QUOTA_BELOW_SAMPLE_SIZE'
type TargetingsErrorType = 'EMPTY_TARGETINGS' | 'NO_VISIBLE_TARGETINGS'
type TargetingRangeErrorType = (typeof TARGETING_RANGE_ERRORS)[number]
type TargetingQuotasMisalignedType = 'TARGETING_QUOTAS_MISALIGNED'
type PurchaseErrorType = (typeof PURCHASE_ERRORS)[number]
type GenericQuestionErrorType = (typeof GENERIC_QUESTION_ERRORS)[number]
type CardRandomizationErrorType = 'TEXT_CARD_RANDOMISED_WITH_LAST_QUESTION_CARD'
type EmptySurveyError = 'EMPTY_SURVEY' | 'EMPTY_SURVEY_FOR_AUDIENCE'

const CARD_MASKING_ERRORS = [
  'CARD_MASKING_TO_DIFFERENT_ROUTE',
  'SENDER_MASKING_AFTER_RECEIVER',
  'RECEIVER_OR_SENDER_ARE_RANDOMIZED',
  'RECEIVER_NOT_IN_SAME_GROUP_AS_SENDER',
] as const

const CARD_PIPING_ERRORS = [
  'CARD_PIPING_FROM_INVALID_QUESTION_TYPE',
  'CARD_PIPING_TITLE_TOO_LONG',
  'CARD_PIPING_FROM_DIFFERENT_ROUTE',
  'CARD_PIPING_FROM_FUTURE_QUESTION',
  'CARD_PIPING_FROM_NON_EXISTENT_QUESTION',
  'CARD_PIPING_FROM_SELF',
  'CARD_PIPING_TO_RANDOMIZED_QUESTION',
  'CARD_PIPING_FROM_RANDOMIZED_QUESTION',
  'CARD_PIPING_FROM_QUESTION_BETWEEN_RANDOMIZED_CARDS',
  'CARD_PIPING_FROM_QUESTION_WITH_EMPTY_ANSWER_TEXT',
  'CARD_PIPING_VISIBILITY_FOR_AUDIENCE_DIFFERENT',
] as const

const CARD_GROUPED_PIPING_ERRORS = ['CARD_IS_PIPING_AND_GROUPED'] as const

const CARD_DISPLAY_LOGIC_ERRORS = [
  'CARD_DISPLAY_LOGIC_REFERENCE_RANDOMIZED_WITH_TARGET',
  'CARD_DISPLAY_LOGIC_IS_REFERENCE_AND_RANDOMIZED_WITH_TARGET',
  'CARD_DISPLAY_LOGIC_DIFFERENT_LOGIC_THAN_PIPING_SOURCE',
  'CARD_DISPLAY_LOGIC_DIFFERENT_LOGIC_THAN_MASKING_SENDER',
  'CARD_DISPLAY_LOGIC_WITH_QUALIFYING_ANSWER_QUOTAS',
  'CARD_DISPLAY_LOGIC_INVISIBLE_TO_ALL_AUDIENCES',
] as const

const CARD_TEXT_ERRORS = [
  'CARD_TEXT_MISSING',
  'CARD_TEXT_TOO_SHORT',
  'CARD_TEXT_TOO_LONG',
  'CARD_TEXT_HAS_PLACEHOLDER_TEXT',
] as const
const CARD_TITLE_ERRORS = [
  'CARD_TITLE_MISSING',
  'CARD_TITLE_TOO_SHORT',
  'CARD_TITLE_TOO_LONG',
  'CARD_TITLE_HAS_PLACEHOLDER_TEXT',
] as const

const QUESTION_BODY_ERRORS = ['MISSING_QUESTION_TYPE'] as const
const QUESTION_CARD_ANSWERS_ERRORS = [
  'IMAGE_ANSWERS_MISSING_IMAGES',
  'IMAGE_CAPTION_ANSWERS_TOO_LONG',
  'TEXT_ANSWERS_TOO_LONG',
  'NOT_ENOUGH_ANSWERS',
  'NOT_ENOUGH_ANSWERS_FOR_AUDIENCE',
  'GRID_NOT_ENOUGH_SUBJECTS',
  'GRID_NOT_ENOUGH_SUBJECTS_FOR_AUDIENCE',
  'GRID_TOO_MANY_SUBJECTS',
  'GRID_TOO_MANY_SUBJECTS_IF_MASKING',
  'GRID_SUBJECTS_TOO_LONG',
  'GRID_NOT_ENOUGH_OPTIONS',
  'GRID_SUBJECTS_TOO_LONG',
  'GRID_OPTIONS_TOO_LONG',
  'GRID_OPTIONS_MISSING_IMAGES',
  'OPEN_TEXT_FIELD_TITLE_TOO_LONG',
  'VIDEO_PROMPT_TOO_LONG',
] as const

const CARD_QUALIFYING_ERRORS = [
  'QUALIFYING_WITH_ANSWER_ROUTES_IS_NOT_LAST_QUALIFYING',
  'QUALIFYING_QUOTAS_SUM_TOO_HIGH',
  'QUALIFYING_QUOTAS_AMOUNT_USED_UP',
  'INVALID_ANSWER_QUOTA_VALUES',
  'AUDIENCE_CANT_QUALIFY',
  'NOT_ALL_AUDIENCES_SEE_CARD_WITH_ANSWER_QUOTAS',
] as const

export const SURVEY_EXTERNAL_TITLE_ERRORS = [
  'SURVEY_TITLE_TOO_SHORT',
  'SURVEY_TITLE_TOO_LONG',
  'SURVEY_TITLE_IS_MISSING',
] as const

const QUESTION_CARD_ANSWER_ERRORS = [
  'IMAGE_CAPTION_ANSWER_TOO_LONG',
  'DUPLICATE_ANSWERS',
  'QUALIFYING_EMPTY_ANSWER',
  'TEXT_ANSWER_TOO_LONG',
  'IMAGE_ANSWERS_MISSING_IMAGES',
  'ANSWER_HAS_PLACEHOLDER',
] as const

const GROUP_CARD_ERRORS = [
  'EMPTY_GROUP',
  'ADJACENT_TEXT_CARDS_IN_GROUP',
  'ADJACENT_TEXT_CARD_AND_GROUP_TEXT_CARD',
  'ADJACENT_TEXT_CARDS_BETWEEN_GROUPS',
  'GROUP_CARD_CONTAINS_CARDS_WITH_CARD_ERRORS',
] as const

export const SURVEY_INTERNAL_TITLE_ERRORS = ['SURVEY_INTERNAL_TITLE_TOO_LONG'] as const

const TARGETING_RANGE_ERRORS = [
  'TARGETING_AGE_RANGES_OVERLAP',
  'TARGETING_AGE_RANGES_INVALID',
  'TARGETING_BELOW_MIN_AGE',
  'VIDEO_SURVEY_TARGETING_BELOW_MIN_AGE',
] as const

const CARD_ROUTING_ERRORS = [
  'CARD_ROUTING_NOT_REACHABLE',
  'CARD_ROUTING_IS_CYCLIC',
  'CARD_ROUTING_TO_OTHER_THAN_FIRST_RANDOMISED_CARD',
  'CARD_DISCONNECTED_FROM_MAIN_RANDOMISATION_GROUP',
  'CARD_ROUTING_TOO_MANY_ROUTES_ON_MULTIPLE_CHOICE_QUESTION',
] as const

const CARD_ROUTING_ANSWER_ERRORS = [
  'CARD_ROUTING_ANSWER_AND_RANDOMIZED_QUESTION',
  'CARD_ROUTING_ANSWER_FROM_QUESTION_BETWEEN_RANDOMIZED_CARDS',
  'CARD_ROUTING_ANSWER_TO_OTHER_THAN_FIRST_RANDOMISED_CARD',
  'CARD_ROUTING_ANSWER_IS_CYCLIC',
  'CARD_ROUTING_ANSWER_IS_EMPTY',
] as const

const CARD_RANDOMIZING_ERRORS = ['QUESTION_RANDOMIZED_AND_QUALIFYING'] as const

const PURCHASE_ERRORS = ['ERRORS_IN_SURVEY'] as const

const GENERIC_QUESTION_ERRORS = ['QUESTIONS_HAVE_ERRORS'] as const

export const isCardRoutingError = (
  error: EditorError,
): error is EditorError<CardRoutingErrorType> => {
  return (CARD_ROUTING_ERRORS as readonly EditorErrorType[]).includes(error.type)
}

export const isQuestionTitleError = (error: EditorError): error is CardTitleError => {
  return (CARD_TITLE_ERRORS as readonly EditorErrorType[]).includes(error.type)
}

export const isQuestionError = (
  error: EditorError,
): error is EditorError<QuestionCardErrorType> => {
  return (
    CARD_TITLE_ERRORS.includes(error.type as CardTitleErrorType) ||
    QUESTION_BODY_ERRORS.includes(error.type as QuestionCardBodyErrorType)
  )
}

export const isTargetingRangeError = (
  error: EditorError,
): error is EditorError<TargetingRangeErrorType> => {
  return TARGETING_RANGE_ERRORS.includes(error.type as any)
}

export const isEmptySurveyError = (error: SurveyError): boolean => {
  return error.type === ('EMPTY_SURVEY' as EmptySurveyError)
}

export const isCardQualifyingError = (
  error: EditorError,
): error is EditorError<CardQualifyingErrorType> => {
  return (CARD_QUALIFYING_ERRORS as readonly EditorErrorType[]).includes(error.type)
}

function getUniqueErrors(
  previousErrors: EditorError[],
  currentErrors: EditorError[],
): Record<string, EditorError[]> {
  return {
    resolvedErrors: previousErrors.filter(
      error => !currentErrors.some(currentError => currentError.type === error.type),
    ),
    newErrors: currentErrors.filter(
      error => !previousErrors.some(previousError => previousError.type === error.type),
    ),
  }
}

type CardForTracking = {
  guid: string
  question: { type: string | null }
}

export function trackEditorErrors(
  previousErrors: EditorError[] = [],
  currentErrors: EditorError[] = [],
  errorType = 'editor-error',
  card?: CardForTracking,
): void {
  const { resolvedErrors, newErrors } = getUniqueErrors(previousErrors, currentErrors)
  const cardEntities = card ? createEditorCardEntitiesFromCard(card) : []

  resolvedErrors.forEach(error =>
    trackAction({
      id: 'resolve-error',
      entities: [
        createValidationEntity({
          id: error.type,
          type: errorType,
        }),
        ...cardEntities,
      ],
    }),
  )
  newErrors.forEach(error =>
    trackAction({
      id: 'new-error',
      entities: [
        createValidationEntity({
          id: error.type,
          type: errorType,
        }),
        ...cardEntities,
      ],
    }),
  )
}

export function createEditorCardEntitiesFromCard(card: CardForTracking): Entity[] {
  return [
    createCardEntity({ id: card.guid }),
    createQuestionEntity({ id: card.guid, type: card.question.type }),
  ]
}
