<script lang="tsx">
import { defineComponent } from 'vue'

import { EMPTY_VNODE } from '@attest/vue-tsx'

import { useUserStore } from '../../store'
import InlineUser from '../InlineUser/InlineUser.vue'

export default defineComponent({
  render() {
    const loggedInUser = useUserStore().user

    if (!loggedInUser) return EMPTY_VNODE

    const baseClass = 'c-logged-in-inline-user'

    return (
      <InlineUser
        class={baseClass}
        theme="coral"
        data-name="LoggedInInlineUserInlineUser"
        user={loggedInUser}
      />
    )
  },
})
</script>
<style lang="postcss">
.c-logged-in-inline-user {
  @context scoped {
    .c-inline-user__name {
      @media (--attest-media-s) {
        display: none;
      }

      @media (--attest-media-xl) {
        display: block;
      }
    }
  }
}
</style>
