import { useStudyInsightsStore } from '@attest/results-core'
import { useSavedFiltersStore } from '@attest/results-saved-filters'
import {
  AND,
  createRoundAnswerIdsFilterExpression,
  createRoundAudienceCountryFilterExpression,
  createRoundAudienceIdFilterExpression,
  createRoundCardIdFilterExpression,
  createRoundSegmentationsFilterNode,
  createRoundSegmentFilterNode,
  createRoundWaveTimestampFilterExpression,
  type FilterExpression,
  type FilterNode,
  NONE,
  NOT,
} from '@attest/rounds'
import { STATIC_ANSWER_ID } from '@attest/study'

import { type ColumnDefinitionValue, isStackedColumnDefinitionValue } from '../../column-definition'

export function createDefinitionValueFilterNode(payload: {
  cardId: string
  column: ColumnDefinitionValue
}): FilterNode {
  const { cardId } = payload
  const card = useStudyInsightsStore().getQuestionCardById(cardId)
  const staticAnswerIdExclusions = card.question.type === 'ranked' ? [STATIC_ANSWER_ID.NA] : []
  return AND(
    NOT(
      createRoundAnswerIdsFilterExpression(cardId, [
        STATIC_ANSWER_ID.SKIPPED,
        ...staticAnswerIdExclusions,
      ]),
    ),
    createCombinedColumnFilterNode(payload),
  )
}

function createCombinedColumnFilterNode(payload: {
  cardId: string
  column: ColumnDefinitionValue
}): FilterNode {
  const { column } = payload
  if (isStackedColumnDefinitionValue(column) && column.stackedColumnDefinitionValue) {
    return AND(
      createCombinedColumnFilterNode({
        cardId: payload.cardId,
        column: column.stackedColumnDefinitionValue,
      }),
      createColumnFilterNode(payload),
    )
  }

  return createColumnFilterNode(payload)
}

function createColumnFilterNode(payload: { column: ColumnDefinitionValue }): FilterNode {
  const { column } = payload
  switch (column.type) {
    case 'static':
      return createStaticDefinitionValueFilterNode({ column })
    case 'date':
      return createRoundWaveTimestampFilterExpression(Number.parseInt(column.value))
    case 'question':
      return createQuestionDefinitionValueFilterExpression({ column })
    case 'demographic':
      return createRoundSegmentationsFilterNode(column.variable, column.value.split(','))
    case 'age':
      return createAgeDefinitionValueFilterNode({ column })
    case 'country':
      return createRoundAudienceCountryFilterExpression(column.value)
    case 'audience':
      return createRoundAudienceIdFilterExpression(column.value)
    case 'segment':
      return createSegmentDefinitionValueFilterNode({ column })
    default:
      throw new Error('Definition type not supported')
  }
}

function createStaticDefinitionValueFilterNode(payload: {
  column: ColumnDefinitionValue
}): FilterNode {
  const { column } = payload
  if (column.variable === 'total') {
    return createRoundCardIdFilterExpression(column.value)
  }

  return NONE()
}

function createQuestionDefinitionValueFilterExpression(payload: {
  column: ColumnDefinitionValue
}): FilterExpression {
  const { column } = payload
  const { getMergedQuestionStructureByCardId } = useStudyInsightsStore()
  return createRoundAnswerIdsFilterExpression(
    column.variable,
    getMergedQuestionStructureByCardId(column.variable)
      .options.filter(({ id }) => column.value.split(',').includes(id))
      .flatMap(option => option.answerIds),
  )
}

function createSegmentDefinitionValueFilterNode(payload: {
  column: ColumnDefinitionValue
}): FilterNode {
  const { column } = payload
  const filter = useSavedFiltersStore().filterIdToFilter[column.value]
  if (filter && filter.efl) {
    return createRoundSegmentFilterNode(filter.efl)
  }

  return NONE()
}

function createAgeDefinitionValueFilterNode(payload: {
  column: ColumnDefinitionValue
}): FilterNode {
  const { column } = payload
  const [min, max = Number.POSITIVE_INFINITY] = column.value.split(',').map(v => Number.parseInt(v))
  return createRoundSegmentationsFilterNode(column.variable, [{ min, max }])
}
