import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

import { c } from '@attest/intl'
import { useStudyWaveDateFiltersStore, useStudyWaveFiltersStore } from '@attest/results-core'
import { useStudyStore } from '@attest/study'
import { cloneRaw } from '@attest/util'

import {
  type ColumnDefinitionValue,
  createAllAgeColumnDefinitions,
  createAllAudienceColumnDefinitions,
  createAllCountryColumnDefinitions,
  createAllDateColumnDefinitions,
  createAllDemographicColumnDefinitions,
  createAllQuestionColumnDefinitions,
  createAllSegmentColumnDefinitions,
  createAllStackedColumnDefinitions,
  createAllStaticColumnDefinitionsForCard,
  type CrossTabType,
  type DynamicColumnDefinition,
  type DynamicColumnDefinitionValue,
  getAnswerIdsForGridQuestionSubjects,
  getDefinitionTitle,
  getDefinitionValueMetadata,
  getDefinitionValueTitle,
  type StaticColumnDefinition,
} from '../column-definition'

import { type SigTestComparison, useSigTestStore } from './sig-test'

export const useCrosstabs2ColumnDefinitionsStore = defineStore(
  'crosstabs2ColumnDefinitions',
  () => {
    const studyStore = useStudyStore()
    const sigTestStore = useSigTestStore()

    const crosstabType = ref<CrossTabType>('standard')
    const includeSubtotal = ref<boolean>(true)
    const stackedColumnDefinition = ref<DynamicColumnDefinition>()
    const dynamicDefinitions = ref<DynamicColumnDefinition[]>([])
    const visualisation = ref<'crosstabs' | 'charts'>('crosstabs')

    const getStaticDefinitionsForCard = computed(
      () =>
        // eslint-disable-next-line unicorn/consistent-function-scoping
        (cardId: string): StaticColumnDefinition[] =>
          createAllStaticColumnDefinitionsForCard(cardId),
    )
    const getDefinitionsForCard = computed(() => {
      return (cardId: string) => [
        ...createAllStaticColumnDefinitionsForCard(cardId),
        ...dynamicDefinitions.value,
      ]
    })

    const stackedDefinitions = computed(() => createAllStackedColumnDefinitions())
    const dateDefinitions = computed(() =>
      createAllDateColumnDefinitions(useStudyWaveDateFiltersStore().publishedTimestamps),
    )
    const questionDefinitions = computed(() => createAllQuestionColumnDefinitions())
    const ageDefinitions = computed(() => createAllAgeColumnDefinitions())
    const countryDefinitions = computed(() =>
      createAllCountryColumnDefinitions(
        new Set(useStudyWaveFiltersStore().deserialisedAudiences.map(a => a.country)),
      ),
    )
    const audienceDefinitions = computed(() => createAllAudienceColumnDefinitions())
    const demographicDefinitions = computed(() => createAllDemographicColumnDefinitions())
    const segmentDefinitions = computed(() => createAllSegmentColumnDefinitions())

    const anyFailingDefinitions = computed(() => {
      return false
    })

    function clearDefinitions(): void {
      dynamicDefinitions.value = []
      sigTestStore.updateComparison('total')
    }

    function setDefinition(definition: DynamicColumnDefinition): void {
      dynamicDefinitions.value = [
        ...dynamicDefinitions.value.filter(
          ({ type, variable }) => type !== definition.type || variable !== definition.variable,
        ),
        cloneRaw(definition),
      ]
    }

    function addDefinition(definition: DynamicColumnDefinition): void {
      setDefinition(definition)
    }

    function deleteDefinition(definition: DynamicColumnDefinition): void {
      dynamicDefinitions.value = dynamicDefinitions.value.filter(
        ({ type, variable }) => type !== definition.type || variable !== definition.variable,
      )
      checkAndUpdateSigTestComparison(sigTestStore.comparison.crosstabs)
    }

    function addDefinitionValue(definition: DynamicColumnDefinitionValue): void {
      const column = dynamicDefinitions.value.find(
        ({ type, variable }) => type === definition.type && variable === definition.variable,
      )

      addDefinition({
        ...definition,
        values: [...(column ? column.values : []), definition.value],
      })
    }

    function deleteDefinitionValue(definition: DynamicColumnDefinitionValue): void {
      const column = dynamicDefinitions.value.find(
        ({ type, variable }) => type === definition.type && variable === definition.variable,
      )

      if (!column) return

      const values = column.values.filter(value => definition.value !== value)

      if (values.length === 0) {
        deleteDefinition(column)
        return
      }

      setDefinition({ ...definition, values })
      checkAndUpdateSigTestComparison(sigTestStore.comparison.crosstabs)
    }

    function checkAndUpdateSigTestComparison(comparison: SigTestComparison): void {
      if (typeof comparison !== 'string') {
        const foundComparison = dynamicDefinitions.value.find(
          definition =>
            definition.variable === comparison.variable &&
            definition.values.includes(comparison.value),
        )
        if (!foundComparison) sigTestStore.updateComparison('total')
      }
    }

    function setStackedColumnDefinition(
      columnDefinition: DynamicColumnDefinition | undefined,
    ): void {
      stackedColumnDefinition.value = columnDefinition
      checkAndUpdateSigTestComparison(sigTestStore.comparison.crosstabs)
    }

    function dynamicColumnsWithSubtotal(
      subTotalValue: string | undefined,
      dynamicColumns?: DynamicColumnDefinition[],
    ): DynamicColumnDefinition[] {
      const columns = dynamicColumns ?? dynamicDefinitions.value
      if (includeSubtotal.value && subTotalValue && stackedColumnDefinition.value) {
        const stackedColumn = {
          ...stackedColumnDefinition.value,
          values: [subTotalValue],
          titleOverride: c('subtotal'),
        }
        return [...columns, stackedColumn]
      }
      return columns
    }

    function $reset(): void {
      sigTestStore.$reset()
      dynamicDefinitions.value = []
      stackedColumnDefinition.value = undefined
    }

    // reset state if study id shifts
    const id = computed(() => studyStore.study?.id)
    watch(id, $reset)

    return {
      anyFailingDefinitions,

      dynamicDefinitions: computed(() =>
        dynamicDefinitions.value.map(dynamicDefinition => {
          if (dynamicDefinition.type === 'country') {
            const includedCountries = new Set(
              useStudyWaveFiltersStore().deserialisedAudiences.map(a => a.country),
            )
            return {
              ...dynamicDefinition,
              values: dynamicDefinition.values.filter(value => includedCountries.has(value)),
            }
          }
          if (dynamicDefinition.type === 'date') {
            return {
              ...dynamicDefinition,
              values: dynamicDefinition.values.filter(value =>
                useStudyWaveDateFiltersStore().publishedTimestamps.has(Number(value)),
              ),
            }
          }
          return dynamicDefinition
        }),
      ),
      dynamicColumnsWithSubtotal,
      dateDefinitions,
      questionDefinitions,
      ageDefinitions,
      countryDefinitions,
      audienceDefinitions,
      demographicDefinitions,
      segmentDefinitions,
      stackedDefinitions,

      stackedColumnDefinition: computed(() => {
        if (!stackedColumnDefinition.value?.type) {
          return
        }
        return {
          ...stackedColumnDefinition.value,
          values: stackedColumnDefinition.value?.values.filter(value => {
            if (stackedColumnDefinition.value?.type === 'date') {
              return useStudyWaveDateFiltersStore().publishedTimestamps.has(Number(value))
            }
            if (stackedColumnDefinition.value?.type === 'country') {
              const includedCountries = new Set(
                useStudyWaveFiltersStore().deserialisedAudiences.map(a => a.country),
              )
              return includedCountries.has(value)
            }
            return true
          }),
        }
      }),
      setStackedColumnDefinition,

      crosstabType,
      visualisation,
      includeSubtotal,

      addDefinition,
      addDefinitionValue,
      deleteDefinition,
      deleteDefinitionValue,
      clearDefinitions,

      getAnswerIdsForGridQuestionSubjects: getAnswerIdsForGridQuestionSubjects,
      getTitle: getDefinitionTitle,
      getValueTitle: getDefinitionValueTitle,
      getValueMetadata: getDefinitionValueMetadata,
      isErrorDefinitionValue: (_definition: ColumnDefinitionValue): boolean => false,
      getStaticDefinitionsForCard,
      getDefinitionsForCard,

      $reset,
    }
  },
)
