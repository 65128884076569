import type { AnswerMask } from '@attest/survey'

export type Option<I extends string = string> = TextOption<I> | ImageOption<I>

type OptionBase<I extends string = string> = {
  readonly id: I
  readonly text: string
  readonly isQualifying: boolean
  readonly isRandomized: boolean
  readonly answerIds: readonly string[]
  readonly next?: string
  readonly mask?: AnswerMask
  readonly minTarget?: number
  readonly isPinned?: boolean
}

export type TextOption<I extends string = string> = {} & OptionBase<I>

export type ImageOption<I extends string = string> = {
  readonly imageUrl: string
  readonly filename: string | undefined
} & OptionBase<I>

export enum STATIC_OPTION_ID {
  NA = 'na',
  NONE = 'none',
  OTHER = 'other',
}

export function isImageOption(option: Option): option is ImageOption {
  return 'imageUrl' in option
}

export function isQualifyingOption(option: Option): boolean {
  return option.isQualifying
}

export function isRandomizedOption(option: Option): boolean {
  return option.isRandomized
}

export function isStaticOption(option: Option): boolean {
  return isStaticOptionId(option.id)
}

export function isStaticOptionId(id: string): boolean {
  return Object.values(STATIC_OPTION_ID).includes(id as STATIC_OPTION_ID)
}

export function isNAOption(option: Option): boolean {
  return option.id === STATIC_OPTION_ID.NA
}

export function isNoneOption(option: Option): boolean {
  return option.id === STATIC_OPTION_ID.NONE
}

export function isOtherOption(option: Option): boolean {
  return option.id === STATIC_OPTION_ID.OTHER
}

export function isNonStaticOption(option: Option): boolean {
  return !isStaticOption(option)
}
