import type { RouteRecordRaw } from 'vue-router'

import { ERROR_MESSAGES } from '@attest/_constant'
import {
  createAdminRoute,
  createAdminStudyRoute,
  createAdminSurveysRoute,
} from '@attest/admin-dashboard'
import { createSurveyDrafterRoute } from '@attest/editor-survey-drafter'
import { createStudyReportsRoute } from '@attest/results-reports'
import { ROUTES } from '@attest/router'
import { createStudyManagementRoute } from '@attest/study-management'
import { createResultsTextAnalysisWaveSurveyRoute } from '@attest/text-analysis'
import { createTrendsByAttestRouteRecord } from '@attest/trends-by-attest'

import { createBookADemoRoute } from '../../module/book-a-demo/route'
import { createStudyInsightsRoute } from '../../module/study-insights/route'
import { createStudyUpdaterRoute } from '../../module/study-updater/route'
import { createStudyRoute, getStudyProps } from '../../module/study/route'
import { createSurveyLiveRoute } from '../../module/survey-live/route'
import { createTemplatePreviewRoute } from '../../module/template-preview/route'
import { createTemplateListingRoute } from '../../module/template/route'

import { routesLoggedIn } from './logged-in'
import { routesLoggedOut } from './logged-out'
import { routesPublic } from './public'
import { routesRedirect } from './redirect'

declare module 'vue-router' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface RouteMeta {
    authenticationRequired: 'authenticated' | 'unauthenticated' | 'either'
  }
}

const routeMetaError = {
  showNavbarTopbar: true,
  showProgressBar: true,
  authenticationRequired: 'either',
} as const

export const routes: readonly RouteRecordRaw[] = [
  {
    ...createStudyRoute(),
    children: [
      createStudyInsightsRoute(getStudyProps),
      createStudyUpdaterRoute(getStudyProps),
      createStudyManagementRoute(getStudyProps),
      createStudyReportsRoute(getStudyProps),
    ],
  },

  createAdminRoute(),
  createAdminSurveysRoute(),
  createAdminStudyRoute(),
  createTrendsByAttestRouteRecord(),

  createResultsTextAnalysisWaveSurveyRoute(),

  createTemplateListingRoute(),
  createTemplatePreviewRoute(),
  createSurveyLiveRoute(),
  createBookADemoRoute(),

  ...routesRedirect,
  ...routesPublic,
  ...routesLoggedOut,
  ...routesLoggedIn,

  createSurveyDrafterRoute(),
  {
    path: ROUTES.ERROR.path,
    name: ROUTES.ERROR.name,
    meta: routeMetaError,
    props() {
      return { status: 404, message: ERROR_MESSAGES.NOT_FOUND }
    },
    component: () => import('@attest/_components/src/common/Error.vue'),
  },
]
