export function useStorageSystem(): Storage | undefined {
  if (isStorageAvailable(localStorage)) {
    return localStorage
  }
  if (isStorageAvailable(sessionStorage)) {
    return sessionStorage
  }

  return undefined
}

function isStorageAvailable(storage: Storage): boolean {
  const test = '__test_availablity__'
  try {
    storage.setItem(test, test)
    storage.removeItem(test)
    return true
  } catch {
    return false
  }
}
