import type { RouteMeta } from 'vue-router'

import { CONFIG } from '@attest/config'
import type { FeatureSwitchKey } from '@attest/feature-switch'
import type { PermissionType } from '@attest/user-permission'

export type RouteMetaGlobal = {
  authenticate?: boolean
  showNavbarTopbar?: boolean
  showProgressBar?: boolean
  featureSwitchKey?: FeatureSwitchKey
  isAccessibleWhenAuthenticated?: boolean
  permissionRequired?: PermissionType[]
} & RouteMeta

export const EXTERNAL_PATH = {
  INTERCOM_HELP: '//intercom.help/attest',
  INTERCOM_HELP_TRACKER_FAQ:
    'https://intercom.help/attest/en/articles/5099006-beta-using-the-trackers-and-trends-pages',
  INTERCOM_HELP_STATISTICAL_SIGNIFICANCE:
    'https://intercom.help/attest/en/articles/4122984-beta-statistical-significance-calculator',
  INTERCOM_HELP_MULTI_MARKET:
    'https://intercom.help/attest/en/articles/5717738-how-to-send-one-survey-to-multiple-markets',
  INTERCOM_HELP_TEXT_ANALYSIS:
    'https://intercom.help/attest/en/articles/1273676-analysing-open-text-answers',
  TERMS: `${CONFIG.APP.LANDING_URL}/terms`,
  POLICY: `${CONFIG.APP.LANDING_URL}/privacy-policy`,
  BOOK_AN_INTRO: `${CONFIG.APP.LANDING_URL}/get-started?book-an-intro`,
  PRICING: `${CONFIG.APP.LANDING_URL}/pricing`,
  NPS_INFO: 'https://www.askattest.com/blog/articles/marketers-guide-to-net-promoter-score',
  ACADEMY: 'https://www.askattest.com/academy',
  GET_IN_TOUCH: 'https://www.askattest.com/get-in-touch-with-attest',
} as const

export const isAdviceKey = (
  advice: string,
): advice is keyof typeof EXTERNAL_HELP_ARTICLE.ADVICE_HELP =>
  advice in EXTERNAL_HELP_ARTICLE.ADVICE_HELP
export const EXTERNAL_HELP_ARTICLE = {
  ADVICE_HELP: {
    AGREEMENT_BIAS: `${EXTERNAL_PATH.INTERCOM_HELP}/en/articles/4500912-avoiding-bias-in-your-survey`,
    PII: `${EXTERNAL_PATH.INTERCOM_HELP}/en/articles/3191121-can-i-ask-respondents-for-personal-information`,
    OVER_QUALIFYING: `${EXTERNAL_PATH.INTERCOM_HELP}/en/articles/1273625-setting-up-qualifying-questions`,
  },
  AUTOMATED_INSIGHTS: `${EXTERNAL_PATH.INTERCOM_HELP}/en/articles/4657964-beta-what-automated-insights-are-available-for-my-survey`,
  CREDIT_ACTIVITY: `${EXTERNAL_PATH.INTERCOM_HELP}/en/articles/3087089-checking-your-attest-usage-stats`,
  MONTHLY_PLAN_FAQ: `${EXTERNAL_PATH.INTERCOM_HELP}/en/articles/4311868-monthly-plan-frequently-asked-questions`,
  BRAND_TRACKER_GETTING_STARTED: `${EXTERNAL_PATH.INTERCOM_HELP}/en/articles/3277276-getting-started-with-brand-tracking`,
  ANSWER_QUOTAS_LEARN_MORE: `${EXTERNAL_PATH.INTERCOM_HELP}/en/articles/5441437-managing-answer-quotas-on-qualifying-questions`,
  REMOVE_QUOTAS_LEARN_MORE: `${EXTERNAL_PATH.INTERCOM_HELP}/en/articles/5553368-removing-demographic-quotas-from-your-live-survey`,
  SAMPLE_SIZE_CALC_LEARN_MORE: `${EXTERNAL_PATH.INTERCOM_HELP}/en/articles/5954331-the-sample-size-calculator-getting-your-sample-size-right`,
  WAVE_INFO: `${EXTERNAL_PATH.INTERCOM_HELP}/en/articles/6251308-what-is-a-wave`,
  TRENDS_STATISTICAL_SIGNIFICANCE: `${EXTERNAL_PATH.INTERCOM_HELP}/en/articles/4122984-calculating-statistical-significance`,
} as const

export const ROUTES = {
  INDEX: {
    name: 'INDEX',
    path: '/',
  },

  CREATE_FROM_TEMPLATE: { name: 'CREATE_FROM_TEMPLATE', path: '/create-from-template/:guid' },
  SURVEY_LIST: { name: 'SURVEY_LIST', path: '/surveys/:type(own|team)?' },
  SURVEY_DIRECTORY: { name: 'SURVEY_DIRECTORY', path: '/surveys-directory/:directoryPath*' },
  SURVEY_LIST_DELETED: { name: 'SURVEY_LIST_DELETED', path: '/surveys/deleted' },
  ACCOUNT_SETUP: { name: 'ACCOUNT_SETUP', path: '/welcome' },

  LOGIN: { name: 'LOGIN', path: '/login' },
  REGISTER: { name: 'REGISTER', path: '/register' },
  REGISTER_INVITE_EXPIRED: { name: 'REGISTER_INVITE_EXPIRED', path: '/register/invite-expired' },
  LOGIN_SSO: { name: 'LOGIN_SSO', path: '/login/sso' },
  REGISTER_SSO: { name: 'REGISTER_SSO', path: '/register/sso' },
  FORGOT_PASSWORD: { name: 'FORGOT_PASSWORD', path: '/forgot-password' },
  RESET_PASSWORD: { name: 'RESET_PASSWORD', path: '/:pathMatch(reset-password|set-new-password)' },

  PROFILE: { name: 'PROFILE', path: '/profile' },
  SURVEY: { name: 'SURVEY', path: '/survey/:guid/:name?' },
  VALIDATE_EMAIL: { name: 'VALIDATE_EMAIL', path: '/validate-email' },

  PEOPLE: { name: 'PEOPLE', path: '/people' },
  CREDITS: { name: 'CREDITS', path: '/credits/:tab(overview|transactions)?' },

  ERROR: { name: 'ERROR', path: '/:pathMatch(.*)' },

  PLANS: { name: 'PLANS', path: '/plans' },

  IMPERSONATE: { name: 'IMPERSONATE', path: '/impersonate' },
} as const

export const VIEWS = {
  NAVBAR: 'navbar',
  STUDY_NAVBAR: 'study-navbar',
} as const
