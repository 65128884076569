import type { ApiAnswerQuota } from '@attest/_api/model/answer-quotas'
import type { AnswerMask } from '@attest/survey'

import type { StudyQuestionCard, StudyQuestionCardMedia } from '../../card/model'
import type { StudyQuestionType } from '../../card/question/model'
import {
  createStudyQuestionStructure,
  createStudyQuestionStructureOptionSettings,
} from '../../card/question/structure/factory'
import {
  DISPLAY_LOGIC_ANSWER_OPERATOR,
  type DisplayLogic,
  type StudyQuestionOptionResponseType,
  type StudyQuestionStructure,
} from '../../card/question/structure/model'
import {
  createImageOption,
  createNAOption,
  createNoneOption,
  createOtherOption,
  createTextOption,
} from '../../card/question/structure/option/factory'
import type { Option } from '../../card/question/structure/option/model'
import type { ApiStudyWave } from '../wave'

import type {
  ApiDisplayLogic,
  ApiStudyQuestionCard,
  ApiStudyQuestionCardStructure,
  ApiStudyQuestionCardStructureAnswer,
} from './model'

export function createStudyQuestionCard(
  apiCard: ApiStudyQuestionCard,
  apiWaves: ApiStudyWave[],
): StudyQuestionCard {
  return {
    id: apiCard.guid,
    type: 'question',
    title: apiCard.title,
    publishedTimestamp: null,
    deletedTimestamp: null,
    media: createStudyQuestionMedia(apiCard.title_content),
    question: {
      id: apiCard.question_guid,
      type: createStudyQuestionCardType(apiCard),
      optionGroups: apiCard.groups.map(apiGroup => ({
        text: apiGroup.name,
        ids: apiGroup.choices.map(({ id }) => id),
      })),
      structures: Object.entries(apiCard.waves).map(([waveId, apiStructure]) =>
        createStudyQuestionCardStructure({
          apiWave: apiWaves.find(apiWave => apiWave.id === waveId),
          apiStructure,
          optionResponseType: createStudyOptionResponseType(apiCard.card_type),
        }),
      ),
    },
    templateAnswers: apiCard.template_answers,
  }
}

function createStudyQuestionCardType(apiCard: ApiStudyQuestionCard): StudyQuestionType {
  switch (apiCard.card_type) {
    case 'text_response_short':
    case 'text_response_medium':
    case 'text_response_long':
      return 'open-text'
    case 'single_choice':
    case 'multiple_choice':
    case 'either_or':
      return 'choice'
    default:
      return apiCard.card_type
  }
}

function createStudyOptionResponseType(
  apiType: ApiStudyQuestionCard['card_type'],
): StudyQuestionOptionResponseType {
  switch (apiType) {
    case 'text_response_short':
      return 'short'
    case 'text_response_medium':
      return 'medium'
    case 'text_response_long':
      return 'long'
    case 'either_or':
    case 'nps':
    case 'single_choice':
      return 'single'
    case 'multiple_choice':
      return 'multiple'
    case 'ranked':
      return 'order'
    case 'video':
      return 'video'
    default:
      throw new Error('card type not supported')
  }
}

export function createStudyQuestionCardStructure({
  apiWave,
  apiStructure,
  optionResponseType,
}: {
  apiWave: ApiStudyWave | undefined
  apiStructure: ApiStudyQuestionCardStructure
  optionResponseType: StudyQuestionOptionResponseType
}): StudyQuestionStructure {
  if (apiWave === undefined) throw new Error('API wave not found')
  return createStudyQuestionStructure({
    title: apiStructure.title,
    waveId: apiWave.id,
    publishedTimestamp: apiWave.published ?? undefined,
    next: apiStructure.next ?? undefined,
    options: [
      ...apiStructure.answers.map((apiAnswer, index) => {
        return createOption(
          apiAnswer,
          apiStructure.randomized,
          getAnswerQuotaMinTarget(apiStructure.answer_quotas ?? [], index),
        )
      }),
      ...(apiStructure.na ? [createNAOption()] : []),
      ...(apiStructure.none ? [createNoneOption()] : []),
      ...(apiStructure.other ? [createOtherOption()] : []),
    ],
    optionSettings: createStudyQuestionStructureOptionSettings({
      type: optionResponseType,
      limit: apiStructure.max_selections ?? undefined,
    }),
    displayLogic: transformApiDisplayLogicToModel(apiStructure.display_logic),
    mediaUrl:
      apiStructure.title_content?.image_url ??
      apiStructure.title_content?.audio_url ??
      apiStructure.title_content?.video_url,
    omitted: apiStructure.omitted,
  })
}

export function transformApiDisplayLogicToModel(
  apiDisplayLogic?: ApiDisplayLogic,
): DisplayLogic | undefined {
  if (!apiDisplayLogic) return
  return {
    operator: apiDisplayLogic.operator === 'and' ? 'AND' : 'OR',
    conditions: apiDisplayLogic.conditions.map(condition => {
      return {
        choiceIds: condition.choice_ids,
        condition:
          condition.condition === 'none_selected'
            ? DISPLAY_LOGIC_ANSWER_OPERATOR.NONE_SELECTED
            : condition.condition === 'any_selected'
              ? DISPLAY_LOGIC_ANSWER_OPERATOR.ANY_SELECTED
              : DISPLAY_LOGIC_ANSWER_OPERATOR.ALL_SELECTED,
        studyCardGuid: condition.study_card_guid,
      }
    }),
  }
}

export function createOption(
  apiAnswer: ApiStudyQuestionCardStructureAnswer,
  isRandomized: boolean,
  minTarget?: number,
): Option {
  const mask: AnswerMask | undefined = apiAnswer.mask
    ? {
        answerId: apiAnswer.mask?.choice_id,
        cardId: apiAnswer.mask.card_id,
      }
    : undefined

  return apiAnswer.image_url
    ? createImageOption({
        id: apiAnswer.id,
        answerIds: [apiAnswer.id],
        text: apiAnswer.text,
        imageUrl: apiAnswer.image_url,
        filename: apiAnswer.filename ?? undefined,
        isQualifying: apiAnswer.qualifying,
        isRandomized: isRandomized && !apiAnswer.pinned,
        isPinned: apiAnswer.pinned,
        next: apiAnswer.next ?? undefined,
        mask,
        minTarget,
      })
    : createTextOption({
        id: apiAnswer.id,
        answerIds: [apiAnswer.id],
        text: apiAnswer.text,
        isQualifying: apiAnswer.qualifying,
        isRandomized: isRandomized && !apiAnswer.pinned,
        isPinned: apiAnswer.pinned,
        next: apiAnswer.next ?? undefined,
        mask,
        minTarget,
      })
}

export function getAnswerQuotaMinTarget(
  apiAnswerQuotas: ApiAnswerQuota[],
  answerIndex: number,
): number | undefined {
  return apiAnswerQuotas.find(answerQuota => answerQuota.answer_indexes.includes(answerIndex))
    ?.min_target
}

export function createStudyQuestionMedia(
  titleContent?: ApiStudyQuestionCard['title_content'],
): StudyQuestionCardMedia | null {
  if (!titleContent) return null
  if (titleContent.image_url) return { type: 'image', url: titleContent.image_url ?? '' }
  if (titleContent.audio_url) return { type: 'audio', url: titleContent.audio_url ?? '' }
  if (titleContent.video_url) return { type: 'video', url: titleContent.video_url ?? '' }
  return null
}
