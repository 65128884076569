import { useResultsRoundsStore } from '@attest/results-core'

import type { ColumnDefinitionValue } from '../column-definition'
import type { Row, RowChild } from '../row'

import { createDefinitionValueFilterNode } from './factory/column'
import { useMemoizeRounds } from './memoization'

export const useCardColumnRounds = useMemoizeRounds(
  (payload: { cardId: string; column: ColumnDefinitionValue }) => {
    return useResultsRoundsStore()
      .card({ id: payload.cardId })
      .filtered.filter(createDefinitionValueFilterNode(payload)).value
  },
)

export const useCardColumnWithBaseRounds = useMemoizeRounds(
  (payload: { cardId: string; column: ColumnDefinitionValue; row: Row; rowChild: RowChild }) => {
    return useResultsRoundsStore()
      .cardWithBase({
        id: payload.cardId,
        subjectIds: payload.row.id.split(','),
        optionIds: payload.rowChild.id.split(','),
      })
      .filtered.filter(createDefinitionValueFilterNode(payload)).value
  },
)
