import { sum } from '@attest/util'

import type { Quota } from './model'

export function areQuotasValid(quotas: Quota[]): boolean {
  const uniqueDemographics = [
    ...new Set(quotas.flatMap(({ segmentations }) => Object.keys(segmentations))),
  ]
  return uniqueDemographics
    .map(demographic => quotas.filter(({ segmentations }) => demographic in segmentations))
    .every(
      quotasWithDemographic => sum(quotasWithDemographic.map(({ limit }) => limit * 1000)) === 1000,
    )
}
