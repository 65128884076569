import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

import { clientInitialized, getVariation } from '@attest/feature-switch'
import { EXTERNAL_PATH, type RouteMetaGlobal, ROUTES } from '@attest/router'

const createLoggedInMeta = (
  meta: Omit<RouteMetaGlobal, 'authenticationRequired'>,
): RouteMetaGlobal =>
  ({
    ...meta,
    authenticationRequired: 'authenticated',
  }) as const

const plansPathRedirect = (_to: RouteLocationNormalized, from: RouteLocationNormalized): false => {
  if (from.name === undefined) {
    window.location.replace(EXTERNAL_PATH.PRICING)
    return false
  }
  window.open(EXTERNAL_PATH.PRICING, '_blank')
  return false
}

const routes: readonly RouteRecordRaw[] = [
  {
    name: ROUTES.ACCOUNT_SETUP.name,
    path: ROUTES.ACCOUNT_SETUP.path,
    meta: createLoggedInMeta({ showNavbarTopbar: false, showProgressBar: true }),
    props: (route): { redirect?: string } => {
      const redirect = Array.isArray(route.query.redirect)
        ? route.query.redirect[0]
        : route.query.redirect

      return {
        redirect: redirect ?? undefined,
      }
    },
    component: () => import('../../module/account-setup/page/AccountSetup/AccountSetup.vue'),
  },
  {
    name: ROUTES.PROFILE.name,
    path: ROUTES.PROFILE.path,
    meta: createLoggedInMeta({
      showNavbarTopbar: true,
      showProgressBar: true,
    }),
    component: () => import('../../container/Profile.vue'),
  },
  {
    name: ROUTES.SURVEY_LIST.name,
    path: ROUTES.SURVEY_LIST.path,
    meta: createLoggedInMeta({ showNavbarTopbar: true, showProgressBar: true }),
    component: async () => (await import('@attest/survey-dashboard')).SurveysDynamic,
  },
  {
    name: ROUTES.SURVEY_DIRECTORY.name,
    path: ROUTES.SURVEY_DIRECTORY.path,
    meta: createLoggedInMeta({
      showNavbarTopbar: true,
      showProgressBar: true,
      featureSwitchKey: 'survey-folders',
    }),
    component: async () => (await import('@attest/survey-dashboard')).SurveysDirectory,
  },
  {
    name: ROUTES.SURVEY_LIST_DELETED.name,
    path: ROUTES.SURVEY_LIST_DELETED.path,
    meta: createLoggedInMeta({ showNavbarTopbar: true, showProgressBar: true }),
    component: async () =>
      await import('@attest/survey-dashboard/src/page/SurveyListDeleted/SurveyListDeleted.vue'),
  },
  {
    name: ROUTES.CREATE_FROM_TEMPLATE.name,
    path: ROUTES.CREATE_FROM_TEMPLATE.path,
    meta: createLoggedInMeta({ showNavbarTopbar: true, showProgressBar: true }),
    component: () => import('../../container/logged-in/CreateFromTemplate.vue'),
  },
  {
    name: ROUTES.INDEX.name,
    path: ROUTES.INDEX.path,
    meta: createLoggedInMeta({ showProgressBar: true }),
    redirect: () => {
      if (clientInitialized() && getVariation('survey-folders')) {
        return { name: ROUTES.SURVEY_DIRECTORY.name }
      }
      return { name: ROUTES.SURVEY_LIST.name }
    },
  },
  {
    name: ROUTES.PEOPLE.name,
    path: ROUTES.PEOPLE.path,
    meta: createLoggedInMeta({
      showNavbarTopbar: true,
      showProgressBar: true,
    }),
    component: () => import('../../module/team/page/Team/Team.vue'),
  },
  {
    name: ROUTES.CREDITS.name,
    path: ROUTES.CREDITS.path,
    meta: {
      ...createLoggedInMeta({ showNavbarTopbar: true, showProgressBar: true }),
    },
    component: () => import('../../module/credit/page/Credits/Credits.vue'),
  },
  {
    name: ROUTES.PLANS.name,
    path: ROUTES.PLANS.path,
    meta: {
      ...createLoggedInMeta({ showNavbarTopbar: true, showProgressBar: true }),
    },
    beforeEnter: plansPathRedirect,
    component: () => {},
  },
  {
    name: ROUTES.IMPERSONATE.name,
    path: ROUTES.IMPERSONATE.path,
    meta: createLoggedInMeta({
      showNavbarTopbar: true,
      showProgressBar: true,
      permissionRequired: ['IMPERSONATE'],
    }),
    component: () => import('../../module/impersonate/page/Impersonate.vue'),
  },
]

export const routesLoggedIn = routes
