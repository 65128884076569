import { type ColumnDefinitionValue, isStackedColumnDefinitionValue } from './column-definition'
import type { Row, RowChild } from './row'

export function getSigTestKey({
  row,
  rowChild,
  column,
}: {
  row: Row
  rowChild: RowChild
  column: ColumnDefinitionValue
}): string {
  return `${row.id}${rowChild.id}${getColumnCacheKey(column)}`
}

export function getColumnCacheKey(column: ColumnDefinitionValue | undefined): string {
  if (column === undefined) {
    return ''
  }

  const base = `${column.type}${column.variable}${column.value}`
  return !isStackedColumnDefinitionValue(column)
    ? base
    : `${base}${getColumnCacheKey(column.stackedColumnDefinitionValue)}`
}
