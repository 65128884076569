import { isRef, reactive, type Reactive, ref, type Ref, toRaw } from 'vue'

import { clone } from './clone'

export function toRawDeep<T>(observed: T): T {
  const val = toRaw(observed)

  if (Array.isArray(val)) {
    return val.map(toRawDeep) as T
  }

  if (val instanceof Set) {
    return new Set([...val].map(toRawDeep)) as T
  }

  if (val instanceof Map) {
    return new Map([...val].map(([key, value]) => [key, toRawDeep(value)])) as T
  }

  if (val === null) return null as T

  if (typeof val === 'object') {
    const entries = Object.entries(val).map(([key, val]) => [key, toRawDeep(val)])

    return Object.fromEntries(entries)
  }

  return val
}

export function cloneRef<T extends Ref>(original: T): T {
  return ref(clone(toRawDeep(original.value))) as T
}

export function cloneReactive<T extends Reactive<unknown>>(original: T): T {
  return reactive(clone(toRawDeep(original))) as T
}

export function cloneRaw<T>(original: Ref<T> | Reactive<T> | T): T {
  if (isRef(original)) {
    return clone(toRawDeep(original.value)) as T
  }
  return clone(toRawDeep(original)) as T
}
