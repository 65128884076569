import type { RouteLocation } from 'vue-router'

import { STUDY_INSIGHTS_ROUTE_NAME } from '@attest/router'

export enum STUDY_INSIGHTS_SAVED_SETTINGS_VIEW_KEYS {
  TRENDS = 'trends_and_analysis',
  ANALYSIS = 'analysis',
}

export function isOverview($route: RouteLocation): boolean {
  return $route.name === STUDY_INSIGHTS_ROUTE_NAME.OVERVIEW
}

export function isCrosstabs($route: RouteLocation): boolean {
  return $route.name === STUDY_INSIGHTS_ROUTE_NAME.CROSSTABS
}

export function isTrends($route: RouteLocation): boolean {
  return $route.name === STUDY_INSIGHTS_ROUTE_NAME.TRENDS
}

export function isAnalysisByGender($route: RouteLocation): boolean {
  return $route.name === STUDY_INSIGHTS_ROUTE_NAME.ANALYSIS && $route.params.groupBy === 'by-gender'
}

export function isAnalysisByAge($route: RouteLocation): boolean {
  return $route.name === STUDY_INSIGHTS_ROUTE_NAME.ANALYSIS && $route.params.groupBy === 'by-age'
}

export function isAnalysisByCountry($route: RouteLocation): boolean {
  return (
    $route.name === STUDY_INSIGHTS_ROUTE_NAME.ANALYSIS && $route.params.groupBy === 'by-country'
  )
}
