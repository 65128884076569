import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import {
  createCardResponseFilter,
  useCardResponseFiltersStore,
  useResultsDemographicFiltersStore,
  useSegmentFiltersStore,
} from '@attest/results-saved-filters'
import {
  assertIsMergedStudyMessageStructure,
  assertIsMergedStudyQuestionStructure,
  assertIsStudyQuestionCard,
  createMergedMessageStructure,
  createMergedStudyQuestionStructure,
  isGridTemplateAnswers,
  isMergedStudyQuestionStructure,
  isQualifyingOption,
  isStudyGridQuestionCard,
  isStudyQuestionCard,
  type MergedStudyMessageStructure,
  type MergedStudyQuestionStructure,
  type SentWaveSurvey,
  type StudyCard,
  type StudyQuestionCard,
  useStudyStore,
  type WaveSurvey,
} from '@attest/study'
import { intersection, keyBy, sum } from '@attest/util'

import { createResultCardFromStudyCard } from '../factory'
import type { StudyResultsCard } from '../models'
import { isCrosstabs } from '../service'

import { useStudyInsightsUIStore } from './study-insights-ui'
import { useStudyResultsStore } from './study-results'
import { useStudyWaveFiltersStore } from './wave-filters'

const STUDY_INSIGHTS_NAMESPACE = 'studyInsights'

export const useStudyInsightsStore = defineStore(STUDY_INSIGHTS_NAMESPACE, {
  getters: {
    waveSurveys(): WaveSurvey[] {
      return useStudyStore().waveSurveys
    },

    includedWaveSurveyIds(): Set<string> {
      return new Set(useStudyWaveFiltersStore().filteredSentWaveSurveys.map(wave => wave.id))
    },

    idToCard(): Record<string, StudyCard> {
      const waveSurveyIds = new Set(this.waveSurveys.map(({ id }) => id))

      const shouldShowStudyInDraftLanguage =
        useStudyInsightsUIStore().studyDisplayLanguage === 'DRAFT'

      return Object.freeze(
        keyBy(
          useStudyStore().cards.map(card =>
            isStudyQuestionCard(card)
              ? {
                  ...card,
                  title: shouldShowStudyInDraftLanguage
                    ? card.title
                    : (card.question.structures
                        .filter(structure => this.includedWaveSurveyIds.has(structure.waveId))
                        .at(-1)?.title ?? card.title),
                  question: {
                    ...card.question,
                    structures: card.question.structures.filter(structure =>
                      waveSurveyIds.has(structure.waveId),
                    ),
                  },
                }
              : {
                  ...card,
                  text: shouldShowStudyInDraftLanguage
                    ? card.text
                    : (card.structures
                        .filter(structure => this.includedWaveSurveyIds.has(structure.waveId))
                        .at(-1)?.text ?? card.text),
                  structures: card.structures.filter(structure =>
                    waveSurveyIds.has(structure.waveId),
                  ),
                },
          ),

          ({ id }) => id,
        ),
      )
    },

    cardIdToMergedStructure(): Record<
      string,
      MergedStudyQuestionStructure | MergedStudyMessageStructure
    > {
      const shouldShowStudyInDraftLanguage =
        useStudyInsightsUIStore().studyDisplayLanguage === 'DRAFT'
      return Object.fromEntries(
        Object.values(this.idToCard).map(card => {
          return [
            card.id,
            isStudyQuestionCard(card)
              ? createMergedStudyQuestionStructure(
                  card.question.structures
                    .filter(structure => this.includedWaveSurveyIds.has(structure.waveId))
                    .map(structure => {
                      if (shouldShowStudyInDraftLanguage) {
                        const templateAnswers = card.templateAnswers
                        if (isGridTemplateAnswers(templateAnswers)) {
                          return {
                            ...structure,
                            subjects: structure.subjects.map(subject => ({
                              ...subject,
                              text:
                                templateAnswers.subjects?.headers?.find(
                                  header => header?.id === subject.id,
                                )?.text ?? subject.text,
                            })),
                            options: structure.options.map(option => ({
                              ...option,
                              text:
                                templateAnswers.options?.headers?.find(
                                  header => header?.id === option.id,
                                )?.text ?? option.text,
                            })),
                          }
                        }
                        return {
                          ...structure,
                          options: structure.options.map(option => ({
                            ...option,
                            text:
                              templateAnswers?.find(answer => answer.id === option.id)?.text ??
                              option.text,
                          })),
                        }
                      }
                      return structure
                    }),
                )
              : createMergedMessageStructure(
                  card.structures.filter(structure =>
                    this.includedWaveSurveyIds.has(structure.waveId),
                  ),
                ),
          ]
        }),
      )
    },

    cardIdToResultsCard(): Record<string, StudyResultsCard> {
      return Object.freeze(
        keyBy(
          Object.values(this.idToCard)
            .filter(isStudyQuestionCard)
            .map(card =>
              createResultCardFromStudyCard(
                card,
                this.getMergedQuestionStructureByCardId(card.id),
                useStudyResultsStore().cardIdToCard[card.id],
              ),
            ),

          ({ id }) => id,
        ),
      )
    },

    hasQualifyingCards(): boolean {
      return Object.values(this.cardIdToMergedStructure)
        .filter(isMergedStudyQuestionStructure)
        .some(structure => structure.options.some(isQualifyingOption))
    },

    answerFilterCards(): StudyCard[] {
      const cardResponseFiltersStore = useCardResponseFiltersStore()
      return Object.values(this.idToCard).filter(card => {
        const { answerIds, responseIds } =
          cardResponseFiltersStore.cardIdToFilters[card.id] ?? createCardResponseFilter()
        return sum([answerIds.length, responseIds.length]) > 0
      })
    },

    shouldUsePanelFilters(): boolean {
      if (useStudyInsightsRouteStore().isCrosstabs) {
        return false
      }

      const cardResponseFiltersStore = useCardResponseFiltersStore()
      const demographicFilterGetters = useResultsDemographicFiltersStore()
      const segmentFiltersStore = useSegmentFiltersStore()

      return (
        cardResponseFiltersStore.shouldFilter ||
        demographicFilterGetters.shouldFilter ||
        segmentFiltersStore.dynamicSegmentFilters?.length > 0
      )
    },

    filteredSentWaveSurveys(): SentWaveSurvey[] {
      return useStudyWaveFiltersStore().filteredSentWaveSurveys
    },

    loadedFilteredWaves(): SentWaveSurvey[] {
      const studyResultsStore = useStudyResultsStore()
      return this.filteredSentWaveSurveys.filter(wave =>
        studyResultsStore.loadedWaves.includes(wave.id),
      )
    },

    filteredCards(): StudyCard[] {
      return Object.values(this.idToCard).filter(card =>
        this.filteredSentWaveSurveys.some(wave =>
          isStudyQuestionCard(card)
            ? card.question.structures.map(({ waveId }) => waveId).includes(wave.id)
            : card.structures.map(({ waveId }) => waveId).includes(wave.id),
        ),
      )
    },

    filteredCardIdsContainingAnswerVariations() {
      const filteredQuestionCards = this.filteredCards.filter(card =>
        isStudyQuestionCard(card),
      ) as StudyQuestionCard[]

      return new Set(
        filteredQuestionCards
          .filter(questionCard => {
            const filteredStructures = this.filteredSentWaveSurveys
              .filter(ws => questionCard.question.structures.find(s => s.waveId === ws.id))
              .map(ws => questionCard.question.structures.find(s => s.waveId === ws.id))

            for (const filteredStructure of filteredStructures) {
              for (const filteredStructureToCompareTo of filteredStructures) {
                if (isStudyGridQuestionCard(questionCard)) {
                  if (
                    intersection([
                      new Set(filteredStructure?.subjects.map(s => s.id)),
                      new Set(filteredStructureToCompareTo?.subjects.map(s => s.id)),
                    ]).size !== filteredStructure?.subjects.length
                  ) {
                    return true
                  } else if (
                    intersection([
                      new Set(filteredStructure?.options.map(o => o.id)),
                      new Set(filteredStructureToCompareTo?.options.map(o => o.id)),
                    ]).size !== filteredStructure?.options.length
                  ) {
                    return true
                  }
                }
                if (
                  isStudyQuestionCard(questionCard) &&
                  intersection([
                    new Set(filteredStructure?.options.map(o => o.id)),
                    new Set(filteredStructureToCompareTo?.options.map(o => o.id)),
                  ]).size !== filteredStructure?.options.length
                ) {
                  return true
                }
              }
            }
            return false
          })
          .map(card => card.id),
      )
    },
    getSentWaveSurveysForAnswer(): (answerId: string) => SentWaveSurvey[] {
      return (answerId: string) => {
        const filteredQuestionCard = this.filteredCards.find(
          card =>
            isStudyQuestionCard(card) &&
            card.question.optionGroups.find(og => og.ids.includes(answerId)),
        ) as StudyQuestionCard
        if (!filteredQuestionCard) {
          return []
        }

        const updatedAnswerAndCard = this.getMaskedCardAndAnswerId(answerId, filteredQuestionCard)

        return this.filteredSentWaveSurveys.filter(sentWaveSurvey => {
          const filteredQuestionStructure =
            updatedAnswerAndCard.questionCard.question.structures.find(
              s => s.waveId === sentWaveSurvey.id,
            )
          return !!filteredQuestionStructure?.options.some(option =>
            option.answerIds.includes(updatedAnswerAndCard.answerId),
          )
        })
      }
    },
    getSentWaveSurveysForSubject(): (subjectId: string, cardId: string) => SentWaveSurvey[] {
      return (subjectId: string, cardId: string) => {
        let filteredQuestionCard = this.filteredCards.find(
          card => isStudyGridQuestionCard(card) && card.id === cardId,
        ) as StudyQuestionCard
        if (!filteredQuestionCard) {
          return []
        }

        const updatedAnswerAndCard = this.getMaskedCardAndAnswerId(subjectId, filteredQuestionCard)
        subjectId = updatedAnswerAndCard.answerId
        filteredQuestionCard = updatedAnswerAndCard.questionCard

        return this.filteredSentWaveSurveys.filter(sentWaveSurvey => {
          const filteredQuestionStructure = filteredQuestionCard.question.structures.find(
            s => s.waveId === sentWaveSurvey.id,
          )
          const result = !!filteredQuestionStructure?.subjects.some(
            subject => subject.id === subjectId,
          )
          return result
        })
      }
    },

    getMaskedCardAndAnswerId(): (
      answerId: string,
      questionCard: StudyQuestionCard,
    ) => { answerId: string; questionCard: StudyQuestionCard } {
      return (answerId: string, questionCard: StudyQuestionCard) => {
        const answerMasks = questionCard.question.structures
          .map(
            struct =>
              struct.options.find(opt => opt.answerIds.includes(answerId))?.mask ||
              struct.subjects.find(subj => subj.id === answerId)?.mask,
          )
          .filter(mask => !!mask)
        if (answerMasks && answerMasks[0]) {
          const mask = answerMasks[0]
          answerId = mask.answerId
          questionCard = this.filteredCards.find(
            card =>
              (isStudyQuestionCard(card) &&
                card.question.optionGroups.find(og => og.ids.includes(mask.answerId))) ||
              (isStudyGridQuestionCard(card) &&
                card.question.structures.find(
                  structure =>
                    structure.options.some(opt => opt.answerIds.includes(mask.answerId)) ||
                    structure.subjects.some(subject => subject.id === mask.answerId),
                )),
          ) as StudyQuestionCard
        }
        return {
          answerId,
          questionCard,
        }
      }
    },
    getMergedQuestionStructureByCardId(): (cardId: string) => MergedStudyQuestionStructure {
      return cardId => {
        const structure = this.cardIdToMergedStructure[cardId]
        assertIsMergedStudyQuestionStructure(structure)
        return structure
      }
    },

    getMergedMessageStructureByCardId(): (cardId: string) => MergedStudyMessageStructure {
      return cardId => {
        const structure = this.cardIdToMergedStructure[cardId]
        assertIsMergedStudyMessageStructure(structure)
        return structure
      }
    },

    getQuestionCardById(): (cardId: string) => StudyQuestionCard {
      return cardId => {
        const card = this.idToCard[cardId]
        assertIsStudyQuestionCard(card)
        return card
      }
    },
  },
})

// as the main store is options api this is used to access useRoute composition api and react to it
const useStudyInsightsRouteStore = defineStore(`${STUDY_INSIGHTS_NAMESPACE}::route`, () => {
  const route = useRoute()

  return {
    isCrosstabs: computed(() => isCrosstabs(route)),
  }
})
