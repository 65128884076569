import type { SurveyViewership } from '@attest/survey'

import type { StudyCardId } from '../card'
import type { StudyRecurrence } from '../recurrence'
import { rejectDraftWaveSurveys, type WaveSurveyStatus } from '../wave'

export type Study = {
  id: string
  title: string
  internalTitle: string | null
  ownedBy: string
  createdBy: string
  cardIds: StudyCardId[]
  waveSurveyIds: string[]
  recurrence: StudyRecurrence | null
  isShared: boolean
  viewership?: SurveyViewership
  draftLanguage: string
}

export function isStudy(study: unknown): study is Study {
  if (typeof study !== 'object' || study === null) return false
  return 'id' in study && 'title' in study
}

export function assertIsStudy(study: unknown): asserts study is Study {
  if (isStudy(study)) return
  throw new TypeError('Study is not of type study')
}

export function isMultiMarketStudy(
  waveSurveys: { country: string; status: WaveSurveyStatus }[],
): boolean {
  return new Set(rejectDraftWaveSurveys(waveSurveys).map(survey => survey.country)).size > 1
}

export function isMultiLanguageStudy(
  waveSurveys: { language: string; status: WaveSurveyStatus }[],
): boolean {
  return new Set(rejectDraftWaveSurveys(waveSurveys).map(survey => survey.language)).size > 1
}

export function isLongitudinalStudy(
  waves: { publishedTimestamp: number | null; status: WaveSurveyStatus }[],
): boolean {
  return new Set(rejectDraftWaveSurveys(waves).map(survey => survey.publishedTimestamp)).size > 1
}
