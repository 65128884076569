export let DEFAULTS = {
  'study-exclusive-sample-disable': false,
  'audience-variants': false,
  'study-audience-edits-demographics-quotas': false,
  'qual-video-question': false,
  'questions-cord-comments': false,
  'csat-survey': false,
  'csat-purchase-survey': false,
  'csat-targeting-override': false,
  'share-qr-code': false,
  'register-redirect-url': '',
  'study-wave-update-cpi': false,
  'rsfe-url': '',
  'preview-use-rsfe': false,
  'multi-language-same-country': false,
  'multi-market-with-recurrence': false,
  'multi-market-scheduling': false,
  'card-localisation': false,
  'display-logic-drawer': false,
  'localisation-banner': false,
  'default-sample-size': 385,
  'sample-size-calculator-defaults': {
    confidence: 95,
    margin: 5,
    minSample: 385,
  },
  'transfer-surveys-on-user-deletion': false,
  'pending-review-videos': false,
  'localise-standard-answer-media': false,
  'localise-grid-answer-media': false,
  'localise-message-card-media': false,
  'localise-question-card-media': false,
  'login-page-marketing': false,
  'grid-v2': false,
  'edit-audiences-between-waves': false,
  'no-credits': false,
  'credits-on-checkout-only': false,
  'sso-invite': false,
  'multiple-audiences-same-locale': false,
  'named-audiences': false,
  'trends-by-attest': '',
  'open-text-segments': false,
  'admin-new-study-page': false,
  'custom-chart-themes': true,
  'results-reports': false,
  'survey-folders': false,
  'custom-colour-theme': true,
}

export async function loadDefaults(): Promise<void> {
  if (import.meta.env.PROD && import.meta.env.MODE !== 'preview') return
  try {
    const modules = await import.meta.glob('../config.json')
    DEFAULTS = { ...DEFAULTS, ...((await modules['../config.json']?.()) as any)?.default }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error)
  }
}

if (import.meta.hot) {
  import.meta.hot.accept(['../config.json'], ([config]) => {
    if (config) DEFAULTS = { ...DEFAULTS, ...config.default }
  })
}
