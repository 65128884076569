import { nanoid } from 'nanoid'

import { SETTINGS } from '@attest/editor-settings'
import { clone, cloneRaw } from '@attest/util'

import type {
  Card,
  CardGroup,
  CardPosition,
  CardQuestion,
  CardQuestionOptions,
  CardQuestionSubjects,
  CardText,
  CardType,
  CombinedCardType,
  ExclusiveCombinedSubType,
  Header,
  OptionAnswer,
} from './model'

export function createEmptyTextCard(partial?: Partial<Card>): Card {
  const card = createEmptyCard({
    ...partial,
    type: 'text',
    question: createEmptyCardQuestion({
      options: createEmptyCardQuestionOptions({
        answers: [],
      }),
    }),
  })
  return card
}

export function createEmptyQuestionCard(partial: Partial<Card> = {}): Card {
  const card = createEmptyCard({
    ...partial,
    type: 'question',
    question: createEmptyCardQuestion({ type: 'single_choice', ...partial.question }),
  })
  return card
}

export function createEmptyGroupCard(partial?: Partial<Card>): Card {
  return createEmptyCard({
    ...partial,
    type: 'group',
  })
}

export function createEmptyCard(partial?: Partial<Card>): Card {
  return {
    guid: nanoid(),
    type: null,
    position: createEmptyCardPosition(),
    title: '',
    media: null,
    text: createEmptyCardText(),
    nextGuid: null,
    question: createEmptyCardQuestion(),
    group: createEmptyCardGroup(),
    isRandomized: false,
    publishedTimestamp: undefined,
    mediaOverrides: {},
    omittedFromAudiences: new Set(),
    ...partial,
  }
}

function createEmptyVideoCardQuestion(): CardQuestion {
  return createEmptyCardQuestion({ type: 'video' })
}

export function createEmptyVideoQuestionCard(): Card {
  return createEmptyCard({ type: 'question', question: createEmptyVideoCardQuestion() })
}

export function createEmptyCardGroup(partial?: Partial<CardGroup>): CardGroup {
  return {
    cards: [],
    ...partial,
  }
}

export function createEmptyCardQuestion(partial?: Partial<CardQuestion>): CardQuestion {
  return {
    type: null,
    hasOther: false,
    hasNone: false,
    hasNa: false,
    selectionLimit: SETTINGS.DEFAULT_SELECTION_LIMIT,
    subjects: createEmptyCardQuestionSubjects(),
    options: createEmptyCardQuestionOptions(),
    ...partial,
  }
}

export function createEmptyCardText(partial: Partial<CardText> = {}): CardText {
  return {
    type: 'text',
    text: '',
    ...partial,
  }
}

export function createEmptyCardQuestionSubjects(
  partial?: Partial<CardQuestionSubjects>,
): CardQuestionSubjects {
  return {
    headers: createEmptyHeaders(),
    isRandomized: true,
    ...partial,
  }
}

export function createEmptyCardQuestionOptions(
  partial?: Partial<CardQuestionOptions>,
): CardQuestionOptions {
  return {
    answers: createEmptyAnswers(),
    isImage: false,
    isRandomized: true,
    isQualifying: false,
    selectionType: 'single',
    maskingQuestion: null,
    maxSelections: null,
    responseType: 'medium',
    imageDisplaySize: undefined,
    ...partial,
  }
}

export function createEmptyAnswers(
  numberOfAnswers: number = SETTINGS.MIN_NUM_OPTION_ANSWERS,
): OptionAnswer[] {
  return Array.from({ length: numberOfAnswers }, () => createEmptyAnswer())
}

export function createEmptyHeaders(
  numberOfAnswers: number = SETTINGS.MIN_NUM_SUBJECT_HEADERS,
): Header[] {
  return Array.from({ length: numberOfAnswers }, () => createEmptyHeader())
}

export function createEmptyAnswer(override: Partial<OptionAnswer> = {}): OptionAnswer {
  return {
    id: nanoid(3),
    text: '',
    type: 'text',
    media: null,
    mediaOverrides: {},
    nextGuid: null,
    isQualifying: false,
    isPinned: false,
    mask: null,
    quotaId: null,
    isDraft: true,
    omittedFromAudiences: new Set(),
    ...override,
  }
}

export function createEmptyHeader(override: Partial<Header> = {}): Header {
  return {
    id: nanoid(3),
    text: '',
    type: 'text',
    media: null,
    mediaOverrides: {},
    isQualifying: false,
    isPinned: false,
    mask: null,
    quotaId: null,
    isDraft: true,
    omittedFromAudiences: new Set(),
    ...override,
  }
}

export function createEmptyCardPosition(): CardPosition {
  return { x: 0, y: 0 }
}

export function createCombinedCardType<T extends CardType | null>(
  type: T = null as T,
  subType: ExclusiveCombinedSubType<T> | null = null,
): CombinedCardType<T> {
  return {
    type,
    subType: subType as ExclusiveCombinedSubType<T>,
  }
}

export function duplicateCard(card: Card, position?: CardPosition): Card {
  const clonedCard = cloneRaw(card)
  return {
    ...clonedCard,
    question: {
      ...clonedCard.question,
      options: {
        ...clonedCard.question.options,
        answers: clonedCard.question.options.answers.map(answer => ({
          ...answer,
          omittedFromAudiences: new Set(answer.omittedFromAudiences),
        })),
      },
    },
    omittedFromAudiences: new Set(card.omittedFromAudiences),
    guid: createEmptyCard().guid,
    position: clone(position ?? clonedCard.position),
    publishedTimestamp: undefined,
    group: {
      ...clone(clonedCard.group),
      cards: clonedCard.group.cards.map(groupedCard => duplicateCard(groupedCard)),
    },
  }
}

export function cloneCards(cards: Card[]): Card[] {
  return cards.map(card =>
    Object.freeze({
      ...duplicateCard(card),
      guid: card.guid,
      group: { ...card.group, cards: cloneCards(card.group.cards) },
    }),
  )
}
