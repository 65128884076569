import { useMemoize } from '@vueuse/core'
import { computed, type ComputedRef } from 'vue'

import type { Round } from '@attest/rounds'

import { getColumnCacheKey } from '../memoization'
import type { Row, RowChild } from '../row'

type MemoizePayload = Partial<{
  cardId: string
  column: { type: any; value: string; variable: string }
  row: Row
  rowChild: RowChild
}>

export function useMemoizeRounds<T extends MemoizePayload>(
  fn: (payload: T) => Set<Round>,
): (payload: T) => ComputedRef<Set<Round>> {
  return useMemoize(payload => computed(() => fn(payload as T)), { getKey })
}

function getKey(payload: MemoizePayload) {
  return `${payload.cardId}${getColumnCacheKey(payload.column)}${payload.row?.id}${payload.rowChild?.id}`
}
