import type { RouteLocation, RouteRecordRaw } from 'vue-router'

import { REPORTS_ROUTE_NAME, VIEWS } from '@attest/router'

export function createStudyReportsRoute(
  props: (route: RouteLocation) => { studyId: string },
): RouteRecordRaw {
  return {
    path: '',
    children: [
      {
        name: REPORTS_ROUTE_NAME.REPORTS,
        path: 'reports',
        components: {
          [VIEWS.STUDY_NAVBAR]: async () =>
            (
              await import(
                '@attest/study/src/components/organism/StudyNavbar/ResultsAndManageNavbar.vue'
              )
            ).default,
          default: async () => await import('./components/Reports.vue'),
        },
        props: {
          default: props,
        },
        meta: {
          showNavbarTopbar: true,
          showProgressBar: true,
          authenticationRequired: 'authenticated',
        },
      },
      {
        name: REPORTS_ROUTE_NAME.REPORT,
        path: 'report/:reportId',
        components: {
          [VIEWS.STUDY_NAVBAR]: async () =>
            (
              await import(
                '@attest/study/src/components/organism/StudyNavbar/ResultsAndManageNavbar.vue'
              )
            ).default,
          default: async () => await import('./components/Report.vue'),
        },

        props: {
          default: props,
        },
        meta: {
          showNavbarTopbar: true,
          showProgressBar: true,
          authenticationRequired: 'authenticated',
        },
      },
      {
        name: REPORTS_ROUTE_NAME.READONLY_REPORT,
        path: 'readonly-report/:reportId',
        components: {
          [VIEWS.STUDY_NAVBAR]: async () =>
            (
              await import(
                '@attest/study/src/components/organism/StudyNavbar/ResultsAndManageNavbar.vue'
              )
            ).default,
          default: async () => await import('./components/Report.vue'),
        },

        props: {
          default: () => {
            return { ...props, readonly: true }
          },
        },
        meta: {
          showNavbarTopbar: false,
          showProgressBar: false,
          authenticationRequired: 'either',
        },
      },
    ],
  }
}
export { REPORTS_ROUTE_NAME }
