import { defineStore } from 'pinia'

import { isRangeOption, type RangeOption } from '@attest/audience-demographic'
import { sum } from '@attest/util'

import type { DemographicFilters } from '../model'

export const RESULTS_DEMOGRAPHIC_FILTERS_NAMESPACE = 'resultsDemographicFilters'

export type ResultsDemographicFiltersState = {
  nameToFilters: DemographicFilters
  isEnabled: boolean
}

export function createResultsDemographicFiltersState(
  override: Partial<ResultsDemographicFiltersState> = {},
): ResultsDemographicFiltersState {
  return {
    nameToFilters: {},
    isEnabled: false,
    ...override,
  }
}

function isRangeOptions(values: RangeOption[] | string[]): values is RangeOption[] {
  return [...values].every(isRangeOption)
}

function isStringOptions(values: RangeOption[] | string[]): values is string[] {
  return [...values].every(item => typeof item === 'string')
}

export const useResultsDemographicFiltersStore = defineStore(
  RESULTS_DEMOGRAPHIC_FILTERS_NAMESPACE,
  {
    state: createResultsDemographicFiltersState,
    getters: {
      shouldFilter(state): boolean {
        return state.isEnabled && this.totalFilters > 0
      },
      totalFilters(state) {
        return sum(Object.values(state.nameToFilters).map(value => value.length))
      },
    },
    actions: {
      toggleEnabled() {
        this.isEnabled = !this.isEnabled
      },

      reset(filters: Record<string, RangeOption[] | string[]> = {}) {
        this.nameToFilters = filters
        if (this.totalFilters > 0) {
          this.isEnabled = true
        }
      },

      toggle(payload: { name: string; value: RangeOption | string }) {
        const { name, value } = payload

        if (!this.isEnabled) {
          this.isEnabled = true
          this.reset({})
        }

        const filter = this.nameToFilters[name]
        if (!filter) {
          useResultsDemographicFiltersStore().nameToFilters[name] = [value] as
            | string[]
            | RangeOption[]
          return
        }

        if (isRangeOption(value) && isRangeOptions(filter)) {
          filter.splice(0, 1, value)
          return
        }

        if (typeof value === 'string' && isStringOptions(filter)) {
          const index = filter.indexOf(value)
          if (index === -1) {
            filter.push(value)
            return
          }

          if (filter.length <= 1) {
            delete useResultsDemographicFiltersStore().nameToFilters[name]
            return
          }

          filter.splice(index, 1)
        }
      },

      clear(payload: { name: string }) {
        const { name } = payload
        if (!this.nameToFilters[name]) return
        delete this.nameToFilters[name]
      },
    },
  },
)
