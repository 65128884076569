import { getUTCWaveStartTimestamp } from './transformer'

function getWaveTimestamp(wave: {
  startTimestamp: number | null
  timeZoneId: string | null
}): number {
  return getUTCWaveStartTimestamp(wave) ?? Number.POSITIVE_INFINITY
}

export function sortWavesByDescendingStartTime<
  WaveSurvey extends { startTimestamp: number | null; timeZoneId: string | null },
>(waves: WaveSurvey[]): WaveSurvey[] {
  return waves.toSorted((a, b) => getWaveTimestamp(b) - getWaveTimestamp(a))
}

export function sortWavesByAscendingStartTime<
  WaveSurvey extends { startTimestamp: number | null; timeZoneId: string | null },
>(waves: WaveSurvey[]): WaveSurvey[] {
  return waves.toSorted((a, b) => getWaveTimestamp(a) - getWaveTimestamp(b))
}

export function sortWavesByDescendingPublishedTimestamp<
  WaveSurvey extends { publishedTimestamp: number | null },
>(waves: WaveSurvey[]): WaveSurvey[] {
  return sortWavesByAscendingPubishedTimestamp(waves).toReversed()
}

export function sortWavesByAscendingPubishedTimestamp<
  WaveSurvey extends { publishedTimestamp: number | null },
>(waves: WaveSurvey[]): WaveSurvey[] {
  const getWaveComparitor = (wave: WaveSurvey): number =>
    wave.publishedTimestamp ?? Number.POSITIVE_INFINITY
  return waves.toSorted((a, b) => getWaveComparitor(a) - getWaveComparitor(b))
}
