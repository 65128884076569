<script lang="tsx">
import { defineComponent } from 'vue'

import templateSVG from '@attest/_assets/icons/dashboard_customize.svg'
import folderSVG from '@attest/_assets/icons/folder.svg'
import identCardSVG from '@attest/_assets/icons/group.svg'
import trendsByAttestSVG from '@attest/_assets/icons/leaderboard.svg'
import creditsSVG from '@attest/_assets/icons/toll.svg'
import { NavbarItem } from '@attest/_components/src/common/NavbarItem'
import NewBadge from '@attest/_components/src/common/NewBadge.vue'
import { COPY } from '@attest/_copy'
import { getVariation } from '@attest/feature-switch'
import { c } from '@attest/intl'
import { ROUTES, TEMPLATE_LISTING_ROUTES } from '@attest/router'
import { TRENDS_BY_ATTEST_ROUTES } from '@attest/trends-by-attest'
import { createEmitter } from '@attest/vue-tsx'

import type { DefaultNavbarEvents } from './DefaultNavbar.interface'

const emit = createEmitter<DefaultNavbarEvents>()

export default defineComponent({
  props: {
    routeName: { type: String, default: undefined },
    itemClass: { type: String, default: undefined },
  },
  methods: {
    handleCreateSurveyCTAClick(): void {
      emit(this, 'itemClick')
    },
  },

  render() {
    return (
      <div>
        <NavbarItem
          class={this.itemClass}
          icon={folderSVG}
          title="Surveys"
          to={
            getVariation('survey-folders') ? ROUTES.SURVEY_DIRECTORY.name : ROUTES.SURVEY_LIST.name
          }
          data-name="NavbarSurveyList"
          onMouseenter={() => emit(this, 'itemHover', 'Surveys')}
          onClick={() => emit(this, 'itemClick')}
        />
        <NavbarItem
          class={this.itemClass}
          title={c('navbar.templates')}
          icon={templateSVG}
          to={TEMPLATE_LISTING_ROUTES.ROOT.name}
          isForceActive={this.routeName === TEMPLATE_LISTING_ROUTES.ROOT.name}
          data-name="NavbarSurveyTemplates"
          onMouseenter={() => emit(this, 'itemHover', c('navbar.templates'))}
          onClick={() => emit(this, 'itemClick')}
        />
        {getVariation('trends-by-attest') !== '' && (
          <NavbarItem
            class={this.itemClass}
            icon={trendsByAttestSVG}
            title={c('trends-by-attest.navbar')}
            to={TRENDS_BY_ATTEST_ROUTES.ROOT.name}
            data-name="AdminNavbarHome"
            onMouseenter={() => this.$emit('itemHover', c('trends-by-attest.title'))}
          >
            {{ postfix: () => <NewBadge /> }}
          </NavbarItem>
        )}
        {!getVariation('no-credits') && !getVariation('credits-on-checkout-only') && (
          <NavbarItem
            class={this.itemClass}
            icon={creditsSVG}
            title={COPY.NAVIGATION.CREDITS}
            to={ROUTES.CREDITS.name}
            isForceActive={this.routeName === ROUTES.CREDITS.name}
            data-name="NavbarCredits"
            onMouseenter={() => emit(this, 'itemHover', COPY.NAVIGATION.CREDITS)}
            onClick={() => emit(this, 'itemClick')}
          />
        )}
        <NavbarItem
          class={this.itemClass}
          icon={identCardSVG}
          to={ROUTES.PEOPLE.name}
          title={COPY.NAVIGATION.PEOPLE}
          data-name="NavbarTeam"
          onMouseenter={() => emit(this, 'itemHover', COPY.NAVIGATION.PEOPLE)}
          onClick={() => emit(this, 'itemClick')}
        />
      </div>
    )
  },
})
</script>
