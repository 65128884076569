import type { TargetingError, TargetingsError } from '@attest/_lib/src/editor-error/editor-error'
import { createGetErrorsFor } from '@attest/editor'
import { SETTINGS } from '@attest/editor-settings'
import { groupBy, isOverlappingRanges, type Range } from '@attest/util'

import type { Audience, AudienceType } from '../model'
import { areQuotasValid, type Quota } from '../quota'
import { isRangeOptions } from '../segmentations'

export const getTargetingErrors = createGetErrorsFor<
  TargetingError['type'],
  Pick<Audience, 'quotas' | 'segmentations' | 'sampleSize'> & {
    surveyIsVideoResponseSurvey: boolean
  }
>({
  TARGETING_QUOTAS_MISALIGNED: ({ quotas }) => {
    return Array.from(Object.values(groupBy(quotas, getSegmentationName))).some(
      q => !areQuotasValid(q),
    )
  },

  TARGETING_AGE_RANGES_INVALID: ({ segmentations }) => {
    const ranges = segmentations.age
    if (!ranges || ranges.length === 0) return false
    if (!isRangeOptions(ranges)) return false

    return ranges.some((range: Range) => range.min > range.max)
  },

  TARGETING_QUOTA_BELOW_SAMPLE_SIZE: ({ sampleSize, quotas }) => {
    return quotas.some(quota => quota.limit * sampleSize < 1)
  },

  TARGETING_AGE_RANGES_OVERLAP: ({ segmentations }) => {
    const ranges = segmentations.age
    if (!ranges) return false
    if (ranges.length <= 1) return false
    if (!isRangeOptions(ranges)) throw new Error('Expect segmentations to be ranges.')

    return ranges.some(rangeA => isOverlappingRanges(rangeA, ranges))
  },
  TARGETING_BELOW_MIN_AGE: ({ segmentations }) => {
    const ranges = segmentations.age
    if (!ranges) return false
    if (ranges.length <= 0) return false
    if (!isRangeOptions(ranges)) throw new Error('Expect segmentations to be ranges.')

    return ranges.some(({ min }) => min < SETTINGS.AUDIENCE.DEFAULT_MIN_AGE_RANGE)
  },
  VIDEO_SURVEY_TARGETING_BELOW_MIN_AGE: ({ segmentations, surveyIsVideoResponseSurvey }) => {
    const ranges = segmentations.age
    if (!ranges) return false
    if (ranges.length <= 0) return false
    if (!isRangeOptions(ranges)) throw new Error('Expect segmentations to be ranges.')
    return (
      surveyIsVideoResponseSurvey &&
      ranges.some(({ min }) => min < SETTINGS.AUDIENCE.DEFAULT_MIN_AGE_RANGE_FOR_VIDEO_SURVEY)
    )
  },
})

export const getTargetingsErrors = createGetErrorsFor<
  TargetingsError['type'],
  { targetings: Audience[]; audienceType: AudienceType; draftLanguage: string }
>({
  EMPTY_TARGETINGS: ({ targetings, audienceType }) => {
    if (audienceType === 'own') return false
    return targetings.filter(targeting => targeting.country !== '').length === 0
  },
  NO_VISIBLE_TARGETINGS: ({ targetings }) => {
    return targetings.length > 0 && targetings.every(t => t.status === 'HIDDEN')
  },
})

const getSegmentationName = (quota: Quota): string => {
  const [segmentationName] = Object.keys(quota.segmentations)
  return segmentationName ?? ''
}
