import { useMemoize } from '@vueuse/core'
import { computed, type Ref } from 'vue'

import { filter, type FilterNode, type Value } from '@attest/efl'

import type { Round, RoundCardAnswer, RoundOutcome } from './model'

export function createRoundsFilter(roundsRef: Ref<Set<Round>>): (node: unknown) => Ref<Set<Round>> {
  return useMemoize(
    node => computed(() => filter(roundsRef.value, node as any, getRoundField)),

    {
      getKey(node) {
        return (node as FilterNode).toJSON(true) as string
      },
    },
  )
}

export type RoundFieldKey =
  | 'id'
  | 'survey.id'
  | 'audience.id'
  | 'audience.country'
  | 'waveTimestamp'
  | 'outcome'
  | `subjects.age`
  | `subjects.${string}.*`
  | `cards.*~`
  | `cards.${string}.answers.*.${keyof RoundCardAnswer}`
  | `cards.${string}.answers.${string}.${keyof RoundCardAnswer}`
  | `cards.${string}.answers.*~`

function getRoundField(round: Round, key: RoundFieldKey | string): Value {
  switch (key) {
    case 'id':
      return round.id
    case 'waveTimestamp':
      return round.waveTimestamp ?? null
    case 'survey.id':
      return round.survey.id
    case 'audience.id':
      return round.audience.id
    case 'audience.country':
      return round.audience.country
    case 'outcome':
      return round.outcome
    case 'cards.*~':
      return Object.keys(round.cards)
    default: {
      const { groups: { cardId = undefined, answerId = undefined, field = undefined } = {} } =
        key.match(/^cards\.(?<cardId>[^.]+)\.answers\.(?<answerId>[^.]+)\.?(?<field>[^.]+)$/) ?? {}
      if (cardId && answerId && field) {
        const answers = round.cards[cardId]?.answers ?? {}

        if (answerId === '*') {
          return field === '~'
            ? Object.keys(answers)
            : (Object.values(answers).map(
                answer => answer[field as keyof RoundCardAnswer] ?? null,
              ) as Value)
        }

        return answers[answerId]?.[field as keyof RoundCardAnswer] ?? null
      }

      if (key === 'subjects.age') {
        return round.subjects.age
      }

      const { groups: { name = undefined } = {} } = key.match(/^subjects\.(?<name>.+)\.\*$/) ?? {}
      if (name && round.subjects[name]) {
        const subject = round.subjects[name]
        return Array.isArray(subject) ? subject : ([subject] as Value)
      }

      return null
    }
  }
}

type NumberAnswerFields = Extract<'order', keyof RoundCardAnswer>
type StringAnswerFields = Exclude<'order', keyof RoundCardAnswer>

// This although ugly allows us to strongly type the field key to the expected
// efl values that can be used for that key.
// IE ...fieldAndValues('wave.id', 20) will fail
type RoundFieldKeyToAcceptedValues<K extends RoundFieldKey> = K extends `outcome`
  ? RoundOutcome | RoundOutcome[]
  : K extends
        | `waveTimestamp`
        | `cards.${string}.answers.*.${NumberAnswerFields}`
        | `cards.${string}.answers.${string}.${NumberAnswerFields}`
        | `subjects.age`
    ? number | number[] | [min: number, max: number] | [min: number, max: number][]
    : K extends
          | `id`
          | `survey.id`
          | `subjects.${string}.*`
          | `audience.id`
          | `audience.country`
          | `cards.*~`
          | `cards.${string}.answers.*~`
          | `cards.${string}.answers.*.${StringAnswerFields}`
          | `cards.${string}.answers.${string}.${StringAnswerFields}`
      ? string | string[]
      : Value

export function fieldAndValues<K extends RoundFieldKey, V extends RoundFieldKeyToAcceptedValues<K>>(
  key: K,
  value: V,
): [RoundFieldKey, V] {
  return [key, value]
}
