import {
  BETWEEN,
  eflStringToFilterNode,
  EQUAL,
  FilterBlock,
  FilterExpression,
  type FilterNode,
  IN,
  INCLUDES,
  OR,
} from '@attest/efl'
import { isRange, type Range } from '@attest/util'

import { isFilterBlock, isFilterExpression } from '../../../results/packages/efl/src/feature/model'

import { fieldAndValues, type RoundFieldKey } from './filter'
import type { RoundCardAnswer } from './model'

export function createRoundSurveyIdsFilterExpression(surveyIds: string[]): FilterExpression {
  if (surveyIds.length === 1) {
    return EQUAL(...fieldAndValues('survey.id', surveyIds[0]))
  }
  return IN(...fieldAndValues('survey.id', surveyIds))
}

export function createRoundSurveyIdFilterExpression(surveyId: string): FilterExpression {
  return EQUAL(...fieldAndValues('survey.id', surveyId))
}

export function createRoundSegmentFilterNode(efl: string): FilterNode {
  const node = eflStringToFilterNode(efl)

  if (isFilterBlock(node) && node.items.length === 1) {
    return convertSegmentFilterNode(node.items[0])
  }

  return convertSegmentFilterNode(node)
}

function convertSegmentFilterNode(node: FilterNode): FilterNode {
  if (isFilterBlock(node)) {
    return new FilterBlock(
      node.items.map(item => convertSegmentFilterNode(item)),
      node.logic,
    )
  }

  if (isFilterExpression(node)) {
    return new FilterExpression(
      convertSegmentFilterKey(node.key),
      node.type,
      node.category,
      node.operator,
      node.value,
      node.not,
    )
  }

  return new FilterBlock()
}

function convertSegmentFilterKey(key: string): RoundFieldKey {
  if (key === 'survey_guid' || key === 'survey.id') {
    return 'survey.id'
  }

  if (key === 'audience' || key === 'audience.country') {
    return 'audience.id'
  }

  if (key === 'country' || key === 'audience.country') {
    return 'audience.country'
  }

  if (key === 'wave_published_time' || key === 'waveTimestamp') {
    return 'waveTimestamp'
  }

  if (key === 'outcome') {
    return 'outcome'
  }

  if (key === 'age' || key === 'subjects.age') {
    return 'subjects.age'
  }

  if (key.startsWith('cards.')) {
    return key as RoundFieldKey
  }

  const { groups: { cardId = undefined, field = undefined } = {} } =
    key.match(/^answers\.(?<cardId>.+)(\.|\.\*\.)(?<field>.+)$/) ?? {}
  if (cardId && field) {
    if (field === 'id') {
      return `cards.${cardId}.answers.*~`
    }
    return `cards.${cardId}.answers.*.${field as keyof RoundCardAnswer}`
  }

  if (key.startsWith('subjects.')) {
    return key as RoundFieldKey
  }

  return `subjects.${key}.*`
}

export function createRoundSuccessfulFilterExpression(): FilterExpression {
  return IN(...fieldAndValues('outcome', ['COMPLETED', 'APPROVED']))
}

export function createRoundCompletedFilterExpression(): FilterExpression {
  return EQUAL(...fieldAndValues('outcome', 'COMPLETED'))
}

export function createRoundApprovedFilterExpression(): FilterExpression {
  return EQUAL(...fieldAndValues('outcome', 'APPROVED'))
}

export function createRoundAnswerIdsFilterExpression(
  cardId: string,
  answersIds: string[],
): FilterExpression {
  return INCLUDES(...fieldAndValues(`cards.${cardId}.answers.*~`, answersIds))
}

export function createRoundIdsFilterExpression(ids: string[]): FilterExpression {
  return INCLUDES(...fieldAndValues(`id`, ids))
}

export function createRoundAnswersOrderFilterNode(
  cardId: string,
  answerIds: string[],
  orders: number[],
): FilterNode {
  return OR(
    answerIds.map(answerId =>
      INCLUDES(...fieldAndValues(`cards.${cardId}.answers.${answerId}.order`, orders)),
    ),
  )
}

export function createRoundSegmentationsFilterNode(
  name: string,
  filters: Range[] | string[],
): FilterNode {
  if (filters.length === 1) {
    return createRoundSegmentationFilterExpression(name, filters[0])
  }

  if (filters.every(isRange)) {
    return OR(filters.map(filter => createRoundSegmentationFilterExpression(name, filter)))
  }

  return INCLUDES(...fieldAndValues(`subjects.${name}.*`, filters))
}

export function createRoundSegmentationFilterExpression(
  name: string,
  filter: Range | string,
): FilterExpression {
  if (isRange(filter)) {
    return BETWEEN(...fieldAndValues(`subjects.age`, [filter.min, filter.max]))
  }

  return INCLUDES(...fieldAndValues(`subjects.${name}.*`, filter))
}

export function createRoundCardIdFilterExpression(cardId: string): FilterExpression {
  return EQUAL(...fieldAndValues(`cards.*~`, cardId))
}

export function createRoundWaveTimestampFilterExpression(waveTimestamp: number): FilterExpression {
  return EQUAL(...fieldAndValues('waveTimestamp', waveTimestamp))
}

export function createRoundAudienceIdFilterExpression(audienceId: string): FilterExpression {
  return EQUAL(...fieldAndValues('audience.id', audienceId))
}

export function createRoundAudienceCountryFilterExpression(country: string): FilterExpression {
  return EQUAL(...fieldAndValues('audience.country', country))
}
