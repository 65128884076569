import { c } from '@attest/intl'

export function pluralise(
  n: number,
  keyToValue: Record<'one' | 'other', string>,
  options?: Intl.PluralRulesOptions,
): string {
  return keyToValue[
    new Intl.PluralRules('en', { type: 'cardinal', ...options }).select(n) as keyof Record<
      'one' | 'other',
      string
    >
  ]
}

const EDITOR_ERRORS = {
  CARD_TEXT_MISSING: 'You need to add a text.',
  CARD_TEXT_TOO_SHORT: (min: number) =>
    `Your message card is too short, it needs to be at least ${min} characters.`,
  CARD_TEXT_TOO_LONG: (max: number) =>
    `Your message card is too long, it can't be more than ${max} characters.`,
  CARD_TITLE_TOO_SHORT: (min: number) =>
    `Your question is too short, it needs at least ${min} characters.`,
  CARD_TITLE_TOO_LONG: (max: number) =>
    `Your question is too long, it can't be more than ${max} characters.`,
  CARD_TITLE_MISSING: 'You need to add a question title.',
  CARD_TITLE_HAS_PLACEHOLDER_TEXT: 'You need to fill in the placeholder',
  CARD_TEXT_HAS_PLACEHOLDER_TEXT: 'You need to fill in the placeholder',
  IMAGE_ANSWERS_MISSING_IMAGES: '1 or more of answers has no image.',
  IMAGE_CAPTION_ANSWERS_TOO_LONG: '1 or more of your image captions is too long.',
  TEXT_ANSWERS_TOO_SHORT: '1 or more of your answers is missing.',
  VIDEO_PROMPT_TOO_LONG: c('editor.error.card.video_prompt_too_long'),
  QUALIFYING_WITH_ANSWER_ROUTES_IS_NOT_LAST_QUALIFYING: c(
    'editor.qualifying.error.answer_routes_and_not_last_qualifying',
  ),
  GRID_NOT_ENOUGH_SUBJECTS_FOR_AUDIENCE: c('editor.card.errors.not_enough_subjects_for_audience'),
  GRID_NOT_ENOUGH_SUBJECTS: (min: number) => `Your grid question needs at least ${min} subjects.`,
  GRID_TOO_MANY_SUBJECTS: (max: number) =>
    c('editor.card.grid.maximum_subjects', {
      placeholders: { max: `${max}` },
    }),
  GRID_TOO_MANY_SUBJECTS_IF_MASKING: (max: number) =>
    c('editor.card.grid.maximum_subjects_if_masking', {
      placeholders: { max: `${max}` },
    }),
  GRID_NOT_ENOUGH_OPTIONS: (min: number) => `Your grid question needs at least ${min} answers.`,
  GRID_SUBJECTS_TOO_LONG: (maxChars: number) =>
    `1 or more of your subjects is too long, it can't be more than ${maxChars} characters.`,
  GRID_OPTIONS_TOO_LONG: (maxChars: number) =>
    `1 or more of your grid answers is too long, it can't be more than ${maxChars} characters.`,
  GRID_OPTIONS_MISSING_IMAGES: '1 or more of your grid answers needs an image.',
  QUALIFYING_EMPTY_ANSWER: 'Add content to your qualifying answer.',
  TEXT_ANSWERS_TOO_LONG: (max: number) =>
    `1 or more of your answers is too long, it can't be more than ${max} characters.`,

  IMAGE_ANSWER_MISSING_IMAGES: 'Your answer needs an image.',
  IMAGE_CAPTION_ANSWER_TOO_LONG: (max: number) =>
    `Your image caption is too long, it can't be more than ${max} characters.`,
  DUPLICATE_ANSWERS: `2 or more of your answers are the same.`,
  TEXT_ANSWER_TOO_SHORT: `Please add an answer.`,
  TEXT_ANSWER_TOO_LONG: (max: number) =>
    `Your answer is too long, it can't be more than ${max} characters.`,
  ANSWER_HAS_PLACEHOLDER: 'You need to fill in the placeholder',

  SAMPLE_SIZE_TOO_SMALL: 'You need to send your survey to at least 1 respondent',
  SAMPLE_SIZE_TOO_BIG: "Select a number of respondents that's within the total matches available",
  QUAL_SAMPLE_SIZE_TOO_HIGH: c('editor.error.qual_survey.too_many_respondents'),
  AVAILABLE_SIZE_TOO_SMALL: 'Reduce the custom audience settings',
  SURVEY_TITLE_IS_MISSING: 'You need to add a survey title.',
  SURVEY_TITLE_TOO_LONG: (max: number) =>
    `Your survey title is too long, it can't be more than ${max} characters.`,
  SURVEY_TITLE_TOO_SHORT: (min: number) =>
    `Your survey title is too short, it needs at least ${min} characters.`,
  SURVEY_INTERNAL_TITLE_TOO_LONG: (max: number) =>
    `Your internal title is too long, it can't be more than ${max} characters.`,
  START_BEFORE_NOW: 'Please pick a start date in the future.',
  ALL_QUESTIONS_ARE_QUALIFYING:
    'All your questions seem to be qualifying, at least 1 must not be a qualifying question.',
  QUALIFYING_IN_MIDDLE_OF_SURVEY:
    "Your qualifying questions need to be the first questions you ask, move any qualifying questions you're using.",
  QUESTION_RANDOMIZED_AND_QUALIFYING: `You cannot randomise a qualifying question. Remove the randomisation or toggle qualifying off.`,
  TEXT_CARD_RANDOMISED_WITH_LAST_QUESTION_CARD: `Your last card can't be a message card. Edit your randomised cards so the last card is not randomised with a message card.`,

  SENDER_MASKING_AFTER_RECEIVER:
    'You can only forward answers to a question that comes after it. Please re-order your questions or disable forward answers.',
  CARD_MASKING_TO_DIFFERENT_ROUTE:
    'You can only have one route leading to a question with forward answers enabled.  Please re-order your questions or disable forward answers.',
  RECEIVER_OR_SENDER_ARE_RANDOMIZED: `You can't forward answers when your questions are randomised.`,
  RECEIVER_NOT_IN_SAME_GROUP_AS_SENDER: `This card needs to be in the same group as the card it's pulling in answers from.  Please re-order your questions or disable forward answers.`,
  CARD_ROUTING_IS_CYCLIC: "You can't route to a previous question.",
  CARD_ROUTING_NOT_REACHABLE: 'Add this card to a route.',
  CARD_ROUTING_TO_OTHER_THAN_FIRST_RANDOMISED_CARD:
    'You can only route to the first randomised card or after the last randomised card.',
  CARD_DISCONNECTED_FROM_MAIN_RANDOMISATION_GROUP: c(
    'editor.card_errors.randomised_card_disconnected',
  ),
  CARD_ROUTING_TOO_MANY_ROUTES_ON_MULTIPLE_CHOICE_QUESTION:
    'You can only route multiple choice answers to 1 card.',
  CARD_ROUTING_ANSWER_IS_EMPTY: "Add content to the answer you're routing from.",
  CARD_ROUTING_ANSWER_IS_CYCLIC: "You can't route to a previous question.",
  CARD_ROUTING_ANSWER_AND_RANDOMIZED_QUESTION: `You cannot randomise a card with routed answers. Remove the randomisation or the routed answers.`,
  CARD_ROUTING_ANSWER_FROM_QUESTION_BETWEEN_RANDOMIZED_CARDS: `You cannot route from a card that is between randomised cards. Move your randomised cards to one side of the routing.`,
  CARD_ROUTING_ANSWER_TO_OTHER_THAN_FIRST_RANDOMISED_CARD: `You cannot route to a card that is between randomised cards. Move your randomised cards to one side of the routing.`,
  CARD_PIPING_TO_RANDOMIZED_QUESTION: `You cannot pipe to a question that is randomised. Remove the randomisation or the piping.`,
  CARD_PIPING_FROM_RANDOMIZED_QUESTION: `You cannot pipe from a question that is randomised. Remove the randomisation or the piping.`,
  CARD_PIPING_FROM_QUESTION_BETWEEN_RANDOMIZED_CARDS: c(
    'editor.piping.error.piping_from_question_between_randomised_cards',
  ),
  CARD_IS_PIPING_AND_GROUPED: `You cannot pipe from a question that is grouped. Remove the piping.`,
  QUALIFYING_QUOTAS_SUM_TOO_HIGH: c('answer_quotas.error_qualifying_quotas_sum_too_high'),
  QUALIFYING_QUOTAS_AMOUNT_USED_UP: c('answer_quotas.error_qualifying_quotas_amount_used_up'),
  INVALID_ANSWER_QUOTA_VALUES: c('answer_quotas.error_invalid_answer_quota_values'),
  TOO_MANY_QUESTIONS_IN_ROUTE: (max: number) =>
    `Too many questions, you can only have ${max} questions per route.`,
  TOO_MANY_QUALIFYING_QUESTIONS: (max: number) =>
    `Your survey can't have more than ${max} qualifying questions.`,
  VIDEO_QUESTIONS_NOT_ENABLED: c('editor.error.video_not_enabled'),
  VIDEO_QUESTION_SURVEY_WITH_TOO_MANY_VIDEO_QUESTIONS: (max: number) =>
    c('editor.error.too_many_video_questions', {
      placeholders: { max: `${max}` },
    }),
  MISSING_PRIVACY_POLICY: c('editor.error.missing_privacy_policy.text'),
  VIDEO_QUESTION_SURVEY_WITH_TOO_MANY_QUALIFYING_QUESTIONS: c(
    'editor.error.too_many_qualifiers_for_video.text',
  ),
  VIDEO_QUESTION_SURVEY_WITH_TOO_MANY_NON_VIDEO_QUESTIONS: c(
    'editor.error.video_question_survey_with_too_many_non_video_questions.text',
  ),
  TARGETING_AGE_RANGES_OVERLAP: "You've got 2 or more age ranges that overlap.",
  TARGETING_AGE_RANGES_INVALID:
    "You've got 1 or more age ranges that are invalid. The maximum age must be greater than the minimum age.",
  TARGETING_COUNTRY_NOT_AVAILABLE: c('monthly_plan.error_message.country_not_available'),
  MISSING_QUESTION_TYPE: 'You need to select a question type.',
  NOT_ENOUGH_ANSWERS_FOR_AUDIENCE: c('editor.card.errors.not_enough_answers_for_audience'),
  NOT_ENOUGH_ANSWERS: 'Your question needs at least 2 answers.',
  TARGETING_QUOTAS_MISALIGNED: 'Your audience quotas must add up to 100%.',
  TARGETING_QUOTA_BELOW_SAMPLE_SIZE:
    'Your sample size is too small to achieve your current demographic quotas',
  TARGETING_BELOW_MIN_AGE: (minAge: number) =>
    c('audience.errors.targeting_below_age_minimum', {
      placeholders: { minAge: `${minAge}` },
    }),
  VIDEO_SURVEY_TARGETING_BELOW_MIN_AGE: (minAge: number) =>
    c('audience.errors.targeting_below_age_minimum', {
      placeholders: { minAge: `${minAge}` },
    }),
  TOO_MANY_TEXT_CARDS_IN_A_ROW: "You can't have two or more message cards in a row.",
  ENDING_CARD_IS_A_TEXT_CARD: "Your last question can't be a message card.",
  ALL_CARDS_ARE_TEXT_CARDS: 'All of your cards are message cards.',

  CARD_PIPING_FROM_NON_EXISTENT_QUESTION:
    "The question you'd like to pipe from no longer exists. Please select a question that already exists.",
  CARD_PIPING_FROM_FUTURE_QUESTION:
    "You can't pipe to a question that's coming up, please select a previous question to pipe from.",
  CARD_PIPING_FROM_INVALID_QUESTION_TYPE: 'You can only pipe from the single choice question type.',
  CARD_PIPING_FROM_SELF:
    "It looks like you're trying to pipe from this question. Please select a previous question to pipe from.",
  CARD_PIPING_TITLE_TOO_LONG: (max: number) =>
    `Your question is too long because of the piping. Your question can't be more than ${max} characters.`,
  CARD_PIPING_FROM_DIFFERENT_ROUTE:
    "Your piping is pointing to a card which is not on the same route. Adjust the piping or move the card so it's on the same route.",
  CARD_PIPING_FROM_QUESTION_WITH_EMPTY_ANSWER_TEXT: c(
    'editor.card.errors.piping_from_question_with_empty_answer_text',
  ),
  CARDS_CANNOT_BE_REACHED:
    "Your survey contains one or more cards that aren't included in any route. Route to these cards, or delete them from your survey.",
  QUESTIONS_HAVE_ERRORS: 'Your questions contain at least one error. Please correct any red cards.',
  EMPTY_SURVEY: 'Your survey needs to contain at least one question.',
  ERRORS_IN_SURVEY: 'There are errors in your survey, please fix them to be able to proceed.',
  OPEN_TEXT_FIELD_TITLE_TOO_LONG: (max: number) =>
    `1 or more of your field titles is too long, it can't be more than ${max} characters.`,
  EMPTY_GROUP: 'You need to add at least one card to the group.',
  ADJACENT_TEXT_CARDS_IN_GROUP: "You can't have two or more message cards in a row within a group.",
  ADJACENT_TEXT_CARD_AND_GROUP_TEXT_CARD:
    "You can't have a message card outside a group adjacent to another message card within a group.",
  ADJACENT_TEXT_CARDS_BETWEEN_GROUPS:
    "You can't have a message card in a group adjacent to a message card in another group.",
  GROUP_CARD_CONTAINS_CARDS_WITH_CARD_ERRORS: c(
    'editor.group.error.group_card_containing_cards_with_card_errors',
  ),
  CARD_DISPLAY_LOGIC_REFERENCE_RANDOMIZED_WITH_TARGET: c(
    'editor.display_logic.error.reference_randomised',
  ),
  CARD_DISPLAY_LOGIC_IS_REFERENCE_AND_RANDOMIZED_WITH_TARGET: c(
    'editor.display_logic.error.reference_question_and_randomised',
  ),
  CARD_DISPLAY_LOGIC_DIFFERENT_LOGIC_THAN_PIPING_SOURCE: c(
    'editor.display_logic.error.display_logic_different_than_piping_source',
  ),
  CARD_DISPLAY_LOGIC_DIFFERENT_LOGIC_THAN_MASKING_SENDER: c(
    'editor.display_logic.error.different_logic_on_sender',
  ),
  START_BEFORE_PENDING_SCHEDULE: c('editor.schedule.error.start_before_previous_shedule'),
  EMPTY_TARGETINGS: c('editor.audience.no_audiences_error'),
  MISSING_TRANSLATIONS: 'You need to add translations',
  AUDIENCE_CANT_QUALIFY: c('editor.card.errors.audience_cant_qualify'),
  CARD_DISPLAY_LOGIC_WITH_QUALIFYING_ANSWER_QUOTAS: c(
    'editor.card.errors.card_display_logic_with_qualifying',
  ),
  NOT_ALL_AUDIENCES_SEE_CARD_WITH_ANSWER_QUOTAS: c(
    'editor.card.errors.not_all_answers_see_card_with_answer_quotas',
  ),
  CARD_PIPING_VISIBILITY_FOR_AUDIENCE_DIFFERENT: c(
    'editor.card.errors.piping_card_visibility_different_for_audience',
  ),
  CARD_DISPLAY_LOGIC_INVISIBLE_TO_ALL_AUDIENCES: c(
    'editor.card.errors.display_logic_invisible_to_all_audiences',
  ),
  EMPTY_SURVEY_FOR_AUDIENCE: c('editor.card.errors.empty_survey_for_audience'),
  NO_VISIBLE_TARGETINGS: c('editor.errors.audiences.no_visible_audiences'),
}

export const COPY = {
  BEFORE_UNLOAD_PROMPT_MESSAGE:
    'Do you want to leave this site? Changes you made may not be saved.',
  BETA: 'Beta',
  BETA_LABEL: 'These features are in development and may change.',
  BETA_LINK: 'More information',

  LAUNCHER: {
    CONFIRM_CLOSE: 'Confirm and close',
    SEARCH_LABEL: 'Type the title of a question',
    QUESTION_FROM_SURVEY: (questionTitle: string, surveyTitle?: string) =>
      surveyTitle ? `${questionTitle} from ${surveyTitle}` : questionTitle,
    LISTBOX_LABEL: (questionTitle: string, surveyTitle: string) =>
      `Comparing ${questionTitle} from ${surveyTitle} to`,
  },

  SUPPORT_EMAIL: 'support@askattest.com',
  HELLO_EMAIL: 'hello@askattest.com',
  UNKNOWN_ERROR: 'An error has occurred. Please contact support@askattest.com for help',
  CROSS_SURVEY_ANALYSIS: 'Cross survey analysis',
  REJECT: 'Reject',

  NAVIGATION: {
    WALKTHROUGH: 'Walkthrough',
    WALLET: 'Wallet',
    HELP: 'Help',
    LOGIN: 'Login',
    REGISTER: 'Register',
    PEOPLE: 'People',
    CREDITS: 'Credits',
    CTA: {
      FREE_CREDITS: () => `Unlock free credits`,
      UPGRADE_TO_PLAN: `Upgrade your plan`,
    },
    PROFILE: 'Profile & Settings',
    LOGOUT: 'Log out',
  },

  REGISTER: {
    TITLE: {
      DEFAULT: 'Sign up',
      INVITED: (orgName: string) => `Join ${orgName} on Attest`,
      INVITED_FALLBACK: 'Join your team on Attest',
    },
    FORM: {
      PLACEHOLDERS: {
        EMAIL: 'Work email',
        PASSWORD: 'Password',
        FIRST_NAME: 'First name',
        LAST_NAME: 'Last name',
      },
    },
    OPT_OUT_MESSAGE: <T>(transformers: { optOut: (optOut: string) => T }) =>
      [
        'Once you’ve signed up, we may email you from time to time to tell you about our services and product updates. We’ll never share your information with third parties for marketing purposes and you can ',
        transformers.optOut('unsubscribe'),
        ' at any time.',
      ] as const,
    UNDO_OPT_OUT_MESSAGE: <T>(transformers: { undoOptOut: (s: string) => T }) =>
      [
        'You will be unsubscribed from our email updates after registration. Click ',
        transformers.undoOptOut('here'),
        ' to opt back in.',
      ] as const,
    BANNER_LINES: ['No more guesswork.', 'Way more confidence.'],
    ALREADY_HAVE_ACCOUNT_QUESTION: 'Already have an account?',
    LOGIN: 'Log in',
  },

  LOGIN: {
    TITLE: {
      INVITED: (orgName: string) => `Join ${orgName}`,
      INVITED_FALLBACK: 'Join your team',
    },
    BANNER_TITLE: 'New data export options and more powerful results!',
    BANNER_DESCRIPTION: `Find a new PowerPoint export, plus more features to help you analyse your data, in your results dashboard.`,
    SIGN_UP: 'Register an account',
    FORGOT_PASSWORD: 'Forgot password?',
    FORM: {
      MESSAGES: {
        TRY_AGAIN: (time: number) =>
          ` Try again in ${time} minutes or contact support@askattest.com`,
      },
      PLACEHOLDERS: {
        EMAIL: 'Work Email',
        PASSWORD: 'Password',
        SUBMIT: 'Log in',
        SUBMIT_INVITED: ' Join now',
      },
    },
  },

  MFA: {
    FORM: {
      PLACEHOLDERS: {
        CODE: 'Verification code',
        SUBMIT: 'Log in',
      },
    },
    MODAL: {
      HEADER: 'Lost your device',
      TITLE: 'Reset your 2-step Verification',
      BODY: 'If you’ve lost your device you’ll need to contact your organisation’s administrator who can reset your 2-step verification from your Team page. If you are the administrator, please contact support@askattest.com.',
      BUTTON: 'Close',
    },
  },

  SSO: {
    LOGIN: {
      NOT_FOUND: 'Account not found',
      ACCOUNT_NOT_ACTIVATED: 'This account is no longer active',
    },

    REGISTER: {
      EMAIL_IS_TAKEN: 'This email is already in use on this service',
      SSO_ID_ALREADY_EXISTS: 'This account is already registered',
    },
  },

  FORGOT_PASSWORD: {
    TITLE: 'Password reset',
    FORM: {
      PLACEHOLDERS: {
        EMAIL: 'Work email',
      },
    },
  },

  RESET_PASSWORD: {
    TITLE: 'Password reset',
    SUBTITLE: 'Enter your new password for the account',
    LOGIN_LINK: 'Log in with an existing account',
    FORM: {
      PLACEHOLDERS: {
        EMAIL: 'Work email',
        PASSWORD: 'Password',
      },
      SUBMIT: 'Change password',
    },
  },

  CSV: {
    COUNT: 'Count',
    PERCENTAGE: 'Percentage',
    AVERAGE_POSITION: 'Average Position',
    TOTAL: 'Total',
    SCORE: 'Score',
    ANSWERS: 'Answers',
  },

  PASSWORD_REQUIREMENT_ERRORS: {
    PASSWORD_INCORRECT_LENGTH: `Must be between 12-64 characters`,
    NO_PREDICTABLE_WORDS_LINE_1: "Doesn't include predictable words or combinations",
    NO_PREDICTABLE_WORDS_LINE_2: 'e.g. password123',
    PASSWORD_MATCHES_EMAIL: 'Cannot contain your email',
  },

  BUILDERS: {
    SHOW: (numItems: number) => `${COPY.SHOW} ${numItems}`,
    VIEW_ALL: (numItems: number) => `${COPY.VIEW_ALL} ${numItems}`,
  },

  ATTEST: {
    COMPANY_NAME: 'Attest Technologies Limited',
    ADDRESS_LINE_1: '20-22 Wenlock Road',
    CITY: 'London',
    POST_CODE: 'N1 7GU',
    COMPANY_NUMBER: '09445883',
    VAT_NUMBER: '212319254',
    PHONE_NUMBER: '0330 808 4746',
  },

  EDITOR: {
    LOADING: `Loading your draft`,
    READ_ONLY_WARNING: `Comment-only mode. Only the person who created the survey can edit.`,
    EDIT_IN_LIST_VIEW: 'Edit in list view',
    TOGGLE_NEW_OLD_TOOLTIP:
      'You can switch at any time. Your changes will be kept and are available in both editors.',
    ERRORS: EDITOR_ERRORS,
    SECTION: {
      DRAFT: 'Draft survey',
      AUDIENCE: 'Set audience',
    },
    ADVICE: {
      TITLE: 'Survey advice',
      SUMMARY: (numQuestions: number) =>
        `${numQuestions} ${pluralise(numQuestions, {
          one: 'question',
          other: 'questions',
        })} ${pluralise(numQuestions, { one: 'has', other: 'have' })} unresolved advice.`,
      REVIEW: 'Review your survey before purchasing to improve the quality of your results.',
    },
    CANT_PURCHASE_NOT_OWNER: `You can't purchase a survey you don't own.`,
    REVIEW: {
      CURRENTLY_AVAILABLE: 'Currently available',
      CREDITS_TO_PAY: 'Total credits to pay',
      NEW_BALANCE: (credits: string) => `Your new credit pot balance will be ${credits}`,
      TOTAL_TO_PAY: 'Total to pay',
      INCLUDING_VAT: 'inc. VAT',
      CREDIT_POT: `Credit pot`,
      PAYMENT: `Payment method`,
    },
    OPEN_TEXT: {
      SHORT: 'Short - 60 characters',
      MEDIUM: 'Medium - 140 characters',
      LONG: 'Long - 800 characters',
      NUMBER_FIELDS: 'Number of answer fields',
    },
    SAVING: 'Saving',
    SAVED: 'Saved',
    QUOTAS_BLURB:
      "Quotas set the number of respondents with the chosen demographics that will complete your survey. Without quotas, it'll be completed by the first available respondents.",
    PREVIEW_DESCRIPTION: `Use the arrows to jump between questions,\nor take the survey as the respondent below.`,
    PREVIEW_RANDOMIZED_QUESTION_TOOLTIP: `This question will appear in a different position each time the survey is taken`,
    PREVIEW_RANDOMIZED_GROUP_TOOLTIP: `This question is part of a group. The group will appear in a different position each time the survey is taken`,
    PREVIEW_RESTART: {
      MESSAGE: 'This is the end of your survey',
      OK: 'Quit',
      CANCEL: 'Repeat',
    },
    SURVEY_DETAILS: 'Survey details',
  },

  EDITOR_ERRORS: {
    SAVE_FAILURE: {
      TITLE: 'Survey has stopped saving',
      BODY_PARAGRAPH:
        'Something has happened on our side and your survey has stopped saving. Please refresh the page now to avoid losing any work.',
      CONFIRM_COPY: 'Refresh',
    },
    SURVEY_VERSION: {
      TITLE: `There's a more recent version of this survey`,
      BODY_PARAGRAPH:
        'Please update to the latest version or all recent changes will be lost. If you do choose to continue all your changes will be permanently lost.',
      CONFIRM: 'Update',
      CANCEL: 'Keep this version',
    },
    POSITION_ERROR: {
      TITLE: 'Your cards are in invalid positions',
      BODY_PARAGRAPH:
        'One or more of your survey cards is in an invalid position, so your survey has stopped saving. Please refresh the page now to avoid losing any work. Your cards will be re-positioned when you refresh.',
      CONFIRM: 'Refresh',
    },
    URL_NOT_WHITELISTED: {
      TITLE: 'A card contains an invalid URL',
      BODY_PARAGRAPH:
        'One of more of your cards has media from an invalid URL. Please check and update these URLs in order to continue.',
      CANCEL: 'Cancel',
    },
  },

  ZOOM_MAP: 'Map view',
  ZOOM_LIST: 'List view',
  EDIT: 'Edit',
  CHANGE: 'Change',
  ADD_MEDIA: 'Add media',
  FREQUENCY: 'Frequency',
  PIPE: 'Pipe',
  DONE: 'Done',
  PREVIEW_MEDIA: 'Preview media',
  ADD_QUESTION_CARD: 'Add question',
  ADD_QUESTION_TO_GROUP: 'Add question to group',
  ADD_OTHER: 'Add other',
  PURCHASE_DATE: 'Purchase date',
  COMPANY_NUMBER: 'Company No',
  VAT_NUMBER: 'VAT No',
  TRANSACTION_HISTORY: 'Transaction history',
  TRANSACTIONS: 'Transactions',
  EQUAL_TO: 'Equal to',
  PREVIEW: 'Preview',
  PREVIEW_SURVEY: 'Preview your survey',
  OR: 'or',
  RESULTS: 'Results',
  COPIED: 'Copied',
  SUBMIT: 'Submit',
  SAVE_CHANGES: 'Save changes',
  PLAY: 'Play',
  PAUSE: 'Pause',
  ACTIONS: 'Actions',
  SELECT_ALL: 'Select all',
  DESELECT_ALL: 'Deselect all',
  ADD_AGE_RANGE: 'Add age range',
  REMOVE_RANGE: 'Remove range',
  COMPARE: 'Compare',
  COPY_LINK: 'Copy link',
  NA: 'N/A',
  NONE: 'None',
  OTHER: 'Other',
  SKIP: 'Skipped',
  NOW: 'Now',
  OPTIONAL: 'Optional',
  LATER: 'Later',
  AND: 'and',
  BASE: 'Base',
  SHOW: 'Show',
  VIEW_ALL: 'View all',
  VALUES: 'Values',
  PERCENTAGES: 'Percentages',
  DISTRIBUTE_EVENLY: 'Distribute evenly',
  OPEN_MENU: 'Open menu',
  NUMBER_SELECTED: (number: number) => `${number} selected`,
  NUMBER_TAKERS_SELECTED: (numTakers: number) =>
    `${numTakers} ${pluralise(numTakers, {
      one: 'respondent',
      other: 'respondents',
    })} selected`,
  NUMBER_RESPONDENTS: (number: number) =>
    `${number} ${pluralise(number, { one: 'respondent', other: 'respondents' })}`,
  QUOTAS: 'Quotas',
  CARD_TYPES: {
    SINGLE_CHOICE: 'Single choice',
    MULTIPLE_CHOICE: 'Multiple choice',
    EITHER_OR: 'Either or',
    RANKED: 'Ranked',
    MESSAGE_CARD: 'Message',
    TEXT: 'Message',
    TEXT_RESPONSE_SHORT: 'Short text response',
    TEXT_RESPONSE_MEDIUM: 'Medium text response',
    TEXT_RESPONSE_LONG: 'Long text response',
    FREE_TEXT: 'Open text',
    NPS: 'NPS',
    GRID: 'Grid',
    GROUP: 'Group',
  },
  GRID_SELECTION: {
    SINGLE: 'Single choice',
    MULTIPLE: 'Multiple choice',
  },
  MULTIPLE_CHOICE: {
    LIMIT: 'Multiple choice limit',
    NO_LIMIT: 'No limit',
  },
  GRID_PLACEHOLDERS: {
    ANSWER: [
      'Answer 1 (e.g. A few times an hour)',
      'Answer 2 (e.g. Every hour)',
      'Answer 3 (e.g. A few times a day)',
      'Answer 4 (e.g. Daily)',
      'Answer 5 (e.g. A few times a week)',
      'Answer 6 (e.g. Weekly)',
      'Answer 7 (e.g. A few times a month)',
      'Answer 8 (e.g. Monthly)',
      'Answer 9 (e.g. Yearly)',
      'Answer 10 (e.g. Less than once a year)',
    ],
  },
  DETAILS: 'Details',
  QUESTIONS: 'Questions',
  RANDOMIZE: 'Randomise',
  RANDOMIZED_ANSWERS: 'Randomised answers',
  RANDOMIZED_QUESTION: 'Randomised question',
  RANDOMIZED_GROUP: 'Randomised group',
  RANDOMIZED_ROWS: 'Randomised subjects',
  RANDOMIZATION: {
    SELECT_INDICATOR: `Select at least 1 question`,
  },
  ANSWER: 'Answer',
  MASKING: {
    FORWARD_ANSWERS: 'Forward answers',
    FORWARD_ANSWERS_FROM: 'Forward selected answers from',
    PREVIEW_EXPLANATION:
      'Respondents will only see answer choices that they selected in a previous question',
  },
  FILTERS: 'Filters',
  TARGETING: 'Targeting',
  SIG_TEST: 'Sig. Test',
  INSIGHTS: {
    TITLE: 'Insights',
    HEADING: 'Analysis available to you',
    DESCRIPTION:
      'A machine-learning powered analysis of your survey, with automatically-generated insights. We currently support:',
    LIST_ITEM_ONE: '- Sentiment Analysis',
    LIST_ITEM_TWO: '- Topic Analysis',
    LIST_ITEM_THREE: '- Brand Analysis',
    LIST_ITEM_FOUR: '- Driver Analysis',
    EXPLANATION: 'The type of analysis available varies based on your survey.',
    FIND_OUT_MORE: 'Find out more',
  },
  CLEAR: 'Clear',
  NET_PROMOTER_SCORE: 'Net Promoter Score',
  NPS: {
    PROMOTERS: 'Promoters',
    PASSIVES: 'Passives',
    DETRACTORS: 'Detractors',
    TITLE: 'Net Promoter Score',
    NOT_LIKELY: 'Not at all likely',
    EXTREMELY_LIKELY: 'Extremely likely',
  },
  SEE_FULL_RESPONSES: 'See full responses',
  RESPONSES_PER_QUESTION: 'Responses per question',
  NO_OTHER_ANSWERS: `No 'other' answers to display`,
  COUNTRY_LANGUAGE: 'Country & language',
  START_DATE: 'Start date',
  START: 'Start',
  END: 'End',
  SELECTED_CARD: 'Selected card',
  CARDS: 'Cards',
  CARDHOLDER_NAME: 'Cardholder name',
  EXPIRY: 'Expiry',
  REMOVE: 'Remove',
  BALANCE: 'Balance',
  DISABLED: 'Disabled',
  FIELD_TITLE: 'Field Title',
  CHECKOUT: 'Checkout',
  WALLET: 'Wallet',
  TOTAL: 'Total',
  AVAILABLE_IMMEDIATELY: 'Available Immediately',
  VAT: 'Vat',
  CREDIT: 'credit',
  CREDITS: 'credits',
  NUM_CREDITS_WITH_DEFAULT_UNIT: (credits: number) =>
    `${COPY.NUM_CREDITS(credits)} ${pluralise(credits, {
      one: 'credit',
      other: 'credits',
    })}`,
  NUM_CREDITS_WITH_SHORTHAND_UNIT: (credits: number) => `${COPY.NUM_CREDITS(credits)} credits`,
  NUM_CREDITS_WITH_POSITIVE_PREFIX: (credits: number) =>
    `${credits > 0 ? '+' : credits < 0 ? '-' : ''}${Math.abs(credits).toLocaleString()}`,
  NUM_CREDITS: (credits: number) => credits.toLocaleString(),
  UNTITLED: 'Untitled',
  AUDIO: 'Audio',
  PLAY_QUESTION_AUDIO: 'Play question audio',
  PAUSE_QUESTION_AUDIO: 'Pause question audio',
  TEXT: 'Text',
  QUESTION: (numQuestions: number) =>
    pluralise(numQuestions, { one: 'Question', other: 'Questions' }),
  AUDIENCE: 'Audience',
  EXCLUDE_RESPONDENTS: {
    EXCLUDE_SURVEY_RESPONDENTS: 'Exclude survey respondents',
    SEARCH_SURVEYS: 'Search surveys',
    SAVE: 'Save exclusions',
    ALL: (amount: number) => `All (${amount})`,
    SELECTED: (amount: number) => `Selected (${amount})`,
    NO_SURVEYS: {
      HEADING: 'You have no surveys to exclude',
      DESCRIPTION:
        'You can exclude respondents from surveys that are open or closed, but not drafts.',
    },
    NO_SELECTED_SURVEYS: {
      HEADING: 'You have not selected any surveys yet',
      DESCRIPTION: 'The respondents of the surveys you select will be excluded from this survey.',
    },
    SHOWING: (amount: number) =>
      `showing ${amount} ${pluralise(amount, { one: 'survey', other: 'surveys' })}`,
    SURVEY_STATUS_OPEN: 'Open',
    SURVEY_STATUS_CLOSED: 'Closed',
  },
  CREATED_BY: 'Created by',

  RESPONSE_PROGRESS: {
    COMPLETES: (completes: number) =>
      `${completes} ${pluralise(completes, {
        one: 'complete',
        other: 'completes',
      })} so far`,
    COMPLETES_FRACTION: (completes: number, maxResponses: number) =>
      `${completes} out of ${maxResponses} ${pluralise(maxResponses, {
        one: 'complete',
        other: 'completes',
      })}`,
  },

  TRIPLE_WIN: {
    OWN_RESPONSES: (responses: number) => `${responses} from your own audience`,
    OWN_AUDIENCE: `Sending to your\nown audience`,
  },

  HEADER_TITLE: {
    EDIT_BUTTON: 'Edit Survey Details',
    ALT: 'Survey cover image',
  },

  CHANGE_PASSWORD: 'Change password',
  CURRENT_PASSWORD: 'Current password',
  NEW_PASSWORD: 'New password',
  ADD_CARD: 'Add card',
  TOP_UP: 'Top up credits',

  ENQUIRE_SALES: {
    TITLE: 'Get Started',
  },

  PLACEHOLDER: {
    EMPTY_DATA: {
      TITLE: "It's a bit quiet around here",
      DESCRIPTION:
        'This feature is coming soon. In the meantime get in contact for help adding your colleagues.',
    },
    EMPTY_SURVEY: {
      DESCRIPTION: 'Add a question or message card to get started',
    },
    EMPTY_GROUP: {
      TITLE: 'Group related questions',
      DESCRIPTION: 'Add a question or message card',
    },
  },

  PRINT: 'Print',
  NOT_VAT_RECEIPT: 'This is not a VAT receipt',
  PAID_WITH: 'Paid with',
  SCHEDULED_FOR: 'Scheduled for',

  ROUTING: {
    ROUTES: 'Routes',
    ROUTE: 'Route',
    ALL_ROUTES: 'All routes',
    EXPLANATION: {
      P1: `This is a routed survey, meaning it uses conditional logic — so some questions are
      only shown to respondents depending on whether specific answers are selected.
      These options allow you to see the different routes that respondents have been shown
      depending on their answers, e.g. Route 1 show you only the questions available in
      that route.`,
      P2: `The 'All Routes' option lets you see all of the questions at once and select
      multiple answers when you Cross Tab irrespective of routes.`,
      P3: `So, for example, you can see how people who answered questions in Route 1 also
      answered questions in Route 2 and their demographic composition.`,
      P4: `Read more about Routing and Cross Tabbing in our knowledge base.`,
    },
  },

  CARD: {
    QUESTION_ID_PREFIX: 'Q',
    TEXT_ID_PREFIX: 'M',
    GROUP_ID_PREFIX: 'G',
    TYPE_IN_QUESTION: 'Type in your question...',
    TYPE_IN_TEXT: 'Type in your text...',
    QUESTION: 'Question',
    TEXT: 'Text',
    QUESTION_CARD: 'Question card',
    TEXT_CARD: 'Message card',
    BLANK_TEXT_ERROR: 'Your text cannot be blank',
    GROUP: 'Group',
  },

  SURVEY: {
    DRAFT: 'This survey is a draft and is still pending completion',
    PENDING_COMPLETION: 'This survey is still gathering responses',
    PENDING_OPEN: (dateStart: string) => `This survey is scheduled to open on ${dateStart}`,
  },

  STUDY: {
    TRENDS: {
      GRAPH_TITLE: (title: string) => `Trends comparison graph for: ${title}`,
      FOUND_RESPONDENTS: (respondents: number) =>
        `${pluralise(respondents, {
          one: 'respondent',
          other: 'respondents',
        })}`,
      NUMBER_SURVEYS_SELECTED: (surveys: number) =>
        `${surveys} ${pluralise(surveys, {
          one: 'survey',
          other: 'surveys',
        })} selected`,
    },
    QUESTION_ROUTES: (numQuestions: number, numRoutes: number) =>
      `${numQuestions} (${numRoutes} ${pluralise(numRoutes, {
        one: 'route',
        other: 'routes',
      })})`,
    NOT_ENOUGH_CREDITS: <T>(transformers: { creditsPageLink: (s: string) => T }) => [
      'Your credit balance is too low to purchase this survey. To send this survey top up your credits on the ',
      transformers.creditsPageLink('Credits page'),
    ],
  },

  RESULT: {
    DEMOGRAPHIC_FILTERS: 'Demographic filters',
    SORT_RESPONSES: {
      SORT_BY: 'Order answers by',
      RESULTS: 'Results',
      DRAFT_ORDER: 'Draft order',
    },
    NO_RESPONSES: 'No responses',
    COUNTRY_IN_LANGUAGE: (country: string, language: string) => `${country} in ${language}`,

    RANDOMIZED: 'Randomised',
    FIXED: 'Fixed',

    SKIPS: 'Skips',
    ANSWER_ORDER: 'Answer order',
    SUBJECT_ORDER: 'Subject order',
    SELECTION_LIMIT: 'Selection limit',

    ALL_RESPONSES: 'All responses',
    RESPONDENT: (n: number) => `Respondent #${n}`,
    RESPONSE: 'Response',

    SEARCH_RESPONSES: 'Search responses',
    SELECT_ALL: 'Select all',

    RESULT_GRAPH: {
      EXPORT_BUTTON: 'Download chart',
      EXPORT_FAILED:
        'Failed to export graph. Please try again, or contact support@askattest.com for help.',
      GRAPH_TITLE: (title: string) => `Results graph for: ${title}`,
    },
  },

  EXPORT_PPTX: {
    ATTEST_SURVEY: 'Attest Survey',
    EXPORT: 'Export to PowerPoint',
    DATE_OPENED: (date: string | null) => `Date survey opened: ${date}`,
    DATE_DOWNLOADED: (date: string) => `Date PowerPoint generated: ${date}`,
    TOTAL_SAMPLE_SIZE: (responses: number | null) => `Total sample size: ${responses}`,
    FILTERED: 'Filtered *',
    DEMOGRAPHIC_FILTERED_BY: 'Demographic filters applied: ',
    QUESTION_FILTERS: 'Answer filters applied: ',
    QUESTION_TOTAL: (total: number) => `Total N = ${total}, `,
    QUESTION_FILTERED_TOTAL: (total: number) => `Filtered N = ${total}, `,
    QUESTION_SKIPPED: (skipped: number) => `Skipped = ${skipped}`,
    OR: ' or ',
    DOWNLOAD: 'Export',
    EXPORT_BAR_OPEN: 'Export PowerPoint action bar opened',
    SELECT_CHECKBOX: 'Select card for export',
    SELECT_ALL_CHECKBOX: 'Select all',
  },

  MEDIA: {
    DISPLAY: {
      IMAGE: 'Image',
      VIDEO: 'Video',
      AUDIO: 'Audio',
    },
  },

  ORGANISATION_STATUS: {
    TITLE: 'Organisation Status',
    INPUT_PLACEHOLDER: 'Organisation ID',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    SUCCESS: 'Status has been updated',
  },

  FREE_CREDITS: {
    LOCKED_BANNER: {
      HEADER: (moneyDisplay: string) =>
        `Fill out your profile to unlock ${moneyDisplay} free credits`,
      DESCRIPTION: 'You can put the credits towards sending your next survey',
      BUTTON: 'Unlock free credits',
    },
    UNLOCKED_BANNER: {
      HEADER: (moneyDisplay: string) => `Run a survey with your ${moneyDisplay} free credits`,
      DESCRIPTION:
        'We’ve added the credits to your account, so all you have to do is draft your survey, set your audience and send it out!',
      BUTTON: 'Get started',
    },
  },

  MODAL: {
    CLOSE_LABEL: 'Dismiss modal',
    CLOSE: 'Close',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    SAVE_MEDIA: 'Save media',
    SAVE_AND_CLOSE: 'Save & close',
    PAY_NOW: 'Pay now',
    TOP_UP: {
      TITLE: 'Top up credits',
      TOP_UP_WITH_CREDITS: 'Top up with (credits)',
      NEW_BALANCE: 'New balance',
      CURRENT_BALANCE: 'Current balance',
      NO_CARDS: 'You have not registered any cards',
      BANNER_HEADING: 'Save when you subscribe',
      BANNER_TEXT: `If you're not already on a plan, join one now to save money on credits`,
      SEE_PLANS: 'See our plans',
      LEARN: `to learn how you can save money on credits`,
    },
    REJECT_TAKERS: {
      HEADER: (numTakers: number) =>
        `You're about to reject ${numTakers} ${pluralise(numTakers, {
          one: 'respondent',
          other: 'respondents',
        })}`,
    },
    CONFIRM_OPEN_SURVEY: {
      TITLE: 'Open survey',
      BODY: 'Are you sure you want to open this survey?',
      CONFIRM: 'Open survey',
      CANCEL: 'Cancel',
    },
    DUPLICATE_SURVEY: {
      TITLE: 'Duplicate survey',
      BODY: 'Are you sure you want to duplicate this survey? You’ll be redirected to the survey creation panel.',
      CONFIRM: 'Duplicate survey',
      CANCEL: 'Cancel',
    },
    CONFIRM_CLOSE_SURVEY: {
      TITLE: 'Close survey',
      BODY: 'Are you sure you want to close this survey?',
      CONFIRM: 'Close survey',
      CANCEL: 'Cancel',
    },
    CONFIRM_DELETE_TAG: {
      TITLE: 'Delete tag',
      BODY: 'Are you sure you want to delete this tag?',
      CONFIRM: 'Delete tag',
      CANCEL: 'Cancel',
    },
    SURVEY_LISTING: {
      NO_SURVEYS: {
        BODY: `Things are looking pretty lonely around here.
          Select an option below to create your first survey.`,
      },
      NO_TEAM_SURVEYS: {
        BODY: `When people in your organisation create surveys, you'll see them here.`,
      },
    },
    EXAMPLE_SURVEYS: {
      MAIN_HEADING: 'Welcome!',
      SUB_HEADING: 'Check out one of our survey examples',
      CREATE_NEW_TITLE: 'Or, create a survey from scratch by clicking the button below',
    },
    FREE_CREDITS: {
      YOU_HAVE_UNLOCKED_CREDITS: (moneyDisplay: string) =>
        `You just got ${moneyDisplay} free credits`,
      CREDIT_DESCRIPTION:
        'Nicely done! We’ve added the credits to your account so they’re ready for you to use on your next survey.',
      CREDIT_EXPLANATION: '1 credit gets you 1 answer to 1 question.',
      START_A_SURVEY: 'Start a survey',
    },
    MEDIA_PREVIEW: {
      YOUR_MEDIA: 'Your media',
    },
    DELETE_USER: {
      BODY: (name: string) =>
        `Are you sure you want to delete ${name}? This will remove their access to the platform. You will still be able to view any surveys created by them.`,
    },
    CREATION: {
      ADD_IMAGE: 'Add image',
      ADD_ANOTHER_ONE: 'Add an answer',
    },
    MEDIA: {
      ATTACH_FILE: 'Attach a file here',
      DRAG_AND_DROP_FILES: 'Drop your file here to instantly upload it',
      BROWSE_FILES: 'Select file',
      OR_ADD_LINK: 'Or add a link to your file',
      AUDIO: {
        PLACEHOLDER: 'Audio link',
        UPLOAD_INFO: (maxFileSize: string, maxAudioDuration: number) =>
          `Your audio must be an MP3 file, up to ${maxAudioDuration} seconds and less than ${maxFileSize}`,
      },
      ERRORS: {
        FILE_TOO_LARGE: 'The file you tried to upload is bigger than 5MB',
        INVALID_FORMAT: 'Invalid format',
        INVALID_IMAGE_TYPE: 'Invalid image format',
        INVALID_IMAGE_DATA: 'Invalid image data',
        IMAGE_RESOLUTION_TOO_HIGH:
          'The resolution of one of your images is too high. You need to upload an image of lower resolution.',
        IMAGE_RESOLUTION_TOO_LOW:
          'Your image resolution is too low. You need to upload an image of higher resolution.',
        IMAGE_FILE_SIZE_TOO_LARGE: 'The file is too large',
        FAILED_TO_LOAD: 'Failed to load image',
        TOO_SMALL: 'Failed to load image',
        AUDIO_TOO_LONG: 'Your audio file is longer than 1 min',
        INVALID_AUDIO_DATA: 'Invalid audio data',
        INCORRECT_AUDIO_TYPE: `Incorrect file type was provided. We only support .mp3 files`,
        VIDEO_NOT_YOUTUBE: 'Videos must be linked from YouTube',
        UNSUPPORTED_TYPE: 'You have tried to upload an incompatible file type',
        UPLOAD_ERROR: "We couldn't upload your file, please try again.",
        FILE_DURATION_TOO_LONG: `This audio clip is too long, clips can be up to 1 min in length.`,
        VIDEO_DURATION_TOO_LONG: `This video clip is too long, clips can be up to 3 min in length.`,
        URL_NOT_WHITELISTED: 'Get in touch and we can set you up with access to this feature!',
        NOT_A_URL: 'You need to enter a valid URL here. Please check it & try again.',
        INVALID_VIDEO: "This URL isn't valid. Please check it & try again.",
        INVALID_VIDEO_URL: "This URL isn't valid, please provide a valid link.",
      },
    },
    SURVEY_DETAILS: {
      JOBS_TO_BE_DONE_TAG_NAMES: [
        'Brand Tracking',
        'Campaign Evaluation',
        'Creative Testing',
        'Product Development/Innovation',
        'PR/Content Marketing',
        'Brand/Competitor Analysis',
        'Consumer Profiling',
        'Category/Industry Research',
        'International Expansion',
        'Pricing',
      ],
    },

    SURVEY_LIVE: {
      HEADER: `Your survey's live! 🎉`,
      BODY: [
        [`Sit back and relax - we'll email you when`],
        [`your results are in. You can also watch`],
        [`responses roll in on the results page.`],
      ],
    },
  },

  SURVEY_ACTIONS: {
    CLOSE: 'Close',
    OPEN: 'Re-Open',
    SHARE: 'Share results',
    DUPLICATE: 'Duplicate',
    INVITE_RESPONDENTS: 'Invite respondents',
    DELETE: 'Delete',
    GENERATING_FILE: 'Generating report',
    DUPLICATE_EDIT: 'Duplicate & edit',
  },

  CREDIT_POT: {
    TITLE: 'Credit pots',
    ADMIN_DESCRIPTION: 'Allocate credits to pots for different teams or projects',
    MEMBER_DESCRIPTION: 'Access credits from the teams or projects you are assigned to',
    DEFAULT: 'Default',
    DEFAULT_POT: 'Main',
    DEFAULT_POT_DESCRIPTION: 'This is where your credit top-ups and refunds go',
    SORTED_BY: 'sorted by date created',
    CREATED: (date: string) => `Created: ${date}`,
    CREATE_POT: 'Create Credit Pot',
    CREATE_POT_MODAL: {
      TITLE: 'Create a new credit pot',
      CONFIRM: 'Create Credit Pot',
      NAME: 'Pot name',
      DESCRIPTION: "What it'll be used for",
      STARTING_BALANCE: {
        TITLE: 'How many credits do you want transferred?',
        DESCRIPTION: 'You can always add or remove credits later',
        CREDIT_AMOUNT: 'Credit amount',
      },
      NOT_ENOUGH_CREDITS_ERROR: (potTitle: string | undefined) =>
        `There are not enough credits in ${potTitle ?? 'main credit pot'}.`,
      SUCCESS_TOAST: (potTitle: string) => `Credit Pot “${potTitle}” created`,
    },
    EXPLANATION: `Credit pots are subsets of your organisation's credits. Each survey is purchased with credits from one pot.`,
    TRANSFER_CREDITS: 'Transfer Credits',
    EDIT_CREDIT_POT: 'Edit Details',
    TRANSFER_AMOUNT: 'Transfer amount',
    TRANSFER_AMOUNT_DESCRIPTION: 'Choose how many credits to transfer',
    CREDIT_AMOUNT: 'Credit amount',
    TRANSFERING_FROM: 'Transferring from',
    TRANSFERING_TO: 'Transferring to',
    TRANSFER_NOW: 'Transfer Now',
    TRANSFER_FAIL: 'Failed to transfer credits',
    EDIT_CREDIT_POT_MODAL: {
      TITLE: 'Edit credit pot details',
      CONFIRM: 'Save changes',
      NAME: 'Name',
      DESCRIPTION: 'Description',
    },
    EDIT_MEMBERS: 'Manage people',
    VIEW_MEMBERS: 'View people',
    VIEW_CREDIT_POT_MEMBERS_MODAL: {
      TITLE: 'View people',
    },
    EDIT_CREDIT_POT_MEMBERS_MODAL: {
      TITLE: 'Manage People',
      CONFIRM: 'Save',
      ADMIN: 'Admin',
      MEMBER: 'Member',
      INFO_TEXT: `Everyone assigned can purchase surveys using this pot’s credits`,
      ALL: (amount: number) => `All (${amount})`,
      SELECTED: (amount: number) => `Assigned (${amount})`,
      SEARCH_LABEL: 'Search people',
    },
    DELETE_POT_MODAL: {
      BODY_TITLE_POT_NAME: (potName: string) => `Delete "${potName}"?`,
      BODY: (name: string) =>
        `Are you sure you want to delete ${name}? You will continue to see this pot's transactions when viewing all transactions but the credit balance will be transferred to the main credit pot.`,
    },
    DELETE: 'Delete',
    DEFAULT_POT_RECURRING_SURVEY: 'You can only use your default credit pot for recurring surveys',
  },

  PAGE: {
    ACCOUNT_SETUP: {
      TITLE: 'Account Setup',
      PLACEHOLDERS: {
        JOB_ROLE: 'What is your job role?',
      },
      JOB_ROLES: {
        EMPTY: 'Select answer',
        MARKETING_BRAND: 'Marketing or Brand',
        INSIGHTS_RESEARCH: 'Insights or Research',
        INNOVATION_PRODUCT_UX: 'Innovation, Product or UX',
        SALES_GROWTH_LEADS: 'Sales, Growth or Regional leads',
        INVESTMENT_STRATEGY: 'Investment or Strategy',
        C_SUITE_FOUNDERS: 'C-suite or founder',
      },
    },
    EDITOR: {
      TITLE: 'Editor',
      SURVEY_TITLE_PLACEHOLDER: 'Untitled (External title)',
    },
    CREDIT: {
      TABLE: {
        TITLE: 'Title',
        USER: 'People',
        TYPE: 'Type',
        DATE: 'Date',
        CREDITS: 'Credits',
        CREDIT_POT: 'Credit pot',
        CREDIT_POT_DELETED: '(Deleted pot)',
      },
      TITLE: 'Credits',
      TABS: {
        OVERVIEW: 'Overview',
        TRANSACTIONS: 'Transactions',
      },
      TRANSACTION_TITLES: {
        TOP_UP: 'Top-up',
        PURCHASE_SURVEY: 'Survey purchase',
        QUALITATIVE_RESEARCH: 'Survey purchase',
        INCREASE_SAMPLE: 'Sample increase',
        REFUND: 'Refund',
        TRANSFER_IN: 'Credit transfer',
        TRANSFER_OUT: 'Credit transfer',
        OPENING_BALANCE: 'Credit transfer',
        COMPENSATION: 'Credit transfer',
        BONUS: 'Credit transfer',
        UPSELL: 'Credit transfer',
        EARLY_RELEASE: 'Credit transfer',
        REMOVE_ERRONEOUS_CREDITS: 'Credit transfer',
        REMOVE_EXPIRED_CREDITS: 'Credit transfer',
        TRIAL: 'Credit transfer',
        REMOVE_TRIAL_CREDITS: 'Credit transfer',
        CROSS_ORG_TRANSFER_IN: 'Credit transfer',
        CROSS_ORG_TRANSFER_OUT: 'Credit transfer',
        ROLLOVER: 'Credit transfer',
        PURCHASE_PROFESSIONAL_SERVICES: 'Credit transfer',
      },
      TRANSACTION_TYPES: {
        TOP_UP: 'Top-up',
        PURCHASE_SURVEY: 'Survey purchase',
        INCREASE_SAMPLE: 'Sample increase',
        REFUND: 'Refund',
        TRANSFER_IN: 'Other',
        TRANSFER_OUT: 'Other',
        OPENING_BALANCE: 'Top-up',
        COMPENSATION: 'Other',
        BONUS: 'Other',
        UPSELL: 'Top-up',
        EARLY_RELEASE: 'Other',
        REMOVE_ERRONEOUS_CREDITS: 'Other',
        REMOVE_EXPIRED_CREDITS: 'Other',
        TRIAL: 'Other',
        REMOVE_TRIAL_CREDITS: 'Other',
        CROSS_ORG_TRANSFER_IN: 'Other',
        CROSS_ORG_TRANSFER_OUT: 'Other',
        QUALITATIVE_RESEARCH: 'Other',
        ROLLOVER: 'Other',
        PURCHASE_PROFESSIONAL_SERVICES: 'Other',
      },
      TRANSACTION_CREDIT_TYPES: {
        BONUS: 'Free Credits',
      },
      TRANSACTION_TABS: {
        ALL: 'All',
        ALL_COUNT: (amount: number) => `${COPY.PAGE.CREDIT.TRANSACTION_TABS.ALL} (${amount})`,
        TOP_UP: 'Top-ups',
        TOP_UP_COUNT: (amount: number) => `${COPY.PAGE.CREDIT.TRANSACTION_TABS.TOP_UP} (${amount})`,
        PURCHASE_SURVEY: 'Survey purchase',
        PURCHASE_SURVEY_COUNT: (amount: number) =>
          `${COPY.PAGE.CREDIT.TRANSACTION_TABS.PURCHASE_SURVEY} (${amount})`,
        INCREASE_SAMPLE: 'Sample increase',
        INCREASE_SAMPLE_COUNT: (amount: number) =>
          `${COPY.PAGE.CREDIT.TRANSACTION_TABS.INCREASE_SAMPLE} (${amount})`,
        REFUND: 'Refunds',
        REFUND_COUNT: (amount: number) => `${COPY.PAGE.CREDIT.TRANSACTION_TABS.REFUND} (${amount})`,
        OTHER: 'Other',
        OTHER_COUNT: (amount: number) => `${COPY.PAGE.CREDIT.TRANSACTION_TABS.OTHER} (${amount})`,
      },
      TOTAL_CREDITS: {
        LABEL: 'credits remaining',
        VIEW_WALLET: 'View Wallet',
        TOP_UP: 'Top up credits',
        DESCRIPTION: '1 credit = 1 response to 1 question',
      },
      ALL_USERS: 'All people',
      ALL_CREDIT_POTS: 'All credit pots',
      CREDIT_DISCREPANCY:
        'If people moved in or out of your organisation before 15 June 2020 there may be some discrepancies in transactions during this period.',
      LEARN_MORE: 'Learn more',
    },
    EDITOR_DRAFT: {
      TITLE: 'Draft',
      LONGEST_ROUTE: (numberQuestions: number, maxQuestions: number) =>
        `Your longest route is ${numberQuestions} of ${maxQuestions} questions (we don't include Message Cards).`,
      MAXIMUM_QUESTIONS: (numberQuestions: number, maxQuestions: number) =>
        `You have created ${numberQuestions} out of ${maxQuestions} maximum questions (we don't count message cards).\n
             We find this limit improves the quality of the data.`,
      ROUTE_CARD: 'Route from card',
      DELETE_ROUTE_CARD: 'Delete card route',
      ROUTE_ANSWER: 'Route answer',
      DELETE_ANSWER: 'Delete answer',
      DELETE_ROUTE_ANSWER: 'Delete route',
    },
    EDITOR_AUDIENCE: {
      AUDIENCE: 'Audience',
      SURVEY_RESPONDENTS: 'Survey respondents',
      OWN_AUDIENCE_INFO:
        'Demographics and exclusive audience are not applicable when sending to your own respondents or re-engaging a specific audience.',
      SAMPLE_SIZE: {
        SAMPLE_NEEDED: 'Sample needed',
        AVAILABLE: 'Available',
      },
      LANGUAGE_SELECT: 'My survey is in',
      COUNTRY_SELECT: 'My survey is for',
      AUDIENCE_TYPE: {
        ATTEST: 'Attest audience',
        CUSTOM: 'Custom audience',
      },
      YOUR_CUSTOM_AUDIENCE: 'Your custom audience',
      ADD_AUDIENCE: {
        TITLE: (country: string | null) => `Custom audience${country ? ' for ' + country : ''}`,
        DESCRIPTION: 'Define custom age, location, earnings and more.',
      },
      EXCLUDE_RESPONDENTS: {
        TITLE: 'Exclude respondents',
        DESCRIPTION: 'Exclude the respondents of your previous surveys',
      },
      OR_OWN_AUDIENCE: 'Or select from our preset audiences',
      AUDIENCE_TYPE_TILE: {
        PANEL: {
          TITLE: 'Send to targeted respondents',
          DESCRIPTION: 'Send to a targeted demographic group with a custom sample size',
        },
        OWN: {
          TITLE: 'Send to my own respondents',
          DESCRIPTION:
            'After you purchase your survey you will be given a link to share with your respondents.',
        },
      },
      ADDITIONAL_COUNTRY_TOOLTIP_MESSAGE: (email: string) =>
        `Speak with our sales team to upgrade to Attest Pro at ${email}`,
    },
    EDITOR_REVIEW: {
      SURVEY_LIVE: 'Survey schedule',
      RECURRING_TITLE: 'Make survey recurring',
      RECURRING_FREQUENCY: {
        NO_REPEAT: "Doesn't repeat",
        ONE_MONTH: 'Every month',
        TWO_MONTHS: 'Every 2 months',
        THREE_MONTHS: 'Every 3 months',
        SIX_MONTHS: 'Every 6 months',
      },
      NUMBER_OF_MONTHS: (numMonths: number) =>
        `${numMonths} ${pluralise(numMonths, {
          one: 'month',
          other: 'months',
        })}`,
      RECURRING_NEXT_DATE: ({
        interval,
        recurrenceInterval,
        date,
      }: {
        interval: number
        recurrenceInterval: 'DAYS' | 'MONTHS'
        date: string
      }) =>
        `Sent out every ${interval} ${pluralise(interval, {
          one: recurrenceInterval === 'DAYS' ? 'day' : 'month',
          other: recurrenceInterval === 'DAYS' ? 'days' : 'months',
        })} - next survey on ${date}`,
      YOUR_SURVEY: 'Your survey',
      NUMBER_QUESTIONS: (number: number) =>
        `${number} ${pluralise(number, { one: 'question', other: 'questions' })}`,
      SURVEY_LINK: 'Survey link',
      SURVEY_LINK_AVAILABLE_ON_CONFIRM:
        'The link to your survey will be available to share once you confirm below',
    },

    EDITOR_CHECKOUT: {
      ATTEST_CREDITS: 'Attest credits',
      CREDIT_CARD: 'Credit or debit card',
    },
    PROFILE: {
      ERRORS: {
        ORGANISATION_NOT_FOUND: 'Your organisation was not found.',
        NOT_FOUND: 'Your user was not found.',
        INTEREST_TAGS_LENGTH_TOO_LARGE:
          'You have selected too many interest tags, try reducing the number of selected tags.',
      },
      TITLE: 'Profile & Settings',
      UPDATED: 'Your details have been updated',
      ACCOUNT: 'Account',
      ACCOUNT_DESCRIPTION: 'This is how you sign into your account.',
      YOUR_ROLE: 'Your role',
      YOUR_RESEARCH_EXPERIENCE: 'Your research experience',
      HAS_EXPERIENCE: {
        YES: 'I have research experience',
        NO: 'I do not have research experience',
        SELECT_ANSWER: 'Select answer',
      },
      MFA: {
        CREATE_MFA_MODAL: {
          START: {
            TITLE: 'Protect your account with 2-Step Verification',
            CONTENT:
              'Add an extra layer of security to your Attest account with 2-Step Verification. Each time you sign into your account you’ll need your password and a unique verification code that’s sent to your phone.',
            CANCEL: 'Cancel',
            NEXT: 'Get started',
          },
          SETUP: {
            TITLE: 'Scan the QR code',
            INSTRUCTIONS: 'Open an authenticator app using your phone and scan the QR code below.',
            INSTRUCTIONS_ALTERNATIVE: `Can't scan the QR code?`,
            INSTRUCTIONS_ENTER_CODE: `Enter this secret key instead: `,
            CANCEL: 'Back',
            NEXT: 'Continue',
          },
          VERIFY: {
            TITLE: 'Enter the verification code',
            INSTRUCTIONS: 'Enter the 6-digit verification code you see in your authenticator app.',
            INPUT_PLACEHOLDER: 'Verification code',
            CANCEL: 'Back',
            NEXT: 'Continue',
          },
          END: {
            TITLE: 'Setup complete',
            CONTENT:
              'You’re all set. From now when you sign into your account you’ll need your password and a unique verification code that’ll be sent to your phone.',
            NEXT: 'Finish',
          },
        },
        DISABLE_MFA_MODAL: {
          CONFIRM: {
            TITLE: 'Do you want to turn off 2-step Verification?',
            CONTENT: `Turning off 2-step Verification will remove the extra security on your Attest account, and you'll only use your password to sign in. Are you sure you want to turn off 2-step Verification?`,
            CANCEL: 'Cancel',
            NEXT: 'Continue',
          },
          VALIDATE: {
            CONTENT:
              'Please open your authenticator app and enter the unique 6-digit verification to confirm turning off 2-step Verification.',
            PLACEHOLDER: 'Verification code',
            CANCEL: 'Cancel',
            NEXT: 'Submit and turn off',
          },
        },
      },
      INTEREST_TAGS: {
        BRAND_TRACKING: 'Brand Tracking',
        CAMPAIGN_EVALUATION: 'Campaign Evaluation',
        CREATIVE_TESTING: 'Creative Testing',
        PRODUCT_DEVELOPMENT_INNOVATION: 'Product Development/Innovation',
        PR_CONTENT_MARKETING: 'PR/Content Marketing',
        BRAND_COMPETITOR_ANALYSIS: 'Brand/Competitor Analysis',
        CONSUMER_PROFILING: 'Consumer Profiling',
        CATEGORY_INDUSTRY_RESEARCH: 'Category/Industry Research',
        INTERNATIONAL_EXPANSION: 'International Expansion',
        PRICING: 'Pricing',
      },
      YOUR_COMPANY: 'Your company',
      YOUR_COMPANY_DESCRIPTION:
        'This will help us recommend things that are right for your business.',
      COMPANY_SECTOR: 'Company sector',
      COMPANY_SIZE: 'Company size',
      COMPANY_SIZES: {
        EMPTY: 'Select a size',
        SIZE_SELF_EMPLOYED: 'Self-Employed',
        SIZE_1_10: '1-10 employees',
        SIZE_11_50: '11-50 employees',
        SIZE_51_200: '51-200 employees',
        SIZE_201_500: '201-500 employees',
        SIZE_501_1000: '501-1000 employees',
        SIZE_1001_5000: '1001-5000 employees',
        SIZE_5001_10000: '5001-10,000 employees',
        SIZE_10001: '10001+ employees',
      },
      COMPANY_SECTORS: {
        EMPTY: 'Select a sector',
        AGRICULTURE: 'Agriculture',
        APPAREL: 'Apparel',
        BANKING: 'Banking',
        BIOTECHNOLOGY: 'Biotechnology',
        COMMUNICATIONS: 'Communications',
        CONSULTING: 'Consulting',
        EDUCATION: 'Education',
        ELECTRONICS: 'Electronics',
        ENERGY: 'Energy',
        ENGINEERING: 'Engineering',
        ENTERTAINMENT: 'Entertainment',
        ENVIRONMENTAL: 'Environmental',
        FINANCE: 'Finance',
        FOOD_BEVERAGE: 'Food & Beverage',
        GOVERNMENT: 'Government',
        HEALTHCARE: 'Healthcare',
        INSURANCE: 'Insurance',
        MANUFACTURING: 'Manufacturing',
        MEDIA: 'Media',
        NOT_FOR_PROFIT: 'Not For Profit',
        OTHER: 'Other',
        RECREATION: 'Recreation',
        RETAIL: 'Retail',
        TECHNOLOGY: 'Technology',
        TELECOMMUNICATIONS: 'Telecommunications',
        UTILITIES: 'Utilities',
      },
      ABOUT_YOU: 'About you',
      ABOUT_YOU_DESCRIPTION:
        'This will help us point you in the right direction as you get started.',
      YOUR_INTERESTS: 'Your interests',
      YOUR_INTERESTS_DESCRIPTION: 'This will allow us share resources that are tailored to you.',
      ADD_YOUR_OWN_INTERESTS: 'Add your own interests',
      ADD_YOUR_OWN_INTERESTS_DESCRIPTION: 'Separate multiple interests with commas',
    },
    CREATE_FROM_TEMPLATE: {
      TITLE: 'Create from template',
    },
    RECEIPT: {
      TITLE: 'Receipt',
    },
    WALLET: {
      TITLE: 'Wallet',
      TRANSACTION_PLACEHOLDER: 'No transactions yet',
    },
    SURVEY_LISTING: {
      TITLE: 'Surveys',
      VIEW_DROPDOWN: {
        MY_SURVEYS: 'My surveys',
        TEAM_SURVEYS: 'All surveys',
      },
      SEARCH_PLACEHOLDER: 'Search title or tag',
      CREATE_NEW_SURVEY: 'Create new survey',
      CREATE_FIRST_SURVEY: 'Create first survey',
      TAGS: {
        EMPTY_TAGS: 'No tags available, create tags below',
      },
      RECURRING_SURVEY: 'Recurring survey',
      ACTION_BAR_OPEN: 'Survey selection action bar opened',
    },
    TEAM: {
      DELETE_USER: {
        BUTTON_LABEL: 'Remove access',
        SUCCESS: (email: string) => `${email} has been removed`,
      },
      ROLES: {
        GLOBAL_ADMIN: 'Global Admin',
        ORGANISATION_ADMIN: 'Admin',
        MEMBER: 'Member',
      },
      INVITE_USER: {
        TITLE: 'Invite people',
        PLACEHOLDER: 'Invite via email...',
        INFO_TEXT: {
          TEXT: 'You can invite people with existing email domains. ',
          SUPPORT: (supportLink: string) => `Contact ${supportLink} to invite others.`,
        },
        ERROR_TEXT: `The email domain must match one of the existing email domains in your organisation.`,
        AVAILABLE_DOMAINS: 'Available domains',
        SUCCESS: (email: string) => `${email} has been invited!`,
        INVITATION_SENT: 'Invitation sent',
      },
    },
  },
  ARIA_LABEL: {
    TOGGLE_TAG: 'toggle tag sidebar',
  },

  TAG: {
    NAME_PLACEHOLDER: 'Tag name ...',
    EDIT: 'Edit',
    DELETE: 'Delete',
    ATTACH: 'Attach',
    DETACH: 'Detach',
  },

  TEMPLATE: {
    CARD: {
      MORE_DETAILS: 'More Details >',
      USE_TEMPLATE: 'Use Template',
    },
  },

  PAGINATION: {
    PREVIOUS_PAGE: 'Go to previous page',
    NEXT_PAGE: 'Go to next page',
    GO_TO_PAGE: (pageNumber: number) => `Go to page ${pageNumber}`,
  },

  CROSSTABS: {
    ADD_RANGE_BUTTON_LABEL: (demographic?: string) => `Add ${demographic?.toLowerCase()} range`,
    SAMPLE_SIZE_SKIPPED: (total: number, skipped: number) =>
      `Sample size = ${total}; Skips = ${skipped}`,
  },

  PRODUCT_ADVICE: {
    ADVICE_TYPE_LABEL: {
      AGREEMENT_BIAS: 'Agreement bias',
      PII: 'Data protection',
      MULTIPLE_SEQUENTIAL_OPEN_TEXT: 'Survey fatigue',
      MANY_OPEN_TEXTS: 'Survey fatigue',
      OVER_QUALIFYING: 'Over qualifying',
      DISPLAY_LOGIC_REFERENCE_QUESTION_NOT_VISIBLE_TO_ALL_AUDIENCES: c(
        'editor.product_advice.display_logic_questions_not_visible_all_audiences.title',
      ),
      DISPLAY_LOGIC_REFERENCE_ANSWER_NOT_VISIBLE_TO_ALL_AUDIENCES: c(
        'editor.product_advice.display_logic_questions_not_visible_all_audiences.title',
      ),
    },
    ADVICE_TYPE_INFO: {
      PII: `In compliance with General Data Protection Regulation (GDPR), we are unable to hold any personal information on respondents in our database. This means any questions that request details such as name or email will be removed.`,
      AGREEMENT_BIAS:
        "Yes/No answers can lead to 'agreement bias' as respondents tend to answer positively. Remove this bias by rephrasing the question and offering more answer options.",
      MULTIPLE_SEQUENTIAL_OPEN_TEXT: `Asking too many open text questions in a row can create respondent fatigue and decrease engagement. Use another question type or spread out open text questions for the best responses.`,
      MANY_OPEN_TEXTS: `Asking too many open text questions in a survey can create respondent fatigue and decrease engagement. Use different question types for the best responses.`,
      OVER_QUALIFYING: `We recommend using no more than 3 qualifying questions per survey to make sure enough people can screen-in, and to provide you a big enough useful sample size.`,
      DISPLAY_LOGIC_REFERENCE_QUESTION_NOT_VISIBLE_TO_ALL_AUDIENCES: c(
        'editor.product_advice.display_logic_questions_not_visible_all_audiences.text',
      ),
      DISPLAY_LOGIC_REFERENCE_ANSWER_NOT_VISIBLE_TO_ALL_AUDIENCES: c(
        'editor.product_advice.display_logic_answers_not_visible_all_audiences.text',
      ),
    },
    INFO_CTA: 'Learn more',
  },

  WALLET_SUBSCRIPTION_PLAN_BLOCK: {
    TITLE: 'Subscription Plan',
    HEADING: ({ name, credits }: { name?: string; credits?: number }) =>
      `${name} - Monthly subscription - ${credits} ${COPY.CREDITS}`,
    SUBHEADING_TITLES: {
      CURRENT_BILLING_PERIOD: 'Current billing period:',
      RENEWAL_DATE: 'Renewal date:',
    },

    TEXTS: [
      'Your subscription will renew every month.',
      'To cancel or make changes to your subscription contact support@askattest.com',
    ],
  },

  MINIMUM_VALUE: 'Minimum value',
  MAXIMUM_VALUE: 'Maximum value',

  IMPERSONATE: {
    TITLE: 'Impersonate',
    DESCRIPTION: (email: string) =>
      `You're in impersonate mode as ${email}. Your actions will look like that user but impersonate is audited for security.`,
  },

  COLOR_SPOT_VALUE: (color: string) => `Colour: ${color}`,
} as const
