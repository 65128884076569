import { nanoid } from 'nanoid'

import { sum } from '@attest/util'

import type { AnswerQuota, Card } from './model'

export function getActiveAnswerQuotas(
  card: Card,
  answerIdToAnswerQuota: { [answerId: string]: AnswerQuota } = {},
): AnswerQuota[] {
  return card.question.options.answers.flatMap(({ isQualifying, quotaId }) =>
    isQualifying && quotaId ? (answerIdToAnswerQuota[quotaId] ?? []) : [],
  )
}

export function createAnswerQuota(quota: Partial<AnswerQuota> = {}): AnswerQuota {
  return {
    id: nanoid(),
    minTarget: 0,
    ...quota,
  }
}

export const ANSWER_QUOTA_TARGET_MIN = 1
export const ANSWER_QUOTA_TARGET_MAX = 99

function getAnswerQuotasSum(answerQuotas: AnswerQuota[]): number {
  return (
    sum(
      answerQuotas.flatMap(({ minTarget }) =>
        typeof minTarget === 'number' ? [minTarget * 1000] : [],
      ),
    ) / 1000
  )
}

export function areAnswerQuotasValid(answerQuotas: AnswerQuota[]): boolean {
  if (answerQuotas.length === 0) return true
  return answerQuotas.every(quota => isAnswerQuotaValid(quota))
}

export function isAnswerQuotaValid({ minTarget }: AnswerQuota): boolean {
  return typeof minTarget === 'number'
    ? minTarget * 100 >= ANSWER_QUOTA_TARGET_MIN && minTarget * 100 <= ANSWER_QUOTA_TARGET_MAX
    : false
}

export function isAnswerQuotasSumTooHigh(answerQuotas: AnswerQuota[]): boolean {
  if (answerQuotas.length === 0) return false
  return getAnswerQuotasSum(answerQuotas) > 1
}

export function isAnswerQuotasSumFull(answerQuotas: AnswerQuota[]): boolean {
  if (answerQuotas.length === 0) return false
  return getAnswerQuotasSum(answerQuotas) === 1
}

export function toAnswerQuotaMinTargetString(minTarget: number | undefined) {
  return typeof minTarget === 'number' ? (minTarget * 100).toFixed(0) : ''
}
