import {
  isSentWaveSurvey,
  type SentWaveSurvey,
  type WaveSurvey,
  type WaveSurveyStatus,
} from './model'

export function rejectDraftWaveSurveys<W extends { status: WaveSurveyStatus }>(waves: W[]): W[] {
  return waves.filter(({ status }) => status !== 'draft')
}

export function filterSentWaveSurveys(waves: WaveSurvey[]): SentWaveSurvey[] {
  return waves.filter(isSentWaveSurvey)
}
