import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M13.0555 9.72233C9.02776 8.78097 8.33332 7.639 7.49999 3.05566C6.62815 7.72233 5.83332 8.64208 1.94443 9.72233C5.83332 10.7254 6.74924 11.5742 7.49999 16.389C8.19443 11.5279 8.88888 10.7254 13.0555 9.72233Z",
      fill: "#F75F2A",
      stroke: "black",
      "stroke-width": "0.416667",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M18.0555 7.49989C16.243 7.06844 15.9305 6.54503 15.5555 4.44434C15.1632 6.58322 14.8055 7.00478 13.0555 7.49989C14.8055 7.95964 15.2177 8.34866 15.5555 10.5554C15.868 8.32744 16.1805 7.95964 18.0555 7.49989Z",
      fill: "#FCC5DE",
      stroke: "black",
      "stroke-width": "0.416667",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M16.1111 14.1669C14.2986 13.7354 13.9861 13.212 13.6111 11.1113C13.2188 13.2502 12.8611 13.6718 11.1111 14.1669C12.8611 14.6266 13.2732 15.0156 13.6111 17.2224C13.9236 14.9944 14.2361 14.6266 16.1111 14.1669Z",
      fill: "#FCC5DE",
      stroke: "black",
      "stroke-width": "0.416667",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }