import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

import type { SavedFilter } from '@attest/segments'
import { useStudyStore } from '@attest/study'
import { cloneRaw } from '@attest/util'

export const useSegmentFiltersStore = defineStore('segmentFilters', () => {
  const studyStore = useStudyStore()

  const dynamicSegmentFilters = ref<SavedFilter[]>([])

  const shouldFilter = computed(() => dynamicSegmentFilters.value.length > 0)

  const currentSegmentFilter = computed(() =>
    shouldFilter.value ? dynamicSegmentFilters.value[0] : undefined,
  )

  function addSegment(segment: SavedFilter): void {
    dynamicSegmentFilters.value = [
      ...dynamicSegmentFilters.value.filter(seg => seg.id !== segment.id),
      cloneRaw(segment),
    ]
  }

  function $reset(): void {
    dynamicSegmentFilters.value = []
  }

  // reset state if study id shifts
  const id = computed(() => studyStore.study?.id)
  watch(id, $reset)

  return {
    dynamicSegmentFilters,
    currentSegmentFilter,
    addSegment,
    shouldFilter,
    $reset,
  }
})
