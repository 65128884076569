import type { ApiStudyListingItem } from '@attest/_api/model/study-listing'
import type { ApiSurveyListingV2Item } from '@attest/_api/model/surveys'
import type { ApiWaveSurvey } from '@attest/_api/model/wave-survey'
import { transformApiSegmentationsToSegmentations } from '@attest/audience'
import { keyBy, sum } from '@attest/util'

import type { Survey, SurveyStatus } from '../../model'
import type { ApiSurvey } from '../model'

import { transformApiRecurrenceInterval, transformApiRecurrenceToRecurrence } from './recurrence'
import { toSurveyModel, toViewership } from './survey'

function transformWaveResponseStatusToWaveStatus(response: string): SurveyStatus {
  return response.toLowerCase() as SurveyStatus
}

export function toSurveysModel(surveys: ApiSurvey[]): Record<string, Survey> {
  const transformed: Record<string, Survey> = {}
  surveys.forEach(survey => {
    transformed[survey.guid] = toSurveyModel(survey)
  })

  return transformed
}

export function toSurveysModelV2(surveys: ApiSurveyListingV2Item[]): Record<string, Survey> {
  return keyBy(
    surveys.map(survey => {
      const waves = (survey.waves ?? []).filter(({ status }) => status !== 'DRAFT')
      const audiences =
        waves.length > 0 ? waves.flatMap(({ audience }) => audience) : (survey.audience ?? [])
      const responses =
        waves.length > 0 ? sum(waves.map(apiWave => apiWave.num_responses)) : survey.responses
      return {
        accessCode: survey.access_code ?? null,
        cards: [],
        groups: [],
        collectsVideoResponses: survey.collects_video_responses,
        countries: [...new Set(audiences.map(({ country }) => country))],
        coverNote: null,
        creationTimestamp: survey.created,
        endTimestamp: survey.expires ?? 0,
        guid: survey.guid,
        internalTitle: survey.internal_title ?? null,
        isShared: survey.shared,
        languages: [...new Set(audiences.map(({ language }) => language))],
        maxResponses: survey.access_code
          ? Number.POSITIVE_INFINITY
          : (sum(audiences.map(apiAudience => apiAudience.max_responses)) ?? null),
        publishTimestamp: survey.published ?? null,
        responses,
        startTimestamp: survey.starts ?? 0,
        status: survey.status,
        tags: survey.tags.map(({ id, name }) => ({ id, name })),
        timeZoneId: survey.time_zone_id ?? null,
        template: null,
        title: survey.title,
        titleImage: survey.title_image ?? null,
        viewership: toViewership(survey.viewership),
        makerGuid: survey.maker_guid,
        recurrence: survey.recurrence
          ? {
              interval: survey.recurrence.interval,
              recurrenceInterval: transformApiRecurrenceInterval(
                survey.recurrence.recurrence_interval,
              ),
              hasFixedInterval: survey.recurrence.fixed_interval,
            }
          : null,
        answerQuotas: {},
        waves:
          survey.waves?.map(wave => ({
            id: wave.id,
            status: transformWaveResponseStatusToWaveStatus(wave.status),
            publishedTimestamp: wave.published,
            startTimestamp: wave.start_date,
            timeZoneId: wave.time_zone_id,
            country: wave.country,
            responses: wave.num_responses,
            maxResponses: wave.max_responses,
          })) ?? [],
        exclusiveSampleGuids: [],
        isRandomized: false,
        lastAnswerTimestamp: null,
        quotas: [],
        segmentations: {},
        lastResponse: survey.last_response,
        makerEmail: survey.maker_email,
      }
    }),
    ({ guid }) => guid,
  )
}

export function apiStudyToSurveyModel(study: ApiStudyListingItem): Survey {
  const latestSentWaveSurvey = study.waves.toSorted(
    (a, b) => (b.published ?? 0) - (a.published ?? 0),
  )[0] as ApiWaveSurvey | undefined
  const latestWave = study.waves.filter(
    ({ published }) => published === latestSentWaveSurvey?.published,
  )

  return {
    accessCode: null,
    cards: [],
    groups: [],
    countries: [...new Set(study.waves.map(wave => wave.country))],
    coverNote: null,
    creationTimestamp: latestSentWaveSurvey?.created ?? 0,
    endTimestamp: latestSentWaveSurvey?.expires ?? 0,
    exclusiveSampleGuids: [],
    guid: study.id,
    internalTitle: study.internal_title ?? null,
    isRandomized: null,
    isShared: study.shared ?? false,
    languages: latestSentWaveSurvey?.language ? [latestSentWaveSurvey?.language] : [],
    lastAnswerTimestamp: null,
    maxResponses: latestWave.length > 0 ? sum(latestWave.map(wave => wave.max_responses)) : null,
    publishTimestamp: latestSentWaveSurvey?.published ?? null,
    quotas: [],
    responses: latestWave.length > 0 ? sum(latestWave.map(wave => wave.num_responses)) : null,
    segmentations: transformApiSegmentationsToSegmentations(
      latestSentWaveSurvey?.segmentations ?? {},
    ),
    startTimestamp: latestSentWaveSurvey?.published ?? 0,
    status: (latestSentWaveSurvey?.status ?? 'closed').toLowerCase() as SurveyStatus,
    tags: study.tags.map(({ id, name }) => ({ id, name })),
    timeZoneId: null,
    template: null,
    title: study.title,
    titleImage: null,
    viewership: 'team',
    makerGuid: study.owned_by ?? null,
    recurrence: transformApiRecurrenceToRecurrence(study.recurrence ?? null),
    answerQuotas: {},
    waves: study.waves.map(wave => ({
      id: wave.id,
      status: transformWaveResponseStatusToWaveStatus(wave.status),
      publishedTimestamp: wave.published,
      startTimestamp: wave.start_date,
      timeZoneId: wave.time_zone_id,
      country: wave.country,
      responses: wave.num_responses,
      maxResponses: wave.max_responses,
    })),
  }
}
