import { computed } from 'vue'

import { formatOrdinal } from '@attest/intl'
import {
  calculateRankedValueAverages,
  type StudyResultsAnswer,
  useStudyInsightsStore,
} from '@attest/results-core'
import { useSavedSettingsStore } from '@attest/results-saved-settings'
import {
  isGridTemplateAnswers,
  isNonStaticOption,
  isStaticOption,
  isStaticOptionId,
  isStudyQuestionCard,
  type Option,
  type StudyQuestionGroup,
  type Subject,
} from '@attest/study'
import { isNotNil, pick } from '@attest/util'
import { sum } from '@attest/util'

import { useCardRowChildWithBaseRounds } from './filters/row-child'

export type Row = {
  id: string
  title?: string
  children: RowChild[]
  isForwarded: boolean
}
export type RowChild = {
  id: string
  title: string
  isQualifying: boolean
  isForwarded: boolean
  routingTargetCardId?: string
  customRowType?: 'nps'
}

const calculateByForwarded = computed(() => {
  return useSavedSettingsStore().activeStudySettings.show.forward_percentage
})

export function createCrosstabRows(cardId: string): Row[] {
  const studyInsightsStore = useStudyInsightsStore()
  const card = studyInsightsStore.getQuestionCardById(cardId)

  let rows: Row[]

  switch (card.question.type) {
    case 'choice':
      rows = getChoiceQuestionRows(cardId)
      break
    case 'nps':
      rows = getNpsQuestionRows(cardId)
      break
    case 'ranked':
      rows = getRankedQuestionRows(cardId)
      break
    case 'grid':
      rows = getGridQuestionRows(cardId)
      break
    default:
      throw new Error('card type not supported')
  }

  return rows.filter(row => row.id !== 'na')
}

function getGridQuestionRows(cardId: string): Row[] {
  const studyInsightsStore = useStudyInsightsStore()
  const card = studyInsightsStore.getQuestionCardById(cardId)
  const cardStructure = studyInsightsStore.getMergedQuestionStructureByCardId(cardId)
  const resultCard = studyInsightsStore.cardIdToResultsCard[cardId]

  return cardStructure.subjects.map(subject => {
    return {
      id: subject.id,
      title: subject.text,
      isForwarded: Boolean(subject.mask),
      children: createOptionRows({
        cardId,
        groups: [
          ...card.question.optionGroups,
          ...cardStructure.options
            .filter(isStaticOption)
            .map(option => ({ text: option.text, ids: [option.id] })),
        ],
        options: cardStructure.options,
        answerIdToAnswer: pick(subject.answerIds, resultCard.answerIdToAnswer),
        subject,
      }),
    }
  })
}

function getChoiceQuestionRows(cardId: string): Row[] {
  const studyInsightsStore = useStudyInsightsStore()
  const card = studyInsightsStore.getQuestionCardById(cardId)
  const cardStructure = studyInsightsStore.getMergedQuestionStructureByCardId(cardId)
  const resultCard = studyInsightsStore.cardIdToResultsCard[cardId]

  return [
    {
      id: cardId,
      title: undefined,
      isForwarded: false,
      children: createOptionRows({
        cardId,
        groups: card.question.optionGroups,
        options: cardStructure.options,
        answerIdToAnswer: resultCard.answerIdToAnswer,
      }),
    },
  ]
}

function createNpsGroupedRow(data: { id: string; title: string }): RowChild {
  return {
    id: data.id,
    title: data.title,
    isQualifying: false,
    isForwarded: false,
  }
}

function getNpsQuestionRows(cardId: string): Row[] {
  const studyInsightsStore = useStudyInsightsStore()
  const cardStructure = studyInsightsStore.getMergedQuestionStructureByCardId(cardId)

  return [
    {
      id: cardId,
      title: undefined,
      isForwarded: false,
      children: [
        {
          id: 'nps',
          title: 'NPS',
          isForwarded: false,
          isQualifying: false,
          customRowType: 'nps',
        },
        createNpsGroupedRow({ id: 'promoters', title: 'Promoters (9-10)' }),
        createNpsGroupedRow({ id: 'passives', title: 'Passives (7-8)' }),
        createNpsGroupedRow({
          id: 'detractors',
          title: 'Detractors (0-6)',
        }),
        ...cardStructure.options.filter(isStaticOption).map(option =>
          createNpsGroupedRow({
            id: option.id,
            title: option.text,
          }),
        ),
      ],
    },
  ]
}

function getRankedQuestionRows(cardId: string): Row[] {
  const studyInsightsStore = useStudyInsightsStore()
  const cardStructure = studyInsightsStore.getMergedQuestionStructureByCardId(cardId)
  const options = cardStructure.options.filter(isNonStaticOption)
  const staticOptions = cardStructure.options.filter(isStaticOption)
  const rows = options.map(option => ({
    id: option.id,
    title: option.text,
    isForwarded: false,
    children: Array.from({ length: options.length }, (_, index) => ({
      id: `${index + 1}`,
      title: formatOrdinal(index + 1),
      isQualifying: false,
      isForwarded: false,
      routingTargetCardId: option.next,
    })),
  }))

  return [
    ...rows,
    ...staticOptions.map(staticOption => ({
      id: staticOption.id,
      title: '',
      isForwarded: false,
      children: [
        {
          id: staticOption.id,
          title: staticOption.text,
          isQualifying: false,
          isForwarded: false,
        },
      ],
    })),
  ]
}

function createOptionRows(data: {
  cardId: string
  groups: StudyQuestionGroup[]
  subject?: Subject
  options: readonly Option[]
  answerIdToAnswer: Record<string, StudyResultsAnswer>
}): RowChild[] {
  return data.groups.flatMap(group => {
    const options = group.ids
      .map(groupId => data.options.find(option => option.id === groupId))
      .filter(isNotNil)

    if (options.length === 0) return []

    return [
      {
        id: group.ids.join(','),
        isQualifying: options.every(({ isQualifying }) => isQualifying),
        isForwarded: options.every(({ mask }) => mask),
        routingTargetCardId:
          options.every(o => o.next) && new Set(options.map(o => o.next)).size === 1
            ? options.find(o => o.next)?.next
            : undefined,
        title: getGroupTitle(data.cardId, group),
      },
    ]
  })
}

function getGroupTitle(cardId: string, group: StudyQuestionGroup): string {
  const studyInsightsStore = useStudyInsightsStore()
  const mergedStructure = studyInsightsStore.getMergedQuestionStructureByCardId(cardId)

  return (
    group.text ??
    group.ids
      .map(id => mergedStructure.options.find(option => option.id === id))
      .filter(isNotNil)
      .map(({ text }) => text)
      .join(', ')
  )
}

export function sortRows(cardId: string, rows: Row[]): Row[] {
  const card = useStudyInsightsStore().getQuestionCardById(cardId)
  switch (card.question.type) {
    case 'ranked':
      return sortRanked(cardId, rows)
    case 'grid':
    case 'choice':
      return sortChoiceRows(cardId, rows)
    default:
      return rows
  }
}

function sortRanked(cardId: string, rows: Row[]): Row[] {
  if ((useSavedSettingsStore().activeStudySettings.order ?? 'draft') === 'draft') {
    return rows
  }

  return rows.toSorted((a, b) => {
    if (isStaticOptionId(a.id)) {
      return -1
    }

    const aRounds = a.children.map(
      rowChild => useCardRowChildWithBaseRounds({ cardId, row: a, rowChild }).value.size,
    )
    const bRounds = b.children.map(
      rowChild => useCardRowChildWithBaseRounds({ cardId, row: b, rowChild }).value.size,
    )
    return (
      calculateRankedValueAverages(sum(...aRounds), aRounds) -
      calculateRankedValueAverages(sum(...bRounds), bRounds)
    )
  })
}

function sortChoiceRows(cardId: string, rows: Row[]): Row[] {
  const studyCard = useStudyInsightsStore().idToCard[cardId]
  const answerIdsInDraftOrder = [
    ...(isStudyQuestionCard(studyCard)
      ? isGridTemplateAnswers(studyCard.templateAnswers)
        ? (studyCard.templateAnswers.options.headers?.map(header => header.id) ?? [])
        : (studyCard.templateAnswers?.map(answer => answer.id) ?? [])
      : []
    )?.filter(id => !['none', 'na', 'other'].includes(id)),
    'none',
    'na',
    'other',
  ]

  return rows.map(row => ({
    ...row,
    children:
      (useSavedSettingsStore().activeStudySettings.order ?? 'draft') === 'draft'
        ? row.children.toSorted(
            (a, b) => answerIdsInDraftOrder.indexOf(a.id) - answerIdsInDraftOrder.indexOf(b.id),
          )
        : row.children.toSorted((a, b) => {
            const aRounds = useCardRowChildWithBaseRounds({ cardId, row, rowChild: a }).value
            const bRounds = useCardRowChildWithBaseRounds({ cardId, row, rowChild: b }).value
            return bRounds.size - aRounds.size
          }),
  }))
}
